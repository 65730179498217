import React, { useEffect, useContext, useState, Fragment } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const FatherForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const { employeeProfileEdit, getUserProfile, cities, religion } = authContext

	const form = { name: '', bornDate: '', bornPlace: '', religionID: '', cityID: '', address: '' }
	const [fatherForm, setFatherForm] = useState(null)
	const [fatherFormAdd, setFatherFormAdd] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeProfileEdit.data.father) {
			setFatherForm(employeeProfileEdit.data.father)
		}
	}, [employeeProfileEdit])

	const updateFatherData = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.updateEmployeeFather(fatherForm)
				alert.show('Sukses Mengubah Data Ayah', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
			} catch (error) {
				alert.show('Gagal Mengubah Data Ayah', { type: 'error' })
				setSubmitting(false)
			}
		}
	}

	const addFatherData = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.addEmployeeFather(fatherFormAdd)
				alert.show('Sukses Menambahkan Data Ayah', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
				setFatherFormAdd(null)
			} catch (error) {
				alert.show('Gagal Menambahkan Data Ayah', { type: 'error' })
				setSubmitting(false)
			}
		}
	}

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Data Ayah</h4>

			{fatherForm ? (
				<div>
					<div className='row'>
						<div className='col-md-12'>
							<div className='form-group'>
								<label htmlFor='childrenName'>
									<span style={{ color: 'red' }}>*</span> Nama Ayah
								</label>
								<input
									type='text'
									id='childrenName'
									placeholder='Nama Anak'
									className='form-control'
									value={fatherForm.name}
									onChange={e => {
										setFatherForm({ ...fatherForm, name: e.target.value })
									}}
								/>
							</div>
						</div>

						<div className='col-md-6'>
							<label htmlFor='dateBornChildren'>
								<span style={{ color: 'red' }}>*</span> Tanggal Lahir
							</label>
							<div className='form-group'>
								<input
									type='date'
									id='dateBornChildren'
									placeholder='Tanggal Lahir'
									className='form-control'
									value={fatherForm.bornDate}
									onChange={e => {
										setFatherForm({ ...fatherForm, bornDate: e.target.value })
									}}
								/>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='childrenCity'>
									<span style={{ color: 'red' }}>*</span> Tempat Lahir
								</label>
								<select
									className='custom-select'
									id='childrenCity'
									value={fatherForm.bornPlace}
									onChange={e => {
										setFatherForm({ ...fatherForm, bornPlace: e.target.value })
									}}>
									<option selected>Choose...</option>
									{cities.data.map(city => (
										<option value={city.ID} key={city.ID}>
											{city.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<label htmlFor='dateBornChildren'>
								<span style={{ color: 'red' }}>*</span> Kota
							</label>
							<div className='form-group'>
								<select
									className='custom-select'
									id='childrenCity'
									value={fatherForm.cityID}
									onChange={e => {
										setFatherForm({ ...fatherForm, cityID: e.target.value })
									}}>
									<option selected>Choose...</option>
									{cities.data.map(city => (
										<option value={city.ID} key={city.ID}>
											{city.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='motherReligion'>
									<span style={{ color: 'red' }}>*</span> Agama
								</label>
								<select
									className='custom-select'
									id='motherReligion'
									name='religionID'
									value={fatherForm.religionID}
									onChange={e => {
										setFatherForm({ ...fatherForm, religionID: e.target.value })
									}}>
									<option selected>Choose...</option>
									{religion.data.map(_religion => (
										<option value={_religion.ID} key={_religion.ID}>
											{_religion.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-12'>
							<label htmlFor='addressMOtherfucker'>
								<span style={{ color: 'red' }}>*</span> Alamat
							</label>
							<div className='form-group'>
								<textarea
									rows={3}
									id='addressMOtherfucker'
									placeholder='Tanggal Lahir'
									className='form-control'
									value={fatherForm.address}
									onChange={e => {
										setFatherForm({ ...fatherForm, address: e.target.value })
									}}
								/>
							</div>
						</div>
					</div>

					<div className='row mt-2'>
						<div className='col-md-6'></div>
						<div className='col-md-6'>
							<div className='form-group'>
								<button
									className='btn btn-primary btn-block'
									onClick={e => {
										e.preventDefault()
										updateFatherData()
									}}>
									Kirim Data Ayah
								</button>
							</div>
						</div>
					</div>

					<hr />
				</div>
			) : fatherFormAdd ? null : (
				<div className='row'>
					<div className='col-12'>
						<label htmlFor=''></label>
						<button
							className='btn btn-primary btn-block'
							onClick={e => {
								e.preventDefault()
								setFatherFormAdd(form)
							}}>
							Tambah Data Ayah
						</button>
					</div>
				</div>
			)}

			{fatherFormAdd && (
				<div>
					<div className='row'>
						<div className='col-md-12'>
							<div className='form-group'>
								<label htmlFor='childrenName'>
									<span style={{ color: 'red' }}>*</span> Nama Ayah
								</label>
								<input
									type='text'
									id='childrenName'
									placeholder='Nama Ayah'
									className='form-control'
									value={fatherFormAdd.name}
									onChange={e => {
										setFatherFormAdd({ ...fatherFormAdd, name: e.target.value })
									}}
								/>
							</div>
						</div>

						<div className='col-md-6'>
							<label htmlFor='dateBornChildren'>
								<span style={{ color: 'red' }}>*</span> Tanggal Lahir
							</label>
							<div className='form-group'>
								<input
									type='date'
									id='dateBornChildren'
									placeholder='Tanggal Lahir'
									className='form-control'
									value={fatherFormAdd.bornDate}
									onChange={e => {
										setFatherFormAdd({ ...fatherFormAdd, bornDate: e.target.value })
									}}
								/>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='childrenCity'>
									<span style={{ color: 'red' }}>*</span> Tempat Lahir
								</label>
								<select
									className='custom-select'
									id='childrenCity'
									value={fatherFormAdd.bornPlace}
									onChange={e => {
										setFatherFormAdd({ ...fatherFormAdd, bornPlace: e.target.value })
									}}>
									<option selected>Choose...</option>
									{cities.data.map(city => (
										<option value={city.ID} key={city.ID}>
											{city.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<label htmlFor='dateBornChildren'>
								<span style={{ color: 'red' }}>*</span> Kota
							</label>
							<div className='form-group'>
								<select
									className='custom-select'
									id='childrenCity'
									value={fatherFormAdd.cityID}
									onChange={e => {
										setFatherFormAdd({ ...fatherFormAdd, cityID: e.target.value })
									}}>
									<option selected>Choose...</option>
									{cities.data.map(city => (
										<option value={city.ID} key={city.ID}>
											{city.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='motherReligion'>
									<span style={{ color: 'red' }}>*</span> Agama
								</label>
								<select
									className='custom-select'
									id='motherReligion'
									name='religionID'
									value={fatherFormAdd.religionID}
									onChange={e => {
										setFatherFormAdd({ ...fatherFormAdd, religionID: e.target.value })
									}}>
									<option selected>Choose...</option>
									{religion.data.map(_religion => (
										<option value={_religion.ID} key={_religion.ID}>
											{_religion.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-12'>
							<label htmlFor='addressMOtherfucker'>
								<span style={{ color: 'red' }}>*</span> Alamat
							</label>
							<div className='form-group'>
								<textarea
									rows={3}
									id='addressMOtherfucker'
									placeholder='Tanggal Lahir'
									className='form-control'
									value={fatherFormAdd.address}
									onChange={e => {
										setFatherFormAdd({ ...fatherFormAdd, address: e.target.value })
									}}
								/>
							</div>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-md-6'></div>
						<div className='col-md-6'>
							<div className='form-group'>
								<button
									className='btn btn-primary btn-block'
									onClick={e => {
										e.preventDefault()
										addFatherData()
									}}>
									Kirim Data Ayah
								</button>
							</div>
						</div>
					</div>

					<hr />
				</div>
			)}
		</form>
	)
}

export default FatherForm
