import React, { createContext, useState, useEffect } from 'react'
import API from '../services/API'
import moment from 'moment'
import { useAlert } from 'react-alert'

export const AbsentContext = createContext()
export const AbsentContextConsumer = AbsentContext.Consumer

const AbsentContextProvider = ({ children }) => {
	const alert = useAlert()
	const [attendanceStatus, setAttendanceStatus] = useState({ data: {}, isLoading: true })
	const [submitting, setSubmitting] = useState(false)
	const [modalContent, setModalContent] = useState(null)
	const [modalDutyContent, setModalDutyContent] = useState(null)

	const clockIn = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.checkIn()
				getAttendanceStatus()
				setSubmitting(false)
				alert.success('Sukses absen masuk')
			} catch (error) {
				setSubmitting(false)
				alert.error('Gagal absen masuk')
			}
		}
	}

	const getAttendanceStatus = async () => {
		try {
			const response = await API.attendanceStatus(moment().format('YYYY-MM-DD'))
			setAttendanceStatus({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getAttendanceStatus()
	}, [])

	return (
		<AbsentContext.Provider
			value={{
				attendanceStatus,
				setAttendanceStatus,
				clockIn,
				getAttendanceStatus,
				submitting,
				setSubmitting,
				modalContent,
				setModalContent,
				modalDutyContent,
				setModalDutyContent,
			}}>
			{children}
		</AbsentContext.Provider>
	)
}

export default AbsentContextProvider
