import Axios from 'axios'

let token

try {
	if (localStorage.getItem('@user_token')) {
		token = localStorage.getItem('@user_token')
	}
	if (sessionStorage.getItem('@user_token')) {
		token = sessionStorage.getItem('@user_token')
	}
} catch (error) {
	token = null
}

Axios.defaults.baseURL = process.env.REACT_APP_BASE_API_LEGACY_URL
Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
Axios.defaults.headers.common['Role'] = 'Employee'
Axios.defaults.headers.post['Content-Type'] = `multipart/form-data`

const headersConfig = {
	Authorization: `Bearer ${token}`,
	Role: 'Employee',
	'Content-Type': `multipart/form-data`,
}

const defaultAxios = Axios.create({
	baseURL: process.env.REACT_APP_BASE_API_LEGACY_URL,
	headers: headersConfig,
})

const portingAxios = Axios.create({
	baseURL: process.env.REACT_APP_BASE_API_PORTING_URL,
	headers: headersConfig,
})

const latestAxios = Axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL,
	headers: headersConfig,
})

export default { defaultAxios, portingAxios, latestAxios}
