import React, { useEffect, useContext, useState, Fragment } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const BankForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const form = { number: '', bankID: '' }

	const [employeeBank, setEmployeeBank] = useState(null)
	const [employeeBankAdd, setEmployeeBankAdd] = useState(null)

	const [submitting, setSubmitting] = useState(false)

	const { employeeProfileEdit, getUserProfile, banks } = authContext

	const onUpdateBank = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.updateEmployeeBank(employeeBank)
				alert.show('Sukses Ubah Bank', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				console.log(error)
				alert.show('Gagal Ubah Bank', { type: 'error' })
			}
		}
	}

	const onAddBank = async e => {
		e.preventDefault()
		if (!submitting) {
			try {
				await API.addEmployeeBank(employeeBankAdd)
				alert.show('Sukses Tambah Bank', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
				setEmployeeBankAdd(null)
			} catch (error) {
				setSubmitting(false)
				console.log(error)
				alert.show('Gagal Tambah Bank', { type: 'error' })
			}
		}
	}

	useEffect(() => {
		if (employeeProfileEdit.data.employeeBank) {
			setEmployeeBank({
				bankID: employeeProfileEdit.data.employeeBank.bankID,
				number: employeeProfileEdit.data.employeeBank.number,
			})
		}
	}, [employeeProfileEdit])

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Informasi Bank</h4>
			{employeeBank ? (
				<Fragment>
					<div className='row'>
						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='employeeBankAdd'>
									<span style={{ color: 'red' }}>*</span> Bank
								</label>
								<select
									className='custom-select'
									id='employeeBankAdd'
									value={employeeBank.bankID}
									onChange={e => setEmployeeBank({ ...employeeBank, bankID: e.target.value })}>
									<option selected>Choose...</option>
									{banks.data.map(bank => (
										<option value={bank.ID} key={bank.ID}>
											{bank.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='rekening'>
									<span style={{ color: 'red' }}>*</span> No. Rekening
								</label>
								<input
									value={employeeBank.number}
									onChange={e => setEmployeeBank({ ...employeeBank, number: e.target.value })}
									type='text'
									name='rekening'
									className='form-control'
									id='rekening'
									placeholder='No.Rekening'
								/>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-md-6'></div>

						<div className='col-md-6'>
							<button className='btn btn-primary btn-block' disabled={submitting} onClick={onUpdateBank}>
								Ubah No. Rekening
							</button>
						</div>
					</div>
				</Fragment>
			) : employeeBankAdd ? null : (
				<div className='row'>
					<div className='col-12'>
						<label htmlFor=''></label>
						<button
							className='btn btn-primary btn-block'
							onClick={e => {
								e.preventDefault()
								setEmployeeBankAdd(form)
							}}>
							Tambah Bank
						</button>
					</div>
				</div>
			)}

			{employeeBankAdd ? (
				<Fragment>
					<div className='row'>
						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='employeeBankAdd'>
									<span style={{ color: 'red' }}>*</span> Bank
								</label>
								<select
									className='custom-select'
									id='employeeBankAdd'
									value={employeeBankAdd.bankID}
									onChange={e => setEmployeeBank({ ...employeeBankAdd, bankID: e.target.value })}>
									<option selected>Choose...</option>
									{banks.data.map(bank => (
										<option value={bank.ID} key={bank.ID}>
											{bank.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='rekening'>
									<span style={{ color: 'red' }}>*</span> No. Rekening
								</label>
								<input
									type='text'
									name='rekening'
									value={employeeBankAdd.number}
									onChange={e => setEmployeeBankAdd({ ...employeeBankAdd, number: e.target.value })}
									className='form-control'
									id='rekening'
									placeholder='No.Rekening'
								/>
							</div>
						</div>
					</div>

					<div className='row'>
						<div className='col-md-6'></div>

						<div className='col-md-6'>
							<button className='btn btn-primary btn-block' disabled={submitting} onClick={onAddBank}>
								Tambah No. Rekening
							</button>
						</div>
					</div>
				</Fragment>
			) : null}
		</form>
	)
}

export default BankForm
