import React, { Fragment, useContext } from 'react'
import ContentLoader from 'react-content-loader'
import { AbsentContext } from '../../../Context/AbsentContext'
import { LeaveContext } from '../../../Context/LeaveContext'
import API from '../../../services/API'

const CalendarDate = ({ employeActivity, isLoading, date }) => {
	const leaveContext = useContext(LeaveContext)
	const absentContext = useContext(AbsentContext)

	const { getLeaveDetail } = leaveContext
	return (
		<div className='mt-2'>
			{isLoading ? (
				<ContentLoader speed={2} height={64} backgroundColor='#f3f3f3' foregroundColor='#ecebeb'>
					<rect x='9' y='1' rx='0' ry='0' width='104' height='20' />
					<rect x='9' y='32' rx='0' ry='0' width='104' height='20' />
				</ContentLoader>
			) : !employeActivity ? null : (
				<Fragment>
					{employeActivity.leave && (
						<div
							className='react-calendar-badge'
							style={{ background: '#ef476f', color: '#fff' }}
							data-toggle='modal'
							data-target='#modalLeaveDetail'
							onClick={() => {
								getLeaveDetail(employeActivity.leave.ID)
							}}>
							<span>Cuti</span>
						</div>
					)}

					{employeActivity.duty && (
						<div
							className='react-calendar-badge'
							style={{ background: '#118ab2', color: '#fff' }}
							data-toggle='modal'
							data-target='#modalDuty'
							onClick={() => {
								console.log(employeActivity.duty)
								absentContext.setModalDutyContent({
									...employeActivity.duty,
									selectedDate: date.format('DD MMMM YYYY'),
								})
							}}>
							<span>Dinas</span>
						</div>
					)}

					{employeActivity.attendance && (
						<div
							className='react-calendar-badge'
							style={{ background: employeActivity.attendance.checkIn ? '#06d6a0' : '#ebf8ff', color: employeActivity.attendance.checkIn ?'#fff': '#000' }}
							data-toggle='modal'
							data-target='#modalAbsentDetail'
							onClick={() => {
								API.getDetailAttendance(employeActivity.attendance.ID).then(response => {
									absentContext.setModalContent({
										...response.data,
										selectedDate: date.format('DD MMMM YYYY'),
									})
								})
							}}>
							<span>{employeActivity.attendance.checkIn ? 'Masuk' : 'Log'}</span>
						</div>
					)}
				</Fragment>
			)}
		</div>
	)
}

export default CalendarDate
