import React, { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const BPJSForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const form = { number: '' }
	const [BPJS, setBPJS] = useState(null)
	const [BPJSAdd, setBPJSAdd] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	const { employeeProfileEdit, getUserProfile } = authContext

	const onUpdateBPJS = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.updateEmployeeBPJS(BPJS)
				alert.show('Sukses Ubah BPJS', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				console.log(error)

				alert.show('Gagal Ubah BPJS', { type: 'error' })
			}
		}
	}

	const onAddBPJS = async e => {
		e.preventDefault()
		if (!submitting) {
			try {
				await API.addEmployeeBPJS(BPJSAdd)
				alert.show('Sukses Tambah BPJS', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
				setBPJSAdd(null)
			} catch (error) {
				setSubmitting(false)
				console.log(error)
				alert.show('Gagal Tambah BPJS', { type: 'error' })
			}
		}
	}

	useEffect(() => {
		if (employeeProfileEdit.data.BPJS) {
			setBPJS(employeeProfileEdit.data.BPJS)
		}
	}, [employeeProfileEdit])

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>BPJS</h4>
			{BPJS ? (
				<div className='row'>
					<div className='col-md-6'>
						<div className='form-group'>
							<label htmlFor='employeeBPJS'>
								<span style={{ color: 'red' }}>*</span> Nomor BPJS
							</label>
							<input
								type='text'
								placeholder='BPJS'
								className='form-control'
								id='employeeBPJS'
								value={BPJS.number}
								onChange={e => setBPJS({ ...BPJS, number: e.target.value })}
							/>
						</div>
					</div>

					<div className='col-md-6'>
						<label htmlFor=''></label>
						<button className='btn btn-primary btn-block mt-2' disabled={submitting} onClick={onUpdateBPJS}>
							Ubah BPJS
						</button>
					</div>
				</div>
			) : BPJSAdd ? null : (
				<div className='row'>
					<div className='col-12'>
						<label htmlFor=''></label>
						<button
							className='btn btn-primary btn-block'
							onClick={e => {
								e.preventDefault()
								setBPJSAdd(form)
							}}>
							Tambah BPJS
						</button>
					</div>
				</div>
			)}

			{BPJSAdd ? (
				<div className='row'>
					<div className='col-md-6'>
						<div className='form-group'>
							<label htmlFor='employeeBPJSAdd'>Nomor BPJS</label>
							<input
								id='employeeBPJSAdd'
								type='text'
								placeholder='BPJS'
								className='form-control'
								value={BPJSAdd.number}
								onChange={e => setBPJSAdd({ ...BPJSAdd, number: e.target.value })}
							/>
						</div>
					</div>

					<div className='col-md-6'>
						<label htmlFor=''></label>
						<button className='btn btn-primary btn-block mt-2' disabled={submitting} onClick={onAddBPJS}>
							Tambah BPJS
						</button>
					</div>
				</div>
			) : null}
		</form>
	)
}

export default BPJSForm
