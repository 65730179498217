import React, { createContext, useState, useEffect, useRef } from 'react'
import API from '../services/API'
import moment from 'moment'
import OneSignal, { useOneSignalSetup } from 'react-onesignal'

export const AuthContext = createContext()
export const AuthContextConsumer = AuthContext.Consumer

const AuthContextProvider = ({ children, history }) => {
	const _modalRef = useRef(null)

	const [userProfile, setUserProfile] = useState({ data: null, isLoading: true })
	const [employeeProfile, setEmployeeProfile] = useState({ data: null, isLoading: true })
	const [employeeProfileEdit, setEmployeeProfileEdit] = useState({ data: null, isLoading: true })
	const [banks, setBanks] = useState({ data: [], isLoading: true })
	const [cities, setCities] = useState({ data: [], isLoading: true })
	const [civilianCategories, setCivilianCategories] = useState({ data: [], isLoading: true })
	const [educationTier, setEducationTier] = useState({ data: [], isLoading: true })
	const [employeePosition, setEmployeePosition] = useState({ data: [], isLoading: true })
	const [religion, setReligion] = useState({ data: [], isLoading: true })
	const [is403, setIs403] = useState(false)
	const [homeInfo, setHomeInfo] = useState({ data: [], isLoading: true })

	const setUser = React.useCallback(async () => {
		if (userProfile.data) {
			// OneSignal.set
			await OneSignal.setExternalUserId(`${userProfile.data.ID}`)
			// await OneSignal.setEmail(userProfile.data.email)
			console.log('aa')
		}
	}, [userProfile.data])

	useOneSignalSetup(() => {
		setUser()
	})

	const getUserProfile = async () => {
		try {
			const response = await API.getUser()
			getEmployeeProfile(response.data.employeeAccess.employeeID)
			setUserProfile({
				data: response.data,
				isLoading: false,
			})
			getEmployeeInfo()
			setIs403(false)
		} catch (error) {
			console.log(error)
			setIs403(true)
		}
	}

	const fetchBanks = async () => {
		try {
			const response = await API.getBank()
			setBanks({ data: response.data.list, isLoading: false })
		} catch (error) {
			console.log(error)
		}
	}

	const fetchEducation = async () => {
		try {
			const response = await API.getEducationTier()
			setEducationTier({ data: response.data.list, isLoading: false })
		} catch (error) {
			console.log(error)
		}
	}

	const fetchCivilians = async () => {
		try {
			const response = await API.getEmployeeCategory()
			setCivilianCategories({ data: response.data.list, isLoading: false })
		} catch (error) {
			console.log(error)
		}
	}

	const getEmployeeProfile = async id => {
		try {
			const response = await API.getEmployeeProfile(id)
			setEmployeeProfile({
				data: response.data,
				isLoading: false,
			})
			setEmployeeProfileEdit({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const getEmployeeInfo = async () => {
		try {
			const response = await API.getEmployeeHome()
			setHomeInfo({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchCities = async () => {
		try {
			const response = await API.getCity()
			setCities({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchEmployeePosition = async () => {
		try {
			const response = await API.getEmployeePosition()
			setEmployeePosition({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchReligion = async () => {
		try {
			const response = await API.getReligion()
			setReligion({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getUserProfile()
		fetchCities()
		fetchEmployeePosition()
		fetchReligion()
		fetchBanks()
		fetchCivilians()
		fetchEducation()
	}, [])

	return (
		<AuthContext.Provider
			value={{
				userProfile,
				setUserProfile,
				getUserProfile,
				employeeProfile,
				setEmployeeProfile,
				cities,
				setCities,
				employeePosition,
				setEmployeePosition,
				religion,
				setReligion,
				employeeProfileEdit,
				setEmployeeProfileEdit,
				is403,
				setIs403,
				banks,
				civilianCategories,
				educationTier,
				homeInfo,
				setHomeInfo,
			}}>
			{children}
			<div className='modal' tabIndex={2} role='dialog' id='modal-logout'>
				<div className='modal-dialog' role='document'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Anda yakin keluar ?</h5>
							<button type='button' className='close' data-dismiss='modal' aria-label='Close' ref={_modalRef}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn btn-secondary'
								onClick={async () => {
									localStorage.clear()
									window.location.href = '/login'
									await OneSignal.removeExternalUserId()
								}}>
								Keluar
							</button>
							<button type='button' className='btn btn-primary' data-dismiss='modal'>
								Batal
							</button>
						</div>
					</div>
				</div>
			</div>
		</AuthContext.Provider>
	)
}

export default AuthContextProvider
