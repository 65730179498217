import React from 'react'
import { Link } from 'react-router-dom'
import gravatar from 'gravatar'
import moment from 'moment'

const AspirationCard = ({ aspiration }) => {
	return (
		<div className='aspiration-card'>
			<div className='d-flex'>
				<img
					src={
						aspiration.employee.employeeAvatar
							? aspiration.employee.employeeAvatar.realPath
							: gravatar.url(aspiration.employee.email, { d: 'identicon', s: '200' })
					}
					className='rounded-circle'
					alt='Avatar'
					style={{
						width: '3rem',
						height: '3rem',
						objectFit: 'cover',
						border: '2px solid #172B4D',
					}}
				/>
				<div className='ml-3'>
					<Link to={`/aspiration/${aspiration.ID}`}>
						<h5 className=' mb-0 font-weight-bold'>{aspiration.subject}</h5>
					</Link>
					<span style={{ fontSize: '0.8rem' }}>
						<i className='ni ni-calendar-grid-58'></i> {moment(aspiration.created, 'YYYY-MM-DD').format('DD MMM YYYY')}{' '}
						{/* <strong>&#903;</strong> <i className='ni ni-chat-round'></i> 5 balasan &nbsp;  */}
						<strong>&#903;</strong> &nbsp;
						{aspiration.aspirationReject
							? 'Ditolak'
							: aspiration.aspirationConfirmed === null
							? 'Menunggu konfirmasi'
							: 'Terkonfirmasi'}
					</span>
				</div>
			</div>
		</div>
	)
}

export default AspirationCard
