import React, { useEffect, useContext, useState, Fragment } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const MotherForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const { employeeProfileEdit, getUserProfile, cities, religion } = authContext

	const form = { name: '', bornDate: '', bornPlace: '', religionID: '', cityID: '', address: '' }
	const [motherForm, setMotherForm] = useState(null)
	const [motherFormAdd, setMotherFormAdd] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	useEffect(() => {
		if (employeeProfileEdit.data.mother) {
			setMotherForm(employeeProfileEdit.data.mother)
		}
	}, [employeeProfileEdit])

	const updateMotherData = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.updateEmployeeMother(motherForm)
				alert.show('Sukses Mengubah Data Ibu', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
			} catch (error) {
				alert.show('Gagal Mengubah Data Ibu', { type: 'error' })
				setSubmitting(false)
			}
		}
	}

	const addMotherData = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.addEmployeeMother(motherFormAdd)
				alert.show('Sukses Menambahkan Data Ibu', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
				setMotherFormAdd(null)
			} catch (error) {
				alert.show('Gagal Menambahkan Data Ibu', { type: 'error' })
				setSubmitting(false)
			}
		}
	}

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Data Ibu</h4>

			{motherForm ? (
				<div>
					<div className='row'>
						<div className='col-md-12'>
							<div className='form-group'>
								<label htmlFor='childrenName'>
									<span style={{ color: 'red' }}>*</span> Nama Ibu
								</label>
								<input
									type='text'
									id='childrenName'
									placeholder='Nama Anak'
									className='form-control'
									value={motherForm.name}
									onChange={e => {
										setMotherForm({ ...motherForm, name: e.target.value })
									}}
								/>
							</div>
						</div>

						<div className='col-md-6'>
							<label htmlFor='dateBornChildren'>
								<span style={{ color: 'red' }}>*</span> Tanggal Lahir
							</label>
							<div className='form-group'>
								<input
									type='date'
									id='dateBornChildren'
									placeholder='Tanggal Lahir'
									className='form-control'
									value={motherForm.bornDate}
									onChange={e => {
										setMotherForm({ ...motherForm, bornDate: e.target.value })
									}}
								/>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='childrenCity'>
									<span style={{ color: 'red' }}>*</span> Tempat Lahir
								</label>
								<select
									className='custom-select'
									id='childrenCity'
									value={motherForm.bornPlace}
									onChange={e => {
										setMotherForm({ ...motherForm, bornPlace: e.target.value })
									}}>
									<option selected>Choose...</option>
									{cities.data.map(city => (
										<option value={city.ID} key={city.ID}>
											{city.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<label htmlFor='dateBornChildren'>
								<span style={{ color: 'red' }}>*</span> Kota
							</label>
							<div className='form-group'>
								<select
									className='custom-select'
									id='childrenCity'
									value={motherForm.cityID}
									onChange={e => {
										setMotherForm({ ...motherForm, cityID: e.target.value })
									}}>
									<option selected>Choose...</option>
									{cities.data.map(city => (
										<option value={city.ID} key={city.ID}>
											{city.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='motherReligion'>
									<span style={{ color: 'red' }}>*</span> Agama
								</label>
								<select
									className='custom-select'
									id='motherReligion'
									name='religionID'
									value={motherForm.religionID}
									onChange={e => {
										setMotherForm({ ...motherForm, religionID: e.target.value })
									}}>
									<option selected>Choose...</option>
									{religion.data.map(_religion => (
										<option value={_religion.ID} key={_religion.ID}>
											{_religion.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-12'>
							<label htmlFor='addressMOtherfucker'>
								<span style={{ color: 'red' }}>*</span> Alamat
							</label>
							<div className='form-group'>
								<textarea
									rows={3}
									id='addressMOtherfucker'
									placeholder='Tanggal Lahir'
									className='form-control'
									value={motherForm.address}
									onChange={e => {
										setMotherFormAdd({ ...motherForm, address: e.target.value })
									}}
								/>
							</div>
						</div>
					</div>

					<div className='row mt-2'>
						<div className='col-md-6'></div>
						<div className='col-md-6'>
							<div className='form-group'>
								<button
									className='btn btn-primary btn-block'
									onClick={e => {
										e.preventDefault()
										updateMotherData()
									}}>
									Kirim Data Ibu
								</button>
							</div>
						</div>
					</div>

					<hr />
				</div>
			) : motherFormAdd ? null : (
				<div className='row'>
					<div className='col-12'>
						<label htmlFor=''></label>
						<button
							className='btn btn-primary btn-block'
							onClick={e => {
								e.preventDefault()
								setMotherFormAdd(form)
							}}>
							Tambah Data Ibu
						</button>
					</div>
				</div>
			)}

			{motherFormAdd && (
				<div>
					<div className='row'>
						<div className='col-md-12'>
							<div className='form-group'>
								<label htmlFor='childrenName'>
									<span style={{ color: 'red' }}>*</span> Nama Ibu
								</label>
								<input
									type='text'
									id='childrenName'
									placeholder='Nama Ibu'
									className='form-control'
									value={motherFormAdd.name}
									onChange={e => {
										setMotherFormAdd({ ...motherFormAdd, name: e.target.value })
									}}
								/>
							</div>
						</div>

						<div className='col-md-6'>
							<label htmlFor='dateBornChildren'>
								<span style={{ color: 'red' }}>*</span> Tanggal Lahir
							</label>
							<div className='form-group'>
								<input
									type='date'
									id='dateBornChildren'
									placeholder='Tanggal Lahir'
									className='form-control'
									value={motherFormAdd.bornDate}
									onChange={e => {
										setMotherFormAdd({ ...motherFormAdd, bornDate: e.target.value })
									}}
								/>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='childrenCity'>
									<span style={{ color: 'red' }}>*</span> Tempat Lahir
								</label>
								<select
									className='custom-select'
									id='childrenCity'
									value={motherFormAdd.bornPlace}
									onChange={e => {
										setMotherFormAdd({ ...motherFormAdd, bornPlace: e.target.value })
									}}>
									<option selected>Choose...</option>
									{cities.data.map(city => (
										<option value={city.ID} key={city.ID}>
											{city.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<label htmlFor='dateBornChildren'>
								<span style={{ color: 'red' }}>*</span> Kota
							</label>
							<div className='form-group'>
								<select
									className='custom-select'
									id='childrenCity'
									value={motherFormAdd.cityID}
									onChange={e => {
										setMotherFormAdd({ ...motherFormAdd, cityID: e.target.value })
									}}>
									<option selected>Choose...</option>
									{cities.data.map(city => (
										<option value={city.ID} key={city.ID}>
											{city.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-6'>
							<div className='form-group'>
								<label htmlFor='motherReligion'>
									<span style={{ color: 'red' }}>*</span> Agama
								</label>
								<select
									className='custom-select'
									id='motherReligion'
									name='religionID'
									value={motherFormAdd.religionID}
									onChange={e => {
										setMotherFormAdd({ ...motherFormAdd, religionID: e.target.value })
									}}>
									<option selected>Choose...</option>
									{religion.data.map(_religion => (
										<option value={_religion.ID} key={_religion.ID}>
											{_religion.name}
										</option>
									))}
								</select>
							</div>
						</div>

						<div className='col-md-12'>
							<label htmlFor='addressMOtherfucker'>
								<span style={{ color: 'red' }}>*</span> Alamat
							</label>
							<div className='form-group'>
								<textarea
									rows={3}
									id='addressMOtherfucker'
									placeholder='Tanggal Lahir'
									className='form-control'
									value={motherFormAdd.address}
									onChange={e => {
										setMotherFormAdd({ ...motherFormAdd, address: e.target.value })
									}}
								/>
							</div>
						</div>
					</div>
					<div className='row mt-2'>
						<div className='col-md-6'></div>
						<div className='col-md-6'>
							<div className='form-group'>
								<button
									className='btn btn-primary btn-block'
									onClick={e => {
										e.preventDefault()
										addMotherData()
									}}>
									Kirim Data Ibu
								</button>
							</div>
						</div>
					</div>

					<hr />
				</div>
			)}

			{/* <div className='row mt-2'>
				<div className='col-md-6'>
					<div className='form-group'>
						<button
							className='btn btn-secondary btn-block'
							onClick={e => {
								e.preventDefault()
								setMotherFormAdd([...motherFormAdd, form])
							}}>
							+ Tambah Data Ibu
						</button>
					</div>
				</div>

				<div className='col-md-6'>
					<div className='form-group'>
						<button
							className='btn btn-primary btn-block'
							onClick={e => {
								e.preventDefault()
								addMotherData()
							}}
							disabled={submitting}>
							Kirim Data Ibu
						</button>
					</div>
				</div>
			</div> */}
		</form>
	)
}

export default MotherForm
