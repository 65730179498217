import React, { createContext, useState, useEffect } from 'react'
import API from '../services/API'
import moment from 'moment'

export const LeaveContext = createContext()
export const LeaveContextConsumer = LeaveContext.Consumer

const LEAVE_ALL = 1
const LEAVE_PENDING = 3
const LEAVE_REJECT = 2
const LEAVE_APPROVED = 4

const LeaveContextProvider = ({ children }) => {
	const [leaveStatus, setLeaveStatus] = useState({ data: [], isLoading: true, isMore: false, itemCount: 0 })
	const [leaveStatusPending, setLeaveStatusPending] = useState({
		data: [],
		isLoading: true,
		isMore: false,
		itemCount: 0,
	})
	const [leaveStatusReject, setLeaveStatusReject] = useState({ data: [], isLoading: true, isMore: false, itemCount: 0 })
	const [leaveStatusApprove, setLeaveStatusApprove] = useState({
		data: [],
		isLoading: true,
		isMore: false,
		itemCount: 0,
	})

	const [leaveStatusPage, setLeaveStatusPage] = useState(1)
	const [leaveStatusPendingPage, setLeaveStatusPendingPage] = useState(1)
	const [leaveStatusRejectPage, setLeaveStatusRejectPage] = useState(1)
	const [leaveStatusApprovePage, setLeaveStatusApprovePage] = useState(1)

	const [selectedLabel, setSelectedLabel] = useState(LEAVE_ALL)
	const [leaveDetail, setLeaveDetail] = useState({ data: null, isLoading: true })

	const getLeaveDetail = async id => {
		setLeaveDetail(prev => ({ ...prev, isLoading: true }))
		try {
			const response = await API.getDetailLeave(id)
			setLeaveDetail({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const resetLeave = () => {
		setLeaveStatus(prev => ({
			...prev,
			data: [],
		}))

		setLeaveStatusPending(prev => ({
			...prev,
			data: [],
		}))

		setLeaveStatusReject(prev => ({
			...prev,
			data: [],
		}))

		setLeaveStatusApprove(prev => ({
			...prev,
			data: [],
		}))
	}

	const getLeaveStatus = async () => {
		try {
			const response = await API.getLeaveStatus({
				type: LEAVE_ALL,
				pageSize: 5,
				currentPage: leaveStatusPage,
				sort: 'created',
				order: 'desc',
			})
			setLeaveStatus(prev => ({
				data: [...prev.data, ...response.data.list],
				isLoading: false,
				isMore: response.data.pagination.isMore,
				itemCount: response.data.pagination.itemCount,
			}))
		} catch (error) {
			console.log(error)
		}
	}

	const getLeaveStatusPending = async () => {
		try {
			const response = await API.getLeaveStatus({
				type: LEAVE_PENDING,
				pageSize: 5,
				currentPage: leaveStatusPendingPage,
				sort: 'created',
				order: 'desc',
			})
			setLeaveStatusPending(prev => ({
				data: [...prev.data, ...response.data.list],
				isLoading: false,
				isMore: response.data.pagination.isMore,
				itemCount: response.data.pagination.itemCount,
			}))
		} catch (error) {
			console.log(error)
		}
	}
	const getLeaveStatusReject = async () => {
		try {
			const response = await API.getLeaveStatus({
				type: LEAVE_REJECT,
				pageSize: 5,
				currentPage: leaveStatusRejectPage,
				sort: 'created',
				order: 'desc',
			})
			setLeaveStatusReject(prev => ({
				data: [...prev.data, ...response.data.list],
				isLoading: false,
				isMore: response.data.pagination.isMore,
				itemCount: response.data.pagination.itemCount,
			}))
		} catch (error) {
			console.log(error)
		}
	}
	const getLeaveStatusApprove = async () => {
		try {
			const response = await API.getLeaveStatus({
				type: LEAVE_APPROVED,
				pageSize: 5,
				currentPage: leaveStatusApprovePage,
				sort: 'created',
				order: 'desc',
			})
			setLeaveStatusApprove(prev => ({
				data: [...prev.data, ...response.data.list],
				isLoading: false,
				isMore: response.data.pagination.isMore,
				itemCount: response.data.pagination.itemCount,
			}))
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		getLeaveStatus()
	}, [leaveStatusPage])

	useEffect(() => {
		getLeaveStatusReject()
	}, [leaveStatusRejectPage])

	useEffect(() => {
		getLeaveStatusApprove()
	}, [leaveStatusApprovePage])

	useEffect(() => {
		getLeaveStatusPending()
	}, [leaveStatusPendingPage])

	return (
		<LeaveContext.Provider
			value={{
				getLeaveStatus,
				leaveStatus,
				setLeaveStatus,
				selectedLabel,
				setSelectedLabel,
				getLeaveStatusReject,
				getLeaveStatusApprove,
				getLeaveStatusPending,
				leaveStatusPending,
				leaveStatusReject,
				leaveStatusApprove,
				getLeaveDetail,
				leaveDetail,
				setLeaveDetail,
				leaveStatusPage,
				setLeaveStatusPage,
				leaveStatusPendingPage,
				setLeaveStatusPendingPage,
				leaveStatusRejectPage,
				setLeaveStatusRejectPage,
				leaveStatusApprovePage,
				setLeaveStatusApprovePage,
				resetLeave,
			}}>
			{children}
		</LeaveContext.Provider>
	)
}

export default LeaveContextProvider
