import React, { Fragment, useState, useContext } from 'react'
import Navbar from '../../Components/Navbar'
import { useEffect } from 'react'
import API from '../../services/API'
import moment from 'moment'
import { AuthContext } from '../../Context/AuthContext'

const Logs = () => {
	const authContext = useContext(AuthContext)
	const { employeeProfile } = authContext
	const [attendanceList, setAttendanceList] = useState({ data: [], isLoading: true })

	const fetchAttendanceList = async () => {
		try {
			const response = await API.getAttendanceList({ order: 'desc', sort: 'created' })

			setAttendanceList({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchAttendanceList()
	}, [])

	return (
		<Fragment>
			<Navbar />
			<div className='container py-5'>
				<h1>Daftar Log Harian</h1>
				<br />
				{attendanceList.isLoading ? (
					<div className='text-center'>
						<img
							src={require('../../assets/images/undraw_fast_loading_0lbh.png')}
							style={{ objectFit: 'cover', height: '14rem' }}
							alt='Loading'
						/>

						<h2>Loading ...</h2>
					</div>
				) : (
					<div>
						{!employeeProfile.isLoading && (
							<a
								href={`${process.env.REACT_APP_BASE_API_LEGACY_URL}/employee/employee-attendance/export?employeeID=${employeeProfile.data.ID}`}
								target='_blank'
								rel='noopener noreferrer'>
								<button
									type='submit'
									className='btn btn-sm my-3 ml-auto'
									style={{ background: '#172B4D', color: '#e5e5e5', width: '10rem', float: 'right' }}>
									Export data
								</button>
							</a>
						)}

						<table className='table'>
							<thead>
								<tr>
									<th>Tanggal</th>
									<th>Jam Masuk</th>
									<th>Jam Keluar</th>
									<th>Aktifitas</th>
								</tr>
							</thead>
							<tbody>
								{attendanceList.data.map((attendance, index) => (
									<tr key={index}>
										<td>{moment(attendance.date, 'YYYY-MM-DD').format('DD MMMM YYYY')}</td>
										<td>
											{attendance.employeeCheckIn
												? moment(attendance.employeeCheckIn.created, 'YYYY-MM-DD HH:mm:ss').format('HH:mm::ss')
												: '-'}
										</td>
										<td>
											{attendance.employeeCheckOut
												? moment(attendance.employeeCheckOut.created, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
												: '-'}
										</td>
										<td>
											<ul>
												<li>
													Pagi (08:00 - 10:00)
													<br />
													{attendance.employeeActivities
														.filter(data => data.type == 1)
														.map(log => {
															return log.activity
														})}
												</li>
												<li>
													Siang (10:00 - 12:00)
													<br />
													{attendance.employeeActivities
														.filter(data => data.type == 2)
														.map(log => {
															return log.activity
														})}
												</li>
												<li>
													Sore (13:00 - 15:00)
													<br />
													{attendance.employeeActivities
														.filter(data => data.type == 3)
														.map(log => {
															return log.activity
														})}
												</li>
												<li>
													Informasi Tambahan
													<br />
													{attendance.employeeAttendanceInfo ? attendance.employeeAttendanceInfo.info : '-'}
												</li>
											</ul>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				)}
			</div>
		</Fragment>
	)
}

export default Logs
