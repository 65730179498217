import React, { useEffect, useContext, useState, Fragment } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const FamilyForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const form = { marriedStatus: '' }

	const [employeeFamily, setEmployeeFamily] = useState(null)
	const [employeeFamilyAdd, setEmployeeFamilyAdd] = useState(null)

	const [submitting, setSubmitting] = useState(false)

	const { employeeProfileEdit, getUserProfile } = authContext

	const onUpdateFamily = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.updateEmployeeFamily(employeeFamily)
				alert.show('Sukses Ubah Data Keluarga', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				console.log(error)
				alert.show('Gagal Ubah Data Keluarga', { type: 'error' })
			}
		}
	}

	const onAddFamily = async e => {
		e.preventDefault()
		if (!submitting) {
			try {
				await API.addEmployeeFamily(employeeFamilyAdd)
				alert.show('Sukses Tambah Data Keluarga', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
				setEmployeeFamilyAdd(null)
			} catch (error) {
				setSubmitting(false)
				console.log(error)
				alert.show('Gagal Tambah Data Keluarga', { type: 'error' })
			}
		}
	}

	useEffect(() => {
		if (employeeProfileEdit.data.employeeFamily) {
			setEmployeeFamily(employeeProfileEdit.data.employeeFamily)
		}
	}, [employeeProfileEdit])

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Data Keluarga</h4>
			{employeeFamily ? (
				<div className='row'>
					<div className='col-md-6'>
						<div className='form-group'>
							<label htmlFor='employeeFamilyType'>
								<span style={{ color: 'red' }}>*</span> Status Pernikahan
							</label>
							<select
								className='custom-select'
								id='employeeFamilyType'
								value={employeeFamily.marriedStatus}
								onChange={e => setEmployeeFamily({ ...employeeFamily, marriedStatus: e.target.value })}>
								<option selected>Choose...</option>
								<option value={1}>Pasangan PNS</option>
								<option value={2}>Pasangan Non-PNS</option>
								<option value={3}>Single</option>
								<option value={4}>Janda/Duda</option>
							</select>
						</div>
					</div>

					<div className='col-md-6'>
						<label htmlFor=''></label>
						<button className='btn btn-primary btn-block mt-2' disabled={submitting} onClick={onUpdateFamily}>
							Ubah Data Keluarga
						</button>
					</div>
				</div>
			) : employeeFamilyAdd ? null : (
				<div className='row'>
					<div className='col-12'>
						<button
							className='btn btn-primary btn-block'
							onClick={e => {
								e.preventDefault()
								setEmployeeFamilyAdd(form)
							}}>
							Tambah Data Keluarga
						</button>
					</div>
				</div>
			)}

			{employeeFamilyAdd ? (
				<div className='row'>
					<div className='col-md-6'>
						<div className='form-group'>
							<label htmlFor='employeeFamilyType'>
								<span style={{ color: 'red' }}>*</span> Status Pernikahan
							</label>
							<select
								className='custom-select'
								id='employeeFamilyType'
								value={employeeFamilyAdd.marriedStatusID}
								onChange={e => setEmployeeFamilyAdd({ ...employeeFamilyAdd, marriedStatus: e.target.value })}>
								<option selected>Choose...</option>
								<option value={1}>Pasangan PNS</option>
								<option value={2}>Pasangan Non-PNS</option>
								<option value={3}>Single</option>
								<option value={4}>Janda/Duda</option>
							</select>
						</div>
					</div>

					<div className='col-md-6'>
						<label htmlFor=''></label>
						<button className='btn btn-primary btn-block mt-2' disabled={submitting} onClick={onAddFamily}>
							Tambah Data Keluarga
						</button>
					</div>
				</div>
			) : null}
		</form>
	)
}

export default FamilyForm
