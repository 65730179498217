import axios from '../utils/axios'
import queryString from 'query-string'
import moment from 'moment'

const Axios = axios.portingAxios

export const getVisitor = (params = {}) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await Axios.get(`/appointment/meeting?expand=responses,visitor&${queryString.stringify(params)}`)
			resolve(response.data)
		} catch (error) {
			reject(error)
		}
	})
}

export const acceptGuest = meetingID => {
	return new Promise(async (resolve, reject) => {
		try {
			const formData = new FormData()
			formData.append('status', '1')
			const response = await Axios.post(`/appointment/meeting/${meetingID}/update/response`, formData)
			resolve(response.data)
		} catch (error) {
			reject(error)
		}
	})
}

export const rejectGuest = (meetingID, note) => {
	return new Promise(async (resolve, reject) => {
		try {
			const formData = new FormData()
			formData.append('status', '0')
			formData.append('note', note)
			const response = await Axios.post(`/appointment/meeting/${meetingID}/update/response`, formData)
			resolve(response.data)
		} catch (error) {
			reject(error)
		}
	})
}
