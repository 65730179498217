import React, { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const EmailForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const form = { email: '' }
	const [emailMendikbud, setEmailMendikbud] = useState(null)
	const [emailMendikbudAdd, setEmailMendikbudAdd] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	const { employeeProfileEdit, getUserProfile } = authContext

	const onUpdateEmail = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.updateEmailDikbud(emailMendikbud)
				alert.show('Sukses Ubah Email Kemdikbud', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
			} catch (error) {
				setSubmitting(false)
				console.log(error)

				alert.show('Gagal Ubah Email Kemdikbud', { type: 'error' })
			}
		}
	}

	const onAddEmail = async e => {
		e.preventDefault()
		if (!submitting) {
			try {
				await API.addEmailDikbud(emailMendikbudAdd)
				alert.show('Sukses Tambah Email Kemdikbud', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
				setEmailMendikbudAdd(null)
			} catch (error) {
				setSubmitting(false)
				console.log(error)
				alert.show('Gagal Tambah Email Kemdikbud', { type: 'error' })
			}
		}
	}

	useEffect(() => {
		if (employeeProfileEdit.data.emailMendikbud) {
			setEmailMendikbud(employeeProfileEdit.data.emailMendikbud)
		}
	}, [employeeProfileEdit])

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Email Kemdikbud</h4>
			{emailMendikbud ? (
				<div className='row'>
					<div className='col-md-6'>
						<div className='form-group'>
							<label htmlFor='employeeEmailDikbud'>
								<span style={{ color: 'red' }}>*</span> Email
							</label>
							<input
								type='email'
								placeholder='Email'
								className='form-control'
								id='employeeEmailDikbud'
								value={emailMendikbud.email}
								onChange={e => setEmailMendikbud({ ...emailMendikbud, email: e.target.value })}
							/>
						</div>
					</div>

					<div className='col-md-6'>
						<label htmlFor=''></label>
						<button className='btn btn-primary btn-block mt-2' disabled={submitting} onClick={onUpdateEmail}>
							Ubah Email Kemdikbud
						</button>
					</div>
				</div>
			) : emailMendikbudAdd ? null : (
				<div className='row'>
					<div className='col-12'>
						<button
							className='btn btn-primary btn-block'
							onClick={e => {
								e.preventDefault()
								setEmailMendikbudAdd(form)
							}}>
							Tambah Email Kemdikbud
						</button>
					</div>
				</div>
			)}

			{emailMendikbudAdd ? (
				<div className='row'>
					<div className='col-md-6'>
						<div className='form-group'>
							<label htmlFor='employeeEmailDikbudAdd'>Email</label>
							<input
								type='email'
								placeholder='Email'
								className='form-control'
								id='employeeEmailDikbudAdd'
								value={emailMendikbudAdd.email}
								onChange={e => setEmailMendikbudAdd({ ...emailMendikbudAdd, email: e.target.value })}
							/>
						</div>
					</div>

					<div className='col-md-6'>
						<label htmlFor=''></label>
						<button className='btn btn-primary btn-block mt-2' disabled={submitting} onClick={onAddEmail}>
							Tambah Email Kemdikbud
						</button>
					</div>
				</div>
			) : null}
		</form>
	)
}

export default EmailForm
