import axios from '../utils/axios'
import queryString from 'query-string'
import moment from 'moment'

const Axios = axios.defaultAxios

export default class DutyAPI {
	static createEmployeeDuty(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('number', body.number)
				formData.append('description', body.description)
				body.selectedDate.forEach(date => {
					formData.append('dates[]', date)
				})

				const response = await Axios.post(`/duty`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getDuty(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/duty?${queryString.stringify(params)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
