import React, { Fragment, useContext, useState, useRef, useEffect } from 'react'
import Navbar from '../../Components/Navbar'
import Calendar from 'react-calendar'
import LeaveCard from './Components/LeaveCard'
import LogCardHeader from './Components/LogCardHeader'
import LogCard from './Components/LogCard'

import { AbsentContext } from '../../Context/AbsentContext'
import { AuthContext } from '../../Context/AuthContext'
import { LeaveContext } from '../../Context/LeaveContext'
import { useAlert } from 'react-alert'
import ScrollContainer from 'react-indiana-drag-scroll'

import moment from 'moment'
import 'moment/locale/id'
import API from '../../services/API'
import { Link, useHistory } from 'react-router-dom'
import CalendarDate from './Components/CalendarDate'
import ModalLeaveDetail from './Components/ModalLeaveDetail'
import ModalAbsentDetail from './Components/ModalAbsentDetail'
import ModalCreateAttendance from './Components/ModalCreateAttendance'
import DutyCard from './Components/DutyCard'
import DutyAPI from '../../services/DutyAPI'
import ModalDuty from './Components/ModalDuty'
import ModalExportAttendance from './Components/ModalExportAttendance'
import ModalExportDuty from './Components/ModalExportDuty'
import ModalLeaveExport from './Components/ModalLeaveExport'

const LEAVE_ALL = 1
const LEAVE_PENDING = 3
const LEAVE_REJECT = 2
const LEAVE_APPROVED = 4

const Container = () => {
	const history = useHistory()
	const alert = useAlert()
	const absentContext = useContext(AbsentContext)
	const authContext = useContext(AuthContext)
	const leaveContext = useContext(LeaveContext)

	const closeModalBtn = useRef(null)
	const uploadRef = useRef(null)

	const { attendanceStatus, submitting, clockIn, showSuccesAlert, getAttendanceStatus } = absentContext
	const { userProfile, is403, getUserProfile } = authContext
	const [attendanceList, setAttendanceList] = useState({ data: [], isLoading: true, isMore: false })
	const [attendanceListPage, setAttendanceListPage] = useState(1)
	const [selectedModalDate, setSelectedModalDate] = useState(null)

	const [dutyList, setDutyList] = useState({ data: [], isLoading: true, isMore: false })
	const [dutyListPage, setDutyListPage] = useState(1)

	const [calendarEmployeeActivities, setCalendarEmployeeActivities] = useState({ data: null, isLoading: true })
	const [activeCalendarDate, setActiveCalendarDate] = useState(moment().format('YYYY-MM-DD'))
	const {
		leaveStatus,
		leaveStatusPending,
		leaveStatusReject,
		leaveStatusApprove,
		selectedLabel,
		setSelectedLabel,
		setLeaveStatusPage,
		setLeaveStatusPendingPage,
		setLeaveStatusRejectPage,
		setLeaveStatusApprovePage,
	} = leaveContext

	const [isError, setIsError] = useState(false)
	const [submittingClockOut, setSubmittingClockOut] = useState(false)
	const [clockOutForm, setCloclOutForm] = useState([
		{
			type: 1,
			activity: '',
		},
		{
			type: 2,
			activity: '',
		},
		{
			type: 3,
			activity: '',
		},
		{
			info: '',
		},
	])

	const fetchAttendanceList = async () => {
		try {
			const response = await API.getAttendanceList({
				order: 'desc',
				sort: 'created',
				pageSize: 5,
				currentPage: attendanceListPage,
			})

			setAttendanceList(prev => ({
				data: [...prev.data, ...response.data.list],
				isLoading: false,
				isMore: response.data.pagination.isMore,
			}))
		} catch (error) {
			console.log(error)
		}
	}

	const fetchCalendarEmployeActivities = async () => {
		try {
			setCalendarEmployeeActivities(prev => ({ ...prev, isLoading: true }))
			const response = await API.getEmployeeCalendarActivities(activeCalendarDate)

			setCalendarEmployeeActivities({ isLoading: false, data: response.data })
		} catch (error) {
			console.log(error)
		}
	}

	const fetchDutyList = async () => {
		try {
			const response = await DutyAPI.getDuty({ order: 'desc', sort: 'created', pageSize: 3, currentPage: dutyListPage })
			setDutyList(prev => ({
				data: [...prev.data, ...response.data.list],
				isLoading: false,
				isMore: response.data.pagination.isMore,
			}))
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchDutyList()
	}, [dutyListPage])

	useEffect(() => {
		fetchAttendanceList()
	}, [attendanceListPage])

	useEffect(() => {
		fetchCalendarEmployeActivities()
	}, [activeCalendarDate])

	const getSelectedLeave = () => {
		switch (selectedLabel) {
			case LEAVE_ALL:
				return leaveStatus.isLoading ? null : leaveStatus.data.length === 0 ? (
					<EmptyState />
				) : (
					<div>
						{leaveStatus.data.map((status, index) => (
							<LeaveCard key={index} leaveStatus={status} userProfile={userProfile} />
						))}
						<div className='d-flex justify-content-center align-items-center  my-2 mb-4'>
							{leaveStatus.isMore && (
								<button
									className='btn btn-primary btn-sm'
									onClick={() => {
										setLeaveStatusPage(page => page + 1)
									}}>
									Show More
								</button>
							)}
						</div>
					</div>
				)
			case LEAVE_APPROVED:
				return leaveStatusApprove.isLoading ? null : leaveStatusApprove.data.length === 0 ? (
					<EmptyState />
				) : (
					<div>
						{leaveStatusApprove.data.map((status, index) => (
							<LeaveCard key={index} leaveStatus={status} userProfile={userProfile} />
						))}

						<div className='d-flex justify-content-center align-items-center  my-2 mb-4'>
							{leaveStatusApprove.isMore && (
								<button
									className='btn btn-primary btn-sm'
									onClick={() => {
										setLeaveStatusApprovePage(page => page + 1)
									}}>
									Show More
								</button>
							)}
						</div>
					</div>
				)
			case LEAVE_PENDING:
				return leaveStatusPending.isLoading ? null : leaveStatusPending.data.length === 0 ? (
					<EmptyState />
				) : (
					<div>
						{leaveStatusPending.data.map((status, index) => (
							<LeaveCard key={index} leaveStatus={status} userProfile={userProfile} />
						))}

						<div className='d-flex justify-content-center align-items-center  my-2 mb-4'>
							{leaveStatusPending.isMore && (
								<button
									className='btn btn-primary btn-sm'
									onClick={() => {
										setLeaveStatusPendingPage(page => page + 1)
									}}>
									Show More
								</button>
							)}
						</div>
					</div>
				)
			case LEAVE_REJECT:
				return leaveStatusReject.isLoading ? null : leaveStatusReject.data.length === 0 ? (
					<EmptyState />
				) : (
					<div>
						{leaveStatusReject.data.map((status, index) => (
							<LeaveCard key={index} leaveStatus={status} userProfile={userProfile} />
						))}

						<div className='d-flex justify-content-center align-items-center  my-2 mb-4'>
							{leaveStatusReject.isMore && (
								<button
									className='btn btn-primary btn-sm'
									onClick={() => {
										setLeaveStatusRejectPage(page => page + 1)
									}}>
									Show More
								</button>
							)}
						</div>
					</div>
				)
		}
	}

	const onSubmit = async () => {
		if (!submittingClockOut) {
			setSubmittingClockOut(true)
			try {
				await API.checkOut(clockOutForm)
				closeModalBtn.current.click()
				getAttendanceStatus()
			} catch (error) {
				setIsError(true)
				setSubmittingClockOut(false)
				setTimeout(() => {
					setIsError(false)
				}, 3000)
			}
		}
	}

	useEffect(() => {
		if (is403) {
			history.push('/login')
		}
	}, [])

	if (attendanceStatus.isLoading || userProfile.isLoading) {
		return null
	}


	console.log('test:selectedModalDate', selectedModalDate)
	return (
		<Fragment>
			<Navbar />
			<div className='py-5 mx-md-5 mx-4 dudi-container'>
				<div className='row'>
					<div className='col-md-12 col-lg-8 pb-4 order-2 order-lg-1 order-md-2'>
						<div className='d-flex align-items-center justify-content-between'>
							<h2>Kalender</h2>
							<div className='form-group pl-3 pl-md-0'>
								<div className='input-group'>
									<div className='input-group-prepend'>
										<span className='input-group-text'>
											<i className='ni ni-zoom-split-in'></i>
										</span>
									</div>
									<input className='form-control' placeholder='Search' type='text' />
								</div>
							</div>
						</div>
						<Calendar
							onActiveStartDateChange={startDate =>
								setActiveCalendarDate(moment(startDate.activeStartDate).format('YYYY-MM-DD'))
							}
							tileClassName='calendar-tile'
							onClickDay={(date) => {
								history.push(`/absent/log/${moment(date).format('YYYY-MM-DD')}`)
							}}
							tileContent={({ activeStartDate, date, view }) => {
								return (
									<CalendarDate
										date={moment(date)}
										isLoading={calendarEmployeeActivities.isLoading}
										employeActivity={
											calendarEmployeeActivities.data
												? calendarEmployeeActivities.data[moment(date).format('YYYY-MM-DD')]
												: null
										}
										userProfile={userProfile}
										activeStartDate={activeStartDate}
									/>
								)
							}}
						/>

						<div className='mt-5 row'>
							<div className='col-12'>
								<div className='d-flex align-items-center justify-content-between'>
									<h2>Status Dinas</h2>

									<button
										data-toggle='modal'
										data-target='#modalExportDuty'
										className='btn btn-sm ml-auto d-flex align-items-center justify-content-center'
										style={{ background: '#172B4D', color: '#e5e5e5', width: '10rem', float: 'right' }}>
										<span className='d-none d-md-block'>Export log </span>
										<i className='ni ni-cloud-download-95'></i>
									</button>
								</div>

								{dutyList.isLoading
									? null
									: dutyList.data.map((duty, index) => <DutyCard userProfile={userProfile} key={index} duty={duty} />)}

								<div className='d-flex justify-content-center align-items-center  mt-2'>
									{dutyList.isMore && (
										<button
											className='btn btn-primary btn-sm mx-auto'
											onClick={() => {
												setDutyListPage(page => page + 1)
											}}>
											Show More
										</button>
									)}
								</div>
							</div>
						</div>
					</div>

					<div className='col-md-12 col-lg-3 order-1 order-md-1 order-lg-3 mb-4'>
						<h2>Log</h2>

						<LogCardHeader
							userProfile={userProfile}
							attendanceStatus={attendanceStatus}
							onClockIn={clockIn}
							submitting={submitting}
						/>

						<br />
						<button
							data-toggle='modal'
							data-target='#modalExportAttendance'
							className='btn btn-sm ml-auto d-flex align-items-center justify-content-center'
							style={{ background: '#172B4D', color: '#e5e5e5', width: '10rem', float: 'right' }}>
							<span className='d-none d-md-block'>Export log </span>
							<i className='ni ni-cloud-download-95'></i>
						</button>
						{attendanceList.isLoading ? null : (
							<div className='mt-4'>
								{attendanceList.data.map(attendance => (
									<div className='mt-4' key={attendance.ID}>
										<p
											className='font-weight-bold'
											style={{ cursor: 'pointer' }}
											data-toggle='modal'
											data-target='#modalAbsentDetail'
											onClick={() => {
												API.getDetailAttendance(attendance.ID).then(response => {
													absentContext.setModalContent({
														...response.data,
														selectedDate: moment(attendance.date, 'YYYY-MM-DD').format('DD MMM YYYY'),
													})
												})
											}}>
											{moment(attendance.date, 'YYYY-MM-DD').format('ddd')},{' '}
											<span className='font-weight-light' style={{ opacity: 0.6 }}>
												{moment(attendance.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
											</span>
										</p>
										{!attendance.employeeCheckIn ? null : (
											<LogCard
												timeStamp={attendance.employeeCheckIn}
												attendance={attendance}
												date={moment(attendance.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
												label='Absen Masuk'
											/>
										)}
										{!attendance.employeeCheckOut ? null : (
											<LogCard
												timeStamp={attendance.employeeCheckOut}
												attendance={attendance}
												date={moment(attendance.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
												label='Absen Keluar'
											/>
										)}
									</div>
								))}

								<div className='d-flex justify-content-center align-items-center  mt-2'>
									{attendanceList.isMore && (
										<button
											className='btn btn-primary btn-sm mx-auto'
											onClick={() => {
												setAttendanceListPage(page => page + 1)
											}}>
											Show More
										</button>
									)}
								</div>
							</div>
						)}
					</div>
				</div>

				<div
					className='modal fade'
					id='clockOutModal'
					tabIndex={-1}
					role='dialog'
					aria-labelledby='clockOutModalLabel'
					aria-hidden='true'>
					<div className='modal-dialog' role='document'>
						<div className='modal-content'>
							<div className='modal-header'>
								<h5 className='modal-title' id='clockOutModalLabel'>
									Silahkan Isi Kegiatan Anda
								</h5>
								<button type='button' className='close' data-dismiss='modal' aria-label='Close' ref={closeModalBtn}>
									<span aria-hidden='true'>×</span>
								</button>
							</div>
							<div className='modal-body'>
								<form>
									<div className='row'>
										{clockOutForm.map((form, index) => (
											<Fragment key={index}>
												{form.type ? (
													<div className='col-12'>
														<div className='form-group'>
															<label htmlFor={`mroning-activities-form${form.type}`}>
																Kegiatan {form.type === 1 ? 'Pagi' : form.type === 2 ? 'Siang' : 'Sore'} :{' '}
															</label>
															<textarea
																className='form-control'
																id={`mroning-activities-form${form.type}`}
																rows='3'
																onChange={event => {
																	let tmp = [...clockOutForm]
																	tmp[index].activity = event.target.value
																	setCloclOutForm(tmp)
																}}
																value={form.activity}
																placeholder={`Masukkan Kegiatan ${form.type === 1 ? 'Pagi' : form.type === 2 ? 'Siang' : 'Sore'
																	} Anda`}></textarea>
														</div>
													</div>
												) : (
													<div className='col-12' key={form.type}>
														<div className='form-group'>
															<label htmlFor='mroning-activities-form-info'>Bagaimana Keadaan Anda ? : </label>
															<input
																className='form-control'
																id='mroning-activities-form-info'
																rows='3'
																onChange={event => {
																	let tmp = [...clockOutForm]
																	tmp[index].info = event.target.value
																	setCloclOutForm(tmp)
																}}
																value={form.info}
																placeholder={`Masukkan Informasi Tambahan Anda`}></input>
														</div>
													</div>
												)}
											</Fragment>
										))}
									</div>
								</form>
								{isError && (
									<div className='alert alert-danger' role='alert'>
										Harap Mengisi Semua Form
									</div>
								)}
							</div>
							<div className='modal-footer'>
								<button type='button' className='btn btn-secondary' data-dismiss='modal'>
									Batal
								</button>
								<button type='button' className='btn btn-primary' onClick={onSubmit} disabled={submittingClockOut}>
									Absen Keluar
								</button>
							</div>
						</div>
					</div>
				</div>

				<ModalLeaveDetail />
				<ModalDuty />
				<ModalExportAttendance />
				<ModalExportDuty />
				<ModalLeaveExport />
			</div>
		</Fragment>
	)
}

const EmptyState = () => (
	<div className='text-center'>
		<img
			src={require('../../assets/images/undraw_empty_xct9.png')}
			style={{ objectFit: 'cover', width: '100%' }}
			alt='Loading'
		/>

		<h5>Belum ada cuti</h5>
	</div>
)

export default Container
