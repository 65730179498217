import axios from '../utils/axios'
import queryString from 'query-string'
import moment from 'moment'

const Axios = axios.defaultAxios

export default class API {
	static getDetailEmployeeAttendance(date) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await axios.latestAxios.get(`/human-resource/attendance/attendance/${date}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static createAttendance(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					formData.append(data, body[data])
				})
				const response = await axios.latestAxios.post('/human-resource/attendance/attendance', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static loginUser(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					formData.append(data, body[data])
				})
				const response = await Axios.post('/access/login', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateUser(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					formData.append(data, body[data])
				})
				const response = await Axios.post('/employee/update', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getUser() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.post('/access/account')

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getAttendanceList(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/employee-attendance?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getDetailAttendance(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/employee-attendance/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getDetailLeave(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/leave/employee-leave/${id}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getEmployeeProfile(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getEmployeeCalendarActivities(date, params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const dateStart = `${moment(date, 'YYYY-MM-DD').format('YYYY-MM-01')}`
				const dateEnd = `${moment(date, 'YYYY-MM-DD').endOf('month').format('YYYY-MM-DD')}`
				const response = await Axios.get(`employee/activities/${dateStart}/${dateEnd}?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getEmployeeHome() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/employee/info`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static checkIn() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.post('/employee/employee-attendance/check-in')

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static checkOut(body = []) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				body.forEach((body, index) => {
					if (body.type) {
						formData.append(`activities[${index}][type]`, body.type)
						formData.append(`activities[${index}][activity]`, body.activity)
					} else {
						formData.append(`info`, body.info)
					}
				})

				const response = await Axios.post('/employee/employee-attendance/check-out', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static attendanceStatus(date) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get('/employee/employee-attendance/status/' + date)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static createEmployeeLeave(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('type', body.type)
				body.selectedDate.forEach(date => {
					formData.append('dates[]', date)
				})

				const response = await Axios.post(`/leave/employee-leave`, formData)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getLeaveStatus(parameters = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/leave/employee-leave?${queryString.stringify(parameters)}`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getCity() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/city`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getEmployeePosition() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/employee-position`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getBank() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/bank`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getReligion() {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/religion`)
				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeBPJS(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/bpjs/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeBPJS(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/bpjs`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmailDikbud(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/email-mendikbud`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
	static updateEmailDikbud(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/email-mendikbud/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeBlood(type) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				formData.append('type', type)

				const response = await Axios.post(`/employee/employee-blood-type`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateEmployeeBlood(type) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				formData.append('type', type)

				const response = await Axios.post(`/employee/employee-blood-type/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static updateEmployeeBank(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-bank/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeBank(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-bank`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeFamily(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-family`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeFamily(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-family/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getEmployeeCategory(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/civilian-category?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addCivilianCategory(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-civilian-category`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeCivilianCategory(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-civilian-category/update/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeCivilianCategory(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-civilian-category/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getEducationTier(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/data-master/education-tier?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeEducation(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()
				Object.keys(body).forEach(obj => {
					if (body[obj]) {
						formData.append(obj, body[obj])
					}
				})
				const response = await Axios.post(`/employee/employee-education`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeEducation(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					if (body[obj]) {
						formData.append(obj, body[obj])
					}
				})
				const response = await Axios.post(`/employee/employee-education/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeEducation(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-education/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeChildren(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-child`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeChildren(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-child/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeChild(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-child/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeSpouse(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('employeeID', body.employeeID)
				formData.append('name', body.name)
				formData.append('isCivilian', body.isCivilian)
				if (body.isCivilian === 1) {
					formData.append('NIP', body.NIP)
				}

				const response = await Axios.post(`/employee/employee-spouse`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeSpouse(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('employeeID', body.employeeID)
				formData.append('name', body.name)
				formData.append('isCivilian', body.isCivilian)
				if (body.isCivilian === 1) {
					formData.append('NIP', body.NIP)
				} else {
					formData.append('NIP', null)
				}
				const response = await Axios.post(`/employee/employee-spouse/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeSpouse(employeeSpouseID) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-spouse/${employeeSpouseID}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeFather(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/father/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeFather(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/father`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeMother(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/mother/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeMother(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/mother`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static forgetPassword(email) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				formData.append('email', email)

				const response = await Axios.post(`/access/forget/password`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static resetPassword(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/access/reset/password`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static changePassword(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/access/change/password`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeCourse(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-course`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeCourse(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-course/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeCourse(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-course/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static addEmployeeTraining(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-training`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static deleteEmployeeTraining(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.delete(`/employee/employee-training/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeTraining(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				let formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})
				const response = await Axios.post(`/employee/employee-training/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateEmployeeAvatar(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/employee/avatar/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}

	static addEmployeeAvatar(body = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(obj => {
					formData.append(obj, body[obj])
				})

				const response = await Axios.post(`/employee/avatar`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error.response.data)
			}
		})
	}
}
