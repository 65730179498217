import React, { Fragment, useContext, useState, useEffect } from 'react'
import Navbar from '../../Components/Navbar'
import { AuthContext } from '../../Context/AuthContext'
import Quill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import AspirationAPI from '../../services/AspirationAPI'
import { useAlert } from 'react-alert'

const formats = [
	'background',
	'bold',
	'color',
	'font',
	'code',
	'italic',
	'link',
	'size',
	'strike',
	'script',
	'underline',
	'blockquote',
	'header',
	'indent',
	'list',
	'align',
	'direction',
	'code-block',
	'formula',
	// 'image'
	// 'video'
]

const Create = ({ history }) => {
	const alert = useAlert()
	const [submitting, setSubmitting] = useState(false)
	const [errors, setErrors] = useState(null)
	const [form, setForm] = useState({
		aspirationCategoryID: '',
		subject: '',
		description: '',
	})
	const [files, setFiles] = useState([
		{
			file: null,
		},
	])

	const onCreate = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await AspirationAPI.addAspiration(
					form,
					files.map(file => file.file)
				)
				alert.success('Berhasil buat aspirasi')
				history.push('/aspiration')
				setSubmitting(false)
			} catch (error) {
				console.log(error.response.data)
				alert.error('Gagal buat aspirasi')

				if (error?.response.data) {
					setErrors(error.response.data.errors)
				}
				setSubmitting(false)
			}
		}
	}

	useEffect(() => {
		if (errors) {
			setTimeout(() => {
				setErrors(null)
			}, 5000)
		}
	}, [errors])

	return (
		<Fragment>
			<Navbar />
			<div className='container py-5'>
				<div className='mx-auto'>
					<h2 className='font-weight-bold'>Buat aspirasi</h2>

					<hr />

					<form onSubmit={onCreate} className='d-flex justify-content-center'>
						<div className='row' style={{ width: '100%' }}>
							<div className='col-12'>
								<div className='form-group'>
									<label className='font-weight-bold' htmlFor='aspirationCategory'>
										<span style={{ color: 'red' }}>*</span> Kategori
									</label>
									<select
										className='custom-select'
										id='aspirationCategory'
										value={form.aspirationCategoryID}
										onChange={e => setForm({ ...form, aspirationCategoryID: e.target.value })}>
										<option selected>Choose...</option>
										<option value={1}>Pelayanan</option>
										<option value={2}>Pekerjaan</option>
										<option value={3}>Fasilitas</option>
										<option value={4}>Kegiatan</option>
									</select>
									{errors && errors.aspirationCategoryID && (
										<small style={{ color: 'red' }}>{errors.aspirationCategoryID[0]}</small>
									)}
								</div>
							</div>
							<div className='col-12'>
								<div className='form-group'>
									<label className='font-weight-bold' htmlFor='aspirationSubject'>
										<span style={{ color: 'red' }}>*</span> Subjek
									</label>
									<input
										type='text'
										className='form-control'
										id='aspirationSubject'
										placeholder='Penambahan modul ...'
										value={form.subject}
										onChange={e => setForm({ ...form, subject: e.target.value })}
									/>
									{errors && errors.subject && <small style={{ color: 'red' }}>{errors.subject[0]}</small>}
								</div>
							</div>

							<div className='col-12'>
								<label className='font-weight-bold' htmlFor='aspirationDescription'>
									<span style={{ color: 'red' }}>*</span> Deskripsi
								</label>
								<div className='form-group'>
									<Quill
										formats={formats}
										id='aspirationDescription'
										onChange={value => setForm({ ...form, description: value })}
									/>
								</div>
								{errors && errors.description && <small style={{ color: 'red' }}>{errors.description[0]}</small>}
							</div>

							<div className='col-12'>
								<div className='form-group'>
									<label className='font-weight-bold' htmlFor='aspirationSubject'>
										<span style={{ color: 'red' }}>*</span> Berkas
									</label>
									{files.map((file, index) => (
										<div className={`d-flex align-items-center ${index > 0 ? 'mt-3' : ''}`} key={index}>
											<input
												type='file'
												className='form-control mr-3'
												id='aspirationSubject'
												placeholder='Penambahan modul ...'
												onChange={e => {
													let temp = [...files]
													temp[index].file = e.target.files[0]
													setFiles(temp)
												}}
											/>
											<button
												className='btn btn-primary'
												style={{ width: '20%' }}
												onClick={e => {
													e.preventDefault()
													setFiles([...files, { file: null }])
												}}>
												Tambah berkas
											</button>
										</div>
									))}

									{/* {errors && errors.subject && <small style={{ color: 'red' }}>{errors.subject[0]}</small>} */}
								</div>
							</div>

							{errors && errors.errors && (
								<div className='col-12 '>
									<div className='bg-warning text-white rounded p-2 px-4 d-flex justify-content-between align-items-center'>
										<span>{errors.errors[0]}</span>
										<span onClick={() => setErrors(null)} style={{ cursor: 'pointer' }}>
											X
										</span>
									</div>
								</div>
							)}

							<div className='col-6 ml-auto'>
								<div className='form-group'>
									<button disabled={submitting} type='submit' className='btn btn-primary btn-block mt-2'>
										Kirim Aspirasi
									</button>
								</div>
							</div>
						</div>
					</form>
				</div>

				<br />
				<br />
				<br />
				<br />
				<br />
			</div>
		</Fragment>
	)
}

export default Create
