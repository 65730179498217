import React, { Fragment, useContext, useState, useRef, useEffect } from 'react'
import Navbar from '../../Components/Navbar'
import { useAlert } from 'react-alert'

import moment from 'moment'
import 'moment/locale/id'
import API from '../../services/API'
import { Link, useHistory } from 'react-router-dom'

const Container = ({ match }) => {
	const history = useHistory()
	const alert = useAlert()
	const date = match.params.date
	const [morningActivity, setMorningActivity] = useState('')
	const [noonActivity, setNoonActivity] = useState('')
	const [afterNoonActivity, setAfterNoonActivity] = useState('')

	useEffect(() => {
		refetch()
	}, [])


	const refetch = () => {
		API.getDetailEmployeeAttendance(date)
			.then(response => {
				const data = response.data
				setMorningActivity(data.activities.find(activity => activity.type === 1)?.activity)
				setNoonActivity(data.activities.find(activity => activity.type === 2)?.activity)
				setAfterNoonActivity(data.activities.find(activity => activity.type === 3)?.activity)
			})
			.catch(error => {

			})
	}

	const submit = () => {
		API.createAttendance({
			date,
			'activity[1][description]': morningActivity,
			'activity[2][description]': noonActivity,
			'activity[3][description]': afterNoonActivity,
		}).then(response => {
			refetch()
		})
	}

	return (
		<Fragment>
			<Navbar />
			<div className='py-5 mx-md-5 mx-4 dudi-container'>
				<div className='row'>
					<div className='col-md-12 col-lg-8 pb-4 order-2 order-lg-1 order-md-2'>
						<Link to="/absent">← Kembali</Link>
						<div className='d-flex align-items-center justify-content-between'>
							<h2>Log Tanggal 25 Desember 1990</h2>
						</div>
					</div>
				</div>


				<div className='px-4 mt-2 modal-body'>
					<div className='row'>
						<div className='col-12'>
							<div className='form-group'>
								<label htmlFor='dutyStartDate'>Tanggal Awal</label>
								<input
									type='date'
									className='form-control'
									value={date}
									disabled
								/>
							</div>
						</div>
						<div className='col-12'>
							<div className='form-group'>
								<label htmlFor='dutyStartDate'>Kegiatan Pagi</label>
								<textarea
									className='form-control'
									rows='3'
									onChange={event => {
										setMorningActivity(event.target.value)
									}}
									defaultValue={morningActivity}
									placeholder={`Masukkan Kegiatan Pagi Anda`}></textarea>
							</div>
						</div>
						<div className='col-12'>
							<div className='form-group'>
								<label htmlFor='dutyStartDate'>Kegiatan Siang</label>
								<textarea
									className='form-control'
									rows='3'
									onChange={event => {
										setNoonActivity(event.target.value)
									}}
									defaultValue={noonActivity}
									placeholder={`Masukkan Kegiatan Siang Anda`}></textarea>
							</div>
						</div>
						<div className='col-12'>
							<div className='form-group'>
								<label htmlFor='dutyStartDate'>Kegiatan Sore</label>
								<textarea
									className='form-control'
									rows='3'
									onChange={event => {
										setAfterNoonActivity(event.target.value)
									}}
									defaultValue={afterNoonActivity}
									placeholder={`Masukkan Kegiatan Sore Anda`}></textarea>
							</div>
						</div>
					</div>
				</div>

				<div className='modal-footer'>
					<button type='button' onClick={() => history.goBack()} className='btn btn-secondary' data-dismiss='modal'>
						Batal
					</button>
					<button onClick={submit} type='button' className='btn btn-primary' >
						Simpan
					</button>
				</div>

			</div>
		</Fragment>
	)
}

const EmptyState = () => (
	<div className='text-center'>
		<img
			src={require('../../assets/images/undraw_empty_xct9.png')}
			style={{ objectFit: 'cover', width: '100%' }}
			alt='Loading'
		/>

		<h5>Belum ada cuti</h5>
	</div>
)

export default Container
