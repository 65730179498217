import React from 'react'
import gravatar from 'gravatar'
import moment from 'moment'

const DutyCard = ({ duty, userProfile }) => {
	return (
		<div className='p-3 mb-3 activity-card shadow-sm activity-card_success'>
			<div className='d-flex align-items-center' style={{ gap: '1rem' }}>
				<img
					className='rounded-circle'
					style={{ width: 32, height: 32 }}
					src={
						userProfile.data.employeeAccess.employee.employeeAvatar
							? userProfile.data.employeeAccess.employee.employeeAvatar.realPath
							: gravatar.url(userProfile.data.employeeAccess.employee.email, { d: 'identicon', s: '200' })
					}
					alt='user profile'
				/>

				<div className='d-flex justify-content-between' style={{ width: '100%' }}>
					<div style={{ position: 'relative' }}>
						<p
							className='font-weight-bold activity-card-title'
							style={{ marginBottom: 0, flex: 1, fontSize: '0.9rem', paddingBottom: 0 }}>
							{duty.number}
						</p>
						<p style={{ fontWeight: 'normal', fontSize: '0.7rem', margin: 0 }}>
							{moment(duty.created, 'YYYY-MM-DD HH:mm:ss').fromNow()}
						</p>

						<div className='duty-card-description'>
							<p style={{ marginBottom: 0, flex: 1, fontSize: '0.8rem' }}>{duty.description}</p>
						</div>
					</div>

					<span style={{ cursor: 'pointer' }}>
						<i className='fas fa-ellipsis-h'></i>
					</span>
				</div>
			</div>

			<div style={{ paddingLeft: 1 }} className='d-flex align-items-center justify-content-between mt-2'>
				<p
					style={{
						fontSize: '0.7rem',
						marginBottom: 0,
						color: '#1890ff',
						fontWeight: 'bold',
					}}>
					<span>
						<i className='far fa-calendar'></i>
					</span>{' '}
					{moment(duty.created, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY')}
				</p>

				<div className='d-flex'>
					
				</div>
			</div>
		</div>
	)
}

export default DutyCard
