import React, { Fragment, useContext, useState, useRef } from 'react'
import Navbar from '../../Components/Navbar'
import { AuthContext } from '../../Context/AuthContext'
import API from '../../services/API'
import { useAlert } from 'react-alert'
import BPJSForm from './Components/BPJSForm'
import EmailForm from './Components/EmailForm'
import BloodForm from './Components/BloodForm'
import CareerForm from './Components/CareerForm'
import BankForm from './Components/BankForm'
import FamilyForm from './Components/FamilyForm'
import EducationForm from './Components/EducationForm'
import ChildrenForm from './Components/ChildrenForm'
import SpousesForm from './Components/SpousesForm'
import MotherForm from './Components/MotherForm'
import FatherForm from './Components/FatherForm'
import CourseForm from './Components/CourseForm'
import TrainingForm from './Components/TrainingForm'

export default () => {
	const _modalRefResetUser = useRef(null)
	const alert = useAlert()
	const authContext = useContext(AuthContext)
	const {
		cities,
		employeePosition,
		religion,
		employeeProfileEdit,
		setEmployeeProfileEdit,
		getUserProfile,
	} = authContext

	const [submitting, setSubmitting] = useState(false)
	const [changePassword, setChangePassword] = useState({ lastPassword: '', newPassword: '', confirmedPassword: '' })

	const onChangeEmployeeProfileEdit = e => {
		setEmployeeProfileEdit({
			...employeeProfileEdit,
			data: { ...employeeProfileEdit.data, [e.target.name]: e.target.value },
		})
	}

	const onUpdateProfile = async event => {
		event.preventDefault()
		if (!submitting) {
			try {
				setSubmitting(true)
				await API.updateUser(employeeProfileEdit.data)
				alert.show('Sukses Update Profil', { type: 'success' })
				getUserProfile()
				setSubmitting(false)
			} catch (error) {
				alert.show('Gagal Update Profil', { type: 'error' })
				setSubmitting(false)
			}
		}
	}

	const onChangePassword = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.changePassword(changePassword)
				_modalRefResetUser.current.click()
				alert.show('Sukses mengubah password anda!', { type: 'success' })
				setSubmitting(false)
			} catch (error) {
				console.log(error)
				alert.show('Gagal mengubah password Anda', { type: 'error' })
				setSubmitting(false)
			}
		}
	}

	return (
		<Fragment>
			<Navbar />
			<div className='container py-5'>
				<h2>Edit Profil</h2>
				<hr />
				{employeeProfileEdit.isLoading ? null : (
					<Fragment>
						<form onSubmit={onUpdateProfile}>
							<h4>Informasi Dasar</h4>
							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor='employeeNameForm'>
											<span style={{ color: 'red' }}>*</span> Nama Lengkap
										</label>
										<input
											type='text'
											placeholder='Nama'
											className='form-control'
											id='employeeNameForm'
											name='name'
											value={employeeProfileEdit.data.name}
											onChange={onChangeEmployeeProfileEdit}
										/>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='employeeBornPlace'>
											<span style={{ color: 'red' }}>*</span> Tempat Lahir
										</label>
										<select
											className='custom-select'
											id='employeeBornPlace'
											name='bornPlace'
											value={employeeProfileEdit.data.bornPlace}
											onChange={onChangeEmployeeProfileEdit}>
											<option selected>Choose...</option>
											{cities.data.map(city => (
												<option value={city.ID} key={city.ID}>
													{city.name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='employeeBornDate'>
											<span style={{ color: 'red' }}>*</span> Tanggal Lahir
										</label>
										<input
											name='bornDate'
											id='employeeBornDate'
											type='date'
											placeholder='Tanggal Lahir'
											className='form-control'
											value={employeeProfileEdit.data.bornDate}
											onChange={onChangeEmployeeProfileEdit}
										/>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='employeePhone'>
											<span style={{ color: 'red' }}>*</span> Nomor Telepon
										</label>
										<input
											type='text'
											placeholder='Nomor Telepon'
											className='form-control'
											id='employeePhone'
											name='phoneNumber'
											value={employeeProfileEdit.data.phoneNumber}
											onChange={onChangeEmployeeProfileEdit}
										/>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='employeeEmail'>
											<span style={{ color: 'red' }}>*</span> Email
										</label>
										<input
											type='email'
											placeholder='Email'
											className='form-control'
											id='employeeEmail'
											name='email'
											value={employeeProfileEdit.data.email}
											onChange={onChangeEmployeeProfileEdit}
										/>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='employeeCity'>
											<span style={{ color: 'red' }}>*</span> Kota
										</label>
										<select
											className='custom-select'
											id='employeeCity'
											name='cityID'
											value={employeeProfileEdit.data.cityID}
											onChange={onChangeEmployeeProfileEdit}>
											<option selected>Choose...</option>
											{cities.data.map(city => (
												<option value={city.ID} key={city.ID}>
													{city.name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='employeePosition'>
											<span style={{ color: 'red' }}>*</span> Posisi Karyawan
										</label>
										<select
											className='custom-select'
											id='employeePosition'
											name='employeePositionID'
											value={employeeProfileEdit.data.employeePositionID}
											onChange={onChangeEmployeeProfileEdit}>
											<option selected>Choose...</option>
											{employeePosition.data.map(position => (
												<option value={position.ID} key={position.ID}>
													{position.name}
												</option>
											))}
										</select>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor='employeeAddress'>
											<span style={{ color: 'red' }}>*</span> Alamat
										</label>
										<textarea
											placeholder='Alamat'
											className='form-control'
											id='employeeAddress'
											name='address'
											value={employeeProfileEdit.data.address}
											onChange={onChangeEmployeeProfileEdit}
										/>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor='NIK'>
											<span style={{ color: 'red' }}>*</span> NIK
										</label>
										<input
											type='text'
											placeholder='NIK'
											className='form-control'
											id='NIK'
											name='NIK'
											value={employeeProfileEdit.data.NIK}
											onChange={onChangeEmployeeProfileEdit}
										/>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor='NPWP'>
											<span style={{ color: 'red' }}>*</span> NPWP
										</label>
										<input
											type='text'
											placeholder='NPWP'
											className='form-control'
											id='NPWP'
											name='NPWP'
											value={employeeProfileEdit.data.NPWP}
											onChange={onChangeEmployeeProfileEdit}
										/>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='employeeReligion'>
											<span style={{ color: 'red' }}>*</span> Agama
										</label>
										<select
											className='custom-select'
											id='employeeReligion'
											name='religionID'
											value={employeeProfileEdit.data.religionID}
											onChange={onChangeEmployeeProfileEdit}>
											<option selected>Choose...</option>
											{religion.data.map(_religion => (
												<option value={_religion.ID} key={_religion.ID}>
													{_religion.name}
												</option>
											))}
										</select>
									</div>
								</div>
								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='employeeGender'>
											<span style={{ color: 'red' }}>*</span> Jenis Kelamin
										</label>
										<select
											className='custom-select'
											id='employeeGender'
											name='gender'
											value={employeeProfileEdit.data.gender}
											onChange={onChangeEmployeeProfileEdit}>
											<option selected>Choose...</option>
											<option value='0'>Laki - Laki</option>
											<option value='1'>Perempuan</option>
										</select>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-md-6'></div>
								<div className='col-md-6'>
									<button type='submit' className='btn btn-primary btn-block mt-2' disabled={submitting}>
										{submitting ? 'Loading...' : 'Edit Informasi Dasar'}
									</button>
								</div>
							</div>
						</form>

						<br />
						<hr />

						<BPJSForm />
						<hr />
						<EmailForm />
						<hr />
						<BloodForm />
						<hr />
						<CareerForm />
						<hr />
						<EducationForm />
						<hr />
						<CourseForm />
						<hr />
						<TrainingForm />
						<hr />
						<BankForm />
						<hr />
						<FamilyForm />
						<hr />
						<ChildrenForm />
						<hr />
						<SpousesForm />
						<hr />
						<MotherForm />
						<hr />
						<FatherForm />

						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<button
									type='submit'
									className='btn btn-secondary btn-block mt-2'
									data-toggle='modal'
									data-target='#modal-reset-password-user'>
									Ubah Password
								</button>
							</div>
						</div>

						<div className='modal' tabIndex={-1} role='dialog' id='modal-reset-password-user'>
							<div className='modal-dialog' role='document'>
								<div className='modal-content'>
									<div className='modal-header'>
										<h5 className='modal-title'>Reset Password</h5>
										<button
											type='button'
											className='close'
											data-dismiss='modal'
											aria-label='Close'
											ref={_modalRefResetUser}>
											<span aria-hidden='true'>×</span>
										</button>
									</div>
									<div className='modal-body'>
										<div className='row'>
											<div className='col-12'>
												<div className='form-group'>
													<label htmlFor='resetEmail'>Password Lama</label>
													<input
														type='password'
														className='form-control'
														id='resetEmail'
														placeholder='Password Lama'
														value={changePassword.lastPassword}
														onChange={event =>
															setChangePassword({ ...changePassword, lastPassword: event.target.value })
														}
													/>
												</div>
											</div>
											<div className='col-12'>
												<div className='form-group'>
													<label htmlFor='resetEmail'>Masukkan Password Anda</label>
													<input
														type='password'
														className='form-control'
														id='resetEmail'
														placeholder='Password'
														value={changePassword.newPassword}
														onChange={event =>
															setChangePassword({ ...changePassword, newPassword: event.target.value })
														}
													/>
												</div>
											</div>
											<div className='col-12'>
												<div className='form-group'>
													<label htmlFor='resetEmail'>Konfirmasi Password Anda</label>
													<input
														type='password'
														className='form-control'
														id='resetEmail'
														placeholder='Konfirmasi Password Anda'
														value={changePassword.confirmedPassword}
														onChange={event =>
															setChangePassword({ ...changePassword, confirmedPassword: event.target.value })
														}
													/>
												</div>
											</div>
										</div>
									</div>
									<div className='modal-footer'>
										<button
											type='button'
											className='btn btn-primary'
											disabled={submitting}
											onClick={e => onChangePassword(e)}>
											Ubah Password
										</button>
										<button type='button' className='btn btn-secondary' data-dismiss='modal'>
											Tutup
										</button>
									</div>
								</div>
							</div>
						</div>

						<br />
					</Fragment>
				)}
			</div>
		</Fragment>
	)
}
