import axios from '../utils/axios'
import queryString from 'query-string'
import moment from 'moment'

const Axios = axios.defaultAxios

export default class AspirationAPI {
	static addAspiration(body = {}, files = []) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})

				files.forEach(file => {
					if (file) {
						formData.append('files[]', file)
					}
				})

				const response = await Axios.post('/aspiration', formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static updateAspiration(body = {}, files = [], removes = [], id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})

				files.forEach(file => {
					if (file) {
						formData.append('files[]', file)
					}
				})

				removes.forEach(file => {
					if (file) {
						formData.append('removes[]', file)
					}
				})

				const response = await Axios.post(`/aspiration/${id}/update`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getAspirations(params = {}) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/aspiration?${queryString.stringify(params)}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getThread(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/aspiration/thread/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static getAspirationDetail(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const response = await Axios.get(`/aspiration/${id}`)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}

	static replyAspiration(body = {}, id) {
		return new Promise(async (resolve, reject) => {
			try {
				const formData = new FormData()

				Object.keys(body).forEach(data => {
					if (body[data]) {
						formData.append(data, body[data])
					}
				})
				const response = await Axios.post(`/aspiration/thread/${id}`, formData)

				resolve(response.data)
			} catch (error) {
				reject(error)
			}
		})
	}
}
