import React, { Fragment, useContext, useState, useEffect } from 'react'
import Navbar from '../../Components/Navbar'
import { AuthContext } from '../../Context/AuthContext'
import Quill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import AspirationAPI from '../../services/AspirationAPI'
import moment from 'moment'
import gravatar from 'gravatar'
import { useAlert } from 'react-alert'
import { Link } from 'react-router-dom'

const Detail = ({ match, history }) => {
	const alert = useAlert()
	const [aspiration, setAspiration] = useState({ data: null, isLoading: true })
	const [threads, setThreads] = useState({ data: [], isLoading: true })
	const [submiting, setSubmitting] = useState(false)
	const [message, setMessage] = useState('')

	const fetchAspirationDetail = async () => {
		try {
			const response = await AspirationAPI.getAspirationDetail(match.params.id)
			setAspiration({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	const fetchThreadsDetail = async () => {
		try {
			const response = await AspirationAPI.getThread(match.params.id)
			setThreads({
				data: response.data.list,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchAspirationDetail()
		fetchThreadsDetail()
	}, [])

	return (
		<Fragment>
			<Navbar />
			<div className='container py-5'>
				<div className='mx-auto mt-4'>
					{aspiration.isLoading ? (
						<div className='text-center'>
							<img
								src={require('../../assets/images/undraw_fast_loading_0lbh.png')}
								style={{ objectFit: 'cover', height: '14rem' }}
								alt='Loading'
							/>

							<h2>Loading ...</h2>
						</div>
					) : (
						<div className='aspiration-list-wrapper mx-auto'>
							{/* {===TS===} */}

							<div className='d-flex flex-column'>
								<small style={{ cursor: 'pointer' }} onClick={() => history.goBack()}>
									{'< Kembali'}
								</small>
								<span className=' mt-3'>Aspirasi</span>
								<Link to={`/edit-aspiration/${match.params.id}`}>
									<button
										type='submit'
										className='btn btn-sm my-3 ml-auto'
										style={{ background: '#172B4D', color: '#e5e5e5', width: '10rem' }}>
										Edit
									</button>
								</Link>

								<h2 className='font-weight-bold'>{aspiration.data.subject}</h2>
								<span style={{ fontSize: '0.8rem' }}>
									<i className='ni ni-calendar-grid-58'></i>{' '}
									{moment(aspiration.data.created, 'YYYY-MM-DD').format('DD MMM YYYY')} <strong>&#903;</strong>{' '}
									{/* <i className='ni ni-chat-round'></i> 5 balasan &nbsp; <strong>&#903;</strong> &nbsp;  */}
									{aspiration.data.aspirationReject
										? 'Ditolak'
										: aspiration.data.aspirationConfirmed === null
										? 'Menunggu konfirmasi'
										: 'Terkonfirmasi'}
								</span>
								<br />
								<div dangerouslySetInnerHTML={{ __html: aspiration.data.description }} />
								{aspiration.data.aspirationFiles.length > 0 && (
									<div className='mt-2'>
										<span>Berkas / Lampiran :</span>

										<ul>
											{aspiration.data.aspirationFiles.map(file => (
												<li key={file.ID} style={{ fontSize: '0.8rem' }}>
													<a href={file.fileURL} target='_blank' rel='noopener noreferrer'>
														{file.file.split('/')[file.file.split('/').length - 1]}
													</a>
												</li>
											))}
										</ul>
									</div>
								)}

								<div className='d-flex align-items-center justify-content-end'>
									<img
										src={gravatar.url(aspiration.data.employee.email, { d: 'identicon', s: '200' })}
										className='rounded-circle'
										alt='Avatar'
										style={{
											width: '2rem',
											height: '2rem',
											objectFit: 'cover',
											border: '2px solid #172B4D',
										}}
									/>
									<div className='ml-3'>
										<span style={{ fontSize: '0.8rem' }}>{aspiration.data.employee.name}</span>
									</div>
								</div>
							</div>
							<hr />
							{/* {===CHAT===} */}

							<div className='d-flex flex-column chat-wrapper'>
								{threads.isLoading
									? null
									: threads.data.map(thread => (
											<div className={'d-flex ' + (thread.isAdmin === 0 ? 'ml-auto' : '')} key={thread.ID}>
												<div className='aspiration-chat'>
													<div className='d-flex'>
														<img
															src={
																thread.isAdmin === 0
																	? gravatar.url(thread.account.email, {
																			d: 'identicon',
																			s: '200',
																	  })
																	: gravatar.url('mitrasdudi@gmail.com', { d: 'identicon', s: '200' })
															}
															className='rounded-circle'
															alt='Avatar'
															style={{
																width: '1.8rem',
																height: '1.8rem',
																objectFit: 'cover',
																border: '2px solid #172B4D',
															}}
														/>
														<div className='ml-2 '>
															<div>
																<span style={{ fontSize: '0.9rem', fontWeight: '500' }}>
																	{thread.isAdmin === 0 ? thread.account.employeeAccess?.employee.name : 'Admin'}
																</span>
																<span style={{ fontSize: '0.7rem' }} className='ml-2'>
																	&nbsp; <strong>&#903;</strong> &nbsp; {moment(thread.created).format('DD MMM YYYY')}
																</span>
															</div>

															<div
																className='mt-2 thread-wrapper'
																dangerouslySetInnerHTML={{ __html: thread.message }}></div>
														</div>
													</div>
												</div>
											</div>
									  ))}
							</div>

							{aspiration.data.aspirationReject ? null : aspiration.data.aspirationConfirmed === null ? (
								<div>
									<div className='discuss-input-wrapper'>
										<div>
											<h5>Tulis balasan anda</h5>
											<Quill onChange={value => setMessage(value)} />

											<div className='float-right mt-3'>
												<button
													disabled={submiting}
													className='btn btn-success clock-in-btn text-sm'
													onClick={e => {
														e.preventDefault()
														if (!submiting) {
															setSubmitting(true)
															AspirationAPI.replyAspiration({ message }, match.params.id)
																.then(() => {
																	fetchAspirationDetail()
																	fetchThreadsDetail()
																	setSubmitting(false)
																})
																.catch(() => {
																	alert.error('Gagal balas')
																	setSubmitting(false)
																})
														}
													}}>
													Kirim
												</button>
											</div>
										</div>
									</div>
								</div>
							) : null}
						</div>
					)}
				</div>
			</div>
		</Fragment>
	)
}

export default Detail
