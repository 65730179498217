import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../Components/Navbar'
import { AuthContext } from '../../Context/AuthContext'
import AspirationAPI from '../../services/AspirationAPI'
import AspirationCard from './Components/AspirationCard'

const Aspiration = () => {
	const [aspirations, setAspirations] = useState({ data: [], isLoading: true })

	const fetchAspirations = async () => {
		try {
			const response = await AspirationAPI.getAspirations({ sort: 'responded', order: 'desc' })
			setAspirations({ isLoading: false, data: response.data.list })
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		fetchAspirations()
	}, [])

	return (
		<Fragment>
			<Navbar />
			<div className='container py-5'>
				<div className='mx-auto'>
					<div className='aspiration-list-wrapper mx-auto'>
						<div className='d-flex flex-column flex-md-row justify-content-between align-items-start align-items-md-center'>
							<h2 className='font-weight-bold'>Daftar aspirasi</h2>
							<div>
								<Link to='/create-aspiration'>
									<button className='btn btn-success btn-sm clock-in-btn text-sm'>Tambah aspirasi</button>
								</Link>
							</div>
						</div>

						<br />

						{aspirations.isLoading ? (
							<div className='text-center'>
								<img
									src={require('../../assets/images/undraw_fast_loading_0lbh.png')}
									style={{ objectFit: 'cover', height: '14rem' }}
									alt='Loading'
								/>

								<h2>Loading ...</h2>
							</div>
						) : !aspirations.isLoading && aspirations.data.length === 0 ? (
							<div className='text-center'>
								<img
									src={require('../../assets/images/undraw_empty_xct9.png')}
									style={{ objectFit: 'cover', height: '14rem' }}
									alt='Loading'
								/>

								<h2>Belum ada Aspirasi dari kamu</h2>
								<p>
									Silahkan berikan aspirasi pertamamu dengan menekan tombol
									<Link to='/create-aspiration'> tambah aspirasi </Link>
									di atas
								</p>
							</div>
						) : (
							aspirations.data.map((aspiration, index) => <AspirationCard aspiration={aspiration} key={index} />)
						)}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Aspiration
