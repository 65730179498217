import React, { useContext, useState } from 'react'
import moment from 'moment'
import { AuthContext } from '../../../Context/AuthContext'

const ModalExportAttendance = () => {
	const authContext = useContext(AuthContext)
	const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'))
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

	const { userProfile } = authContext

	return (
		<div
			className='modal fade'
			id='modalExportAttendance'
			tabIndex={-1}
			role='dialog'
			aria-labelledby='modalExportAttendanceLabel'
			aria-hidden='true'>
			<div className='modal-dialog ' role='document'>
				<div className='modal-content'>
					<div className='modal-header'>
						<div>
							<h5 className='modal-title'>Ekspor data kehadiran</h5>
						</div>
						<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
							<span aria-hidden='true'>×</span>
						</button>
					</div>

					<div className='px-4 mt-2 modal-body'>
						<div className='row'>
							<div className='col-12'>
								<div className='form-group'>
									<label htmlFor='attendanceStartDate'>Tanggal Awal</label>
									<input
										type='date'
										className='form-control'
										id='attendanceStartDate'
										value={startDate}
										onChange={event => setStartDate(event.target.value)}
									/>
								</div>
							</div>

							<div className='col-12'>
								<div className='form-group'>
									<label htmlFor='attendanceEndDate'>Tanggal Akhir</label>
									<input
										type='date'
										className='form-control'
										id='attendanceEndDate'
										value={endDate}
										onChange={event => setEndDate(event.target.value)}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className='modal-footer'>
						<a
							href={`${
								process.env.REACT_APP_BASE_API_LEGACY_URL
							}/employee/employee-attendance/export?startDate=${startDate}&endDate=${endDate}&employeeID=${
								userProfile.data?.employeeAccess?.employeeID
							}&token=${localStorage.getItem('@user_token')}`}
							target='_blank'
							rel='noopener noreferrer'>
							<button type='button' className='btn btn-primary'>
								Ekspor
							</button>
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ModalExportAttendance
