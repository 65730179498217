import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import gravatar from 'gravatar'
import moment from 'moment'
import { acceptGuest, rejectGuest } from '../../../services/GuestAPI'
import { useAlert } from 'react-alert'

const GuestCard = ({ guest, onSuccess }) => {
	const _modalRef = useRef()
	const _modalRejectRef = useRef()
	const alert = useAlert()

	const [submitting, setSubmitting] = useState(false)
	const [noteReject, setNoteReject] = useState('')

	const onApprove = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				const response = await acceptGuest(guest.ID)
				if (response.code === 200) {
					setSubmitting(false)
					alert.show('Sukses terima tamu', { type: 'success' })
					_modalRef.current.click()
					onSuccess()
				}
			} catch (error) {
				console.log(error)
				alert.show('Gagal terima tamu', { type: 'error' })
			}
		}
	}

	const onRejectGuest = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				const response = await rejectGuest(guest.ID, noteReject)
				if (response.code === 200) {
					setSubmitting(false)
					alert.show('Sukses tolak tamu', { type: 'success' })
					_modalRejectRef.current.click()
					onSuccess()
				}
			} catch (error) {
				console.log(error)
				alert.show('Gagal tolak tamu', { type: 'error' })
			}
		}
	}

	const date =
		moment(guest.schedule.dateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
			? 'Hari ini, ' + moment(guest.schedule.dateTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')
			: moment().format('dddd, DD MMMM YYYY HH:mm:ss')

	return (
		<>
			<div className='aspiration-card'>
				<div className='d-flex'>
					<img
						src={guest.visitor.photo ? guest.visitor.photo : '/assets/empty.JPG'}
						className='rounded-circle'
						alt='Avatar'
						style={{
							width: '3rem',
							height: '3rem',
							objectFit: 'cover',
							border: '2px solid #172B4D',
						}}
					/>
					<div className='ml-3' style={{ width: '100%' }}>
						<h5 className=' mb-0 font-weight-bold'>
							{guest.visitor.name} - <span style={{ fontSize: 14, fontWeight: 400 }}>{guest.visitor.institution}</span>
						</h5>
						<span style={{ fontSize: '0.8rem' }}>
							<i className='ni ni-calendar-grid-58'></i> {date} <strong>&#903;</strong> &nbsp;
							{!guest.response ? 'Menunggu konfirmasi' : guest.response.status === 1 ? 'Diterima' : 'Ditolak'}
						</span>
						<h6 className='mt-2'>Keperluan :</h6>
						<p>{guest.need}</p>

						{guest.response === null && (
							<div className='d-flex align-items-center justify-content-end'>
								<button className='btn btn-danger btn-sm' data-toggle='modal' data-target={`#modal-reject-${guest.ID}`}>
									Tolak
								</button>
								<button
									className='btn btn-primary btn-sm'
									data-toggle='modal'
									data-target={`#modal-accept-${guest.ID}`}>
									Terima
								</button>
							</div>
						)}
					</div>
				</div>
			</div>

			<div className='modal' tabIndex={3} role='dialog' id={`modal-accept-${guest.ID}`}>
				<div className='modal-dialog' role='document'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Terima tamu</h5>
							<button type='button' className='close' data-dismiss='modal' aria-label='Close' ref={_modalRef}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div className='modal-body'>
							<p>Yakin terima {guest.visitor.name} ?</p>
						</div>
						<div className='modal-footer'>
							<button
								type='button'
								className='btn btn-primary'
								data-dismiss='modal'
								disabled={submitting}
								onClick={onApprove}>
								Terima
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className='modal' tabIndex={3} role='dialog' id={`modal-reject-${guest.ID}`}>
				<div className='modal-dialog' role='document'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Tolak tamu</h5>
							<button type='button' className='close' data-dismiss='modal' aria-label='Close' ref={_modalRejectRef}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div className='modal-body'>
							<p>Yakin tolak {guest.visitor.name} ?</p>

							<div className='form-group'>
								<label htmlFor='employeeEmail'>
									<span style={{ color: 'red' }}>*</span> Alasan Tolak
								</label>
								<textarea
									type='text'
									placeholder='Alasan'
									className='form-control'
									id='note'
									name='note'
									value={noteReject}
									onChange={e => setNoteReject(e.target.value)}
								/>
							</div>
						</div>
						<div className='modal-footer'>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>
								Ubah Jadwal
							</button>
							<button
								type='button'
								className='btn btn-primary'
								data-dismiss='modal'
								disabled={submitting}
								onClick={onRejectGuest}>
								Tolak
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default GuestCard
