import React, { useEffect, useContext, useState, Fragment } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const SpousesForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	// const form = { marriedStatus: '' }

	// const [employeeFamily, setEmployeeFamily] = useState(null)
	// const [employeeFamilyAdd, setEmployeeFamilyAdd] = useState(null)

	const [submitting, setSubmitting] = useState(false)

	const { employeeProfileEdit, getUserProfile } = authContext

	// const onUpdateFamily = async e => {
	// 	e.preventDefault()
	// 	if (!submitting) {
	// 		setSubmitting(true)
	// 		try {
	// 			await API.updateEmployeeFamily(employeeFamily)
	// 			alert.show('Sukses Ubah Data Keluarga', { type: 'success' })
	// 			getUserProfile()
	// 			setSubmitting(false)
	// 		} catch (error) {
	// 			setSubmitting(false)
	// 			console.log(error)
	// 			alert.show('Gagal Ubah Data Keluarga', { type: 'error' })
	// 		}
	// 	}
	// }

	// const onAddFamily = async e => {
	// 	e.preventDefault()
	// 	if (!submitting) {
	// 		try {
	// 			await API.addEmployeeFamily(employeeFamilyAdd)
	// 			alert.show('Sukses Tambah Data Keluarga', { type: 'success' })
	// 			getUserProfile()
	// 			setSubmitting(false)
	// 			setEmployeeFamilyAdd(null)
	// 		} catch (error) {
	// 			setSubmitting(false)
	// 			console.log(error)
	// 			alert.show('Gagal Tambah Data Keluarga', { type: 'error' })
	// 		}
	// 	}
	// }

	// useEffect(() => {
	// 	if (employeeProfileEdit.data.employeeFamily) {
	// 		setEmployeeFamily(employeeProfileEdit.data.employeeFamily)
	// 	}
	// }, [employeeProfileEdit])

	const form = { name: '', isCivilian: 0, NIP: '' }
	const [spouseForm, setSpouseForm] = useState([])
	const [spouseFormAdd, setSpouseFormAdd] = useState([])

	useEffect(() => {
		if (employeeProfileEdit.data.employeeSpouses.length > 0) {
			setSpouseForm(employeeProfileEdit.data.employeeSpouses)
		}
	}, [employeeProfileEdit])

	// const onChangeTextAdd = (index, key, value) => {
	// 	let temp = [...spouseFormAdd]
	// 	temp[index][key] = value
	// 	setSpouseFormAdd(temp)
	// }

	// const onChangeText = (index, key, value) => {
	// 	let temp = [...spouseForm]
	// 	temp[index][key] = value
	// 	setSpouseForm(temp)
	// }

	/**
	 * @returns {boolean}
	 */
	const disabled = () => {
		let status = false
		const merge = spouseForm.concat(spouseFormAdd)

		if (merge.length === 0) {
			return true
		}

		if (merge.length > 0) {
			merge.forEach(calc => {
				if (
					`${calc.name}`.trim().length === 0 ||
					`${calc.isCivilian}`.trim().length === 0 ||
					`${calc.NIP}`.trim().length === 0
				) {
					status = true
				} else {
					status = false
				}
			})
		}
		return status
	}

	const onAddSpouse = async () => {
		if (!submitting) {
			setSubmitting(true)

			if (spouseForm.length > 0) {
				for (let spouse of spouseForm) {
					try {
						await API.updateEmployeeSpouse(spouse, spouse.ID)
						alert.show('Sukses Mengubah Data Pasangan', { type: 'success' })
					} catch (error) {
						alert.show('Gagal Mengubah Data Pasangan', { type: 'error' })
					}
				}
			}

			if (spouseFormAdd.length > 0) {
				for (let spouse of spouseFormAdd) {
					try {
						await API.addEmployeeSpouse(spouse)
						alert.show('Sukses Menambahkan Data Pasangan', { type: 'success' })
					} catch (error) {
						alert.show('Gagal Menambahkan Data Pasangan', { type: 'error' })
					}
				}
			}
			getUserProfile()

			setSpouseFormAdd([])
			setSubmitting(false)
		}
	}

	const onDelete = async employeeSpouseID => {
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.deleteEmployeeSpouse(employeeSpouseID)
				getUserProfile()
				alert.show('Sukses Hapus Data Pasangan', { type: 'success' })
				setSubmitting(false)
			} catch (error) {
				alert.show('Gagal Hapus Data Pasangan', { type: 'error' })
				setSubmitting(false)
			}
		}
	}

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Data Pasangan</h4>
			{spouseForm.length > 0
				? spouseForm.map((spouse, index) => (
						<div key={index}>
							<div className='row'>
								<div className='col-md-6'></div>
								<div className='col-md-6'>
									<div style={{ float: 'right' }}>
										<button
											onClick={() => onDelete(spouse.ID)}
											type='button'
											disabled={submitting}
											className='btn btn-sm btn-warning btn-tooltip'
											data-toggle='tooltip'
											data-placement='top'
											title='Hapus Data'
											data-container='body'
											data-animation='true'>
											<i className='ni ni-fat-remove '></i>
										</button>
									</div>
								</div>
							</div>
							<div className='row' key={index}>
								<div className='col-md-12'>
									<div className='form-group'>
										<label htmlFor='childrenName'>
											<span style={{ color: 'red' }}>*</span> Nama Pasangan
										</label>
										<input
											type='text'
											id='childrenName'
											placeholder='Nama Pasangan'
											className='form-control'
											value={spouse.name}
											onChange={e => {
												let tmp = [...spouseForm]
												tmp[index].name = e.target.value
												setSpouseForm(tmp)
											}}
										/>
									</div>
								</div>

								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='employeeFamilyType'>
											<span style={{ color: 'red' }}>*</span> Status Pekerjaan
										</label>
										<select
											className='custom-select'
											value={spouse.isCivilian}
											id='employeeFamilyType'
											onChange={e => {
												let tmp = [...spouseForm]
												tmp[index].isCivilian = parseInt(e.target.value)
												setSpouseForm(tmp)
											}}>
											<option selected>Choose...</option>
											<option value={1}>PNS</option>
											<option value={0}>PPNPN</option>
										</select>
									</div>
								</div>

								{parseInt(spouse.isCivilian) === 1 && (
									<div className='col-md-6'>
										<div className='form-group'>
											<label htmlFor='childrenName'>
												<span style={{ color: 'red' }}>*</span> NIP
											</label>
											<input
												type='text'
												id='childrenName'
												placeholder='NIP'
												className='form-control'
												value={spouse.NIP}
												onChange={e => {
													let tmp = [...spouseForm]
													tmp[index].NIP = e.target.value
													setSpouseForm(tmp)
												}}
											/>
										</div>
									</div>
								)}
							</div>
						</div>
				  ))
				: null}

			{spouseFormAdd.length > 0
				? spouseFormAdd.map((spouse, index) => (
						<div className='row' key={index}>
							<div className='col-md-12'>
								<div className='form-group'>
									<label htmlFor='childrenName'>
										<span style={{ color: 'red' }}>*</span> Nama Pasangan
									</label>
									<input
										type='text'
										id='childrenName'
										placeholder='Nama Pasangan'
										className='form-control'
										value={spouse.name}
										onChange={e => {
											let tmp = [...spouseFormAdd]
											tmp[index].name = e.target.value
											setSpouseFormAdd(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='employeeFamilyType'>
										<span style={{ color: 'red' }}>*</span> Status Pekerjaan
									</label>
									<select
										className='custom-select'
										value={spouse.isCivilian}
										id='employeeFamilyType'
										onChange={e => {
											let tmp = [...spouseFormAdd]
											tmp[index].isCivilian = parseInt(e.target.value)
											setSpouseFormAdd(tmp)
										}}>
										<option selected>Choose...</option>
										<option value={1}>PNS</option>
										<option value={0}>PPNPN</option>
									</select>
								</div>
							</div>

							{parseInt(spouse.isCivilian) === 1 && (
								<div className='col-md-6'>
									<div className='form-group'>
										<label htmlFor='childrenName'>
											<span style={{ color: 'red' }}>*</span> NIP
										</label>
										<input
											type='text'
											id='childrenName'
											placeholder='NIP'
											className='form-control'
											value={spouse.NIP}
											onChange={e => {
												let tmp = [...spouseFormAdd]
												tmp[index].NIP = e.target.value
												setSpouseFormAdd(tmp)
											}}
										/>
									</div>
								</div>
							)}
						</div>
				  ))
				: null}

			<div className='row mt-2'>
				<div className='col-md-6'>
					<div className='form-group'>
						<button
							className='btn btn-secondary btn-block'
							onClick={e => {
								e.preventDefault()
								setSpouseFormAdd([...spouseFormAdd, form])
							}}>
							+ Tambah Data Pasangan
						</button>
					</div>
				</div>

				<div className='col-md-6'>
					<div className='form-group'>
						<button
							className='btn btn-primary btn-block'
							onClick={e => {
								e.preventDefault()
								onAddSpouse()
							}}>
							Kirim Data Pasangan
						</button>
					</div>
				</div>
			</div>
		</form>
	)
}

export default SpousesForm
