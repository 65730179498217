import React, { useContext } from 'react'
import moment from 'moment'
import { AbsentContext } from '../../../Context/AbsentContext'
import { AuthContext } from '../../../Context/AuthContext'

const ModalDuty = () => {
	const absentContext = useContext(AbsentContext)
	const authContext = useContext(AuthContext)

	const { modalDutyContent } = absentContext
	const { userProfile } = authContext

	return (
		<div
			className='modal fade'
			id='modalDuty'
			tabIndex={-1}
			role='dialog'
			aria-labelledby='modalDutyLabel'
			aria-hidden='true'>
			<div className='modal-dialog modal-lg' role='document'>
				{modalDutyContent === null ? null : (
					<div className='modal-content'>
						<div className='modal-header'>
							<div>
								<h5 className='modal-title' id='clockOutModalLabel' style={{ lineHeight: 0.7 }}>
									Dinas
								</h5>
							</div>
							<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div className='px-4'>
							<br />
							<div className='row'>
								<div className='col-4'>
									<div>
										<p className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
											Nama Karyawan
										</p>
										<p>{userProfile.data?.employeeAccess.employee.name}</p>
									</div>
								</div>
								<div className='col-4'>
									<div>
										<p className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
											Tanggal Dinas
										</p>
										<p>{modalDutyContent.selectedDate}</p>
									</div>
								</div>
								<div className='col-4'>
									<div>
										<p className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
											Nomor Surat
										</p>
										<p>{modalDutyContent.number}</p>
									</div>
								</div>
							</div>

							<br />
							<div className='row'>
								<div className='col-12'>
									<div>
										<p className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
											Deskripsi
										</p>
										<p>{modalDutyContent.description}</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default ModalDuty
