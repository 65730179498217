import React from 'react'
import { Redirect } from 'react-router-dom'

const Initial = () => {
	if (localStorage.getItem('@user_token')) {
		return <Redirect to='/absent' />
	}

	return <Redirect to='/login' />
}

export default Initial
