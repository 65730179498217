import React, { Fragment } from 'react'
import gravatar from 'gravatar'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

const LogCardHeader = ({ userProfile, attendanceStatus, onClockIn, onClockOut, submitting }) => {
	const history = useHistory()
	return (
		<div className='log-card-header bg shadow p-3 '>
			{attendanceStatus.isLoading || userProfile.isLoading ? null : (
				<Fragment>
					<div className='d-flex justify-content-between' style={{ gap: '1rem' }}>
						<div style={{ flex: 1 }}>
							<img
								className='rounded'
								style={{ width: 64, height: 64 }}
								src={
									userProfile.data.employeeAccess.employee.employeeAvatar
										? userProfile.data.employeeAccess.employee.employeeAvatar.realPath
										: gravatar.url(userProfile.data.employeeAccess.employee.email, { d: 'identicon', s: '200' })
								}
								alt='asdasd'
							/>
						</div>

						<div>
							{attendanceStatus.data.checkOut === null && attendanceStatus.data.checkIn !== null && (
								<div>
									<button
										data-toggle='modal'
										data-target='#clockOutModal'
										className='btn btn-sm btn-secondary'
										style={{ fontSize: '0.8rem' }}>
										Absen Keluar
									</button>
								</div>
							)}
							{attendanceStatus.data.checkIn === null && attendanceStatus.data.checkOut === null && (
								<div>
									<button onClick={onClockIn} className='btn btn-sm btn-secondary' style={{ fontSize: '0.8rem' }}>
										Absen Masuk
									</button>
								</div>
							)}

							<div className='mt-2' style={{ float: 'right' }}>
								{}
								<button
									disabled={attendanceStatus.data.duty}
									onClick={() => history.push('/absent/dinas')}
									className='btn btn-sm btn-secondary'
									style={{ fontSize: '0.8rem' }}>
									{attendanceStatus.data.duty ? 'Sedang Dinas' : 'Dinas'}
								</button>
							</div>
						</div>
					</div>

					{attendanceStatus.data.checkIn && (
						<div className='mt-2'>
							<p style={{ marginBottom: 0, fontWeight: 'bold', fontSize: '0.8rem' }}>Presensi</p>
							<span style={{ fontSize: '0.75rem' }}>
								<strong>Masuk - </strong>{' '}
								{moment(attendanceStatus.data.checkIn.created, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}
							</span>
						</div>
					)}

					{attendanceStatus.data.checkOut && (
						<div style={{ marginTop: -6 }}>
							<span style={{ fontSize: '0.75rem' }}>
								<strong>Keluar - </strong>{' '}
								{moment(attendanceStatus.data.checkOut.created, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss')}
							</span>
						</div>
					)}

					<div className='d-flex align-items-center' style={{ float: 'right', marginTop: '16px' }}>
						
					</div>

					<div className='log-card-date shadow'>
						<span>{moment().format('MMM')}</span>
						<span className='font-weight-bold' style={{ fontSize: '1rem' }}>
							{moment().format('DD')}
						</span>
					</div>
				</Fragment>
			)}
		</div>
	)
}

export default LogCardHeader
