import React, { useContext } from 'react'
import moment from 'moment'
import API from '../../../services/API'
import { AbsentContext } from '../../../Context/AbsentContext'

const LogCard = ({ timeStamp, label, attendance, date }) => {
	const absentContext = useContext(AbsentContext)

	return (
		<div className='d-flex align-items-center mt-2' style={{ gap: '1rem' }}>
			<div
				className='log-date shadow'
				style={{ cursor: 'pointer' }}
				data-toggle='modal'
				data-target='#modalAbsentDetail'
				onClick={() => {
					API.getDetailAttendance(attendance.ID).then(response => {
						absentContext.setModalContent({
							...response.data,
							selectedDate: date,
						})
					})
				}}>
				<span className='font-weight-bold' style={{ fontSize: '0.7rem' }}>
					{moment(timeStamp.created, 'YYYY-MM-DD HH:mm:ss').format('hh:mm')}
				</span>
				<span style={{ fontSize: '0.6rem' }}>{moment(timeStamp.created, 'YYYY-MM-DD HH:mm:ss').format('A')}</span>
			</div>

			<div className='d-flex justify-content-between' style={{ width: '100%' }}>
				<div
					style={{ flex: 1 }}
					className='pr-2'
					data-toggle='modal'
					data-target='#modalAbsentDetail'
					style={{ cursor: 'pointer' }}
					onClick={() => {
						API.getDetailAttendance(attendance.ID).then(response => {
							absentContext.setModalContent({
								...response.data,
								selectedDate: date,
							})
						})
					}}>
					<p className='font-weight-bold' style={{ marginBottom: 0, fontSize: '0.8rem' }}>
						{label}
					</p>
				</div>

				<div>
					
				</div>
			</div>
		</div>
	)
}

export default LogCard
