import React, { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const BloodForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const [bloodType, setBloodType] = useState({ type: '' })
	const [initialBlood, setInitialBlood] = useState(null)
	const [submitting, setSubmitting] = useState(false)

	const { employeeProfileEdit, getUserProfile } = authContext

	const onSubmit = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			if (initialBlood === null) {
				try {
					await API.addEmployeeBlood(bloodType.type)
					getUserProfile()
					alert.show('Sukses Ubah Golongan Darah', { type: 'success' })
					setSubmitting(false)
				} catch (error) {
					alert.show('Gagal Ubah Golongan Darah', { type: 'error' })
					setSubmitting(false)
				}
			} else {
				try {
					await API.updateEmployeeBlood(bloodType.type)
					getUserProfile()
					alert.show('Sukses Ubah Golongan Darah', { type: 'success' })
					setSubmitting(false)
				} catch (error) {
					alert.show('Gagal Ubah Golongan Darah', { type: 'error' })
					setSubmitting(false)
				}
			}
		}
	}

	useEffect(() => {
		if (employeeProfileEdit.data.employeeBloodType) {
			setBloodType({ ...bloodType, type: employeeProfileEdit.data.employeeBloodType.type })
			setInitialBlood(employeeProfileEdit.data.employeeBloodType)
		}
	}, [employeeProfileEdit])

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Golongan Darah</h4>
			<div className='row'>
				<div className='col-md-6'>
					<div className='form-group'>
						<label htmlFor='employeeBloodType'>
							<span style={{ color: 'red' }}>*</span> Tipe
						</label>
						<select
							className='custom-select'
							id='employeeBloodType'
							value={bloodType.type}
							onChange={e => setBloodType({ ...bloodType, type: e.target.value })}>
							<option selected>Choose...</option>
							<option value='A'>A</option>
							<option value='AB'>AB</option>
							<option value='B'>B</option>
							<option value='O'>O</option>
						</select>
					</div>
				</div>

				<div className='col-md-6'>
					<label htmlFor=''></label>

					<button className='btn btn-primary btn-block mt-2' disabled={submitting} onClick={onSubmit}>
						Ubah Golongan Darah
					</button>
				</div>
			</div>
		</form>
	)
}

export default BloodForm
