import React, { Fragment, useState, useContext } from 'react'
import Navbar from '../../Components/Navbar'
import Calendar from 'react-calendar'
import moment from 'moment'
import { LeaveContext } from '../../Context/LeaveContext'
import API from '../../services/API'
import { useHistory } from 'react-router-dom'

const LEAVE_BIG = 1
const LEAVE_SICK = 2
const LEAVE_PREGNANT = 3
const LEAVE_IMPORTANT = 4
const LEAVE_YEARLY = 5

export default () => {
	const history = useHistory()
	const leaveContext = useContext(LeaveContext)
	const {
		leaveStatus,
		getLeaveStatus,
		getLeaveStatusReject,
		getLeaveStatusApprove,
		getLeaveStatusPending,
		resetLeave,
	} = leaveContext
	const [submitting, setSubmitting] = useState(false)
	const [showSuccesAlert, setShowSuccesAlert] = useState(false)
	const [showErrorAlert, setErrorAlert] = useState(false)

	const [selectedDate, setSelectedDate] = useState([])
	const [selectedType, setSelectedType] = useState(null)
	const [errorMessage, setErrorMessage] = useState(null)

	const onChangeSelectedType = event => {
		if (parseInt(event.target.value) === 3) {
			setSelectedDate([])
		}
		setSelectedType(event.target.value)
	}

	const onCreateLeave = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				resetLeave()

				const body = { type: selectedType, selectedDate }
				await API.createEmployeeLeave(body)

				setTimeout(() => {
					setShowSuccesAlert(false)
				}, 3000)
				getLeaveStatus()
				getLeaveStatusApprove()
				getLeaveStatusPending()
				getLeaveStatusReject()
				setSubmitting(false)
				setShowSuccesAlert(true)
				setSelectedDate([])
				history.push('/absent')
			} catch (error) {
				setSubmitting(false)
				setErrorAlert(true)
				setErrorMessage(error.response?.data?.errors?.errors[0] ?? 'Gagal ajukan cuti')
				getLeaveStatus()
				getLeaveStatusApprove()
				getLeaveStatusPending()
				getLeaveStatusReject()
				setTimeout(() => {
					setErrorAlert(false)
				}, 3000)
			}
		}
	}

	return (
		<Fragment>
			<Navbar />

			<div className='container py-5 '>
				<div className='row dudi-container justify-content-sm-center'>
					<div className='col-xs-12 col-md-6 d-flex justify-content-sm-center justify-content-md-start pb-5 flex-column'>
						<Calendar
							onChange={(value, event) => {
								let dateString = moment(value).format('YYYY-MM-DD')
								if (selectedDate.includes(dateString)) {
									const tmp = selectedDate.filter(d => d !== dateString)
									setSelectedDate(tmp)
								} else {
									if (parseInt(selectedType) === 3) {
										setSelectedDate([dateString])
									} else {
										setSelectedDate([...selectedDate, dateString])
									}
								}
							}}
							tileContent={({ activeStartDate, date, view }) =>
								view === 'month' &&
								selectedDate.length > 0 &&
								selectedDate.filter(d => d === moment(date).format('YYYY-MM-DD')).length > 0 ? (
									<span role='img'>✅</span>
								) : null
							}
							tileDisabled={({ activeStartDate, date, view }) => {
								if (view === 'month') {
									if (parseInt(selectedType) === 2 || parseInt(selectedType) === 4) {
										return date.getDay() === 0 || date.getDay() === 6
									} else {
										return (
											date.getDay() === 0 ||
											date.getDay() === 6 ||
											moment(date).isBefore(moment().format('YYYY-MM-DD'), 'days')
										)
									}
								}
							}}
						/>
						<br />
					</div>
					<div className='col-xs-12 col-md-6 d-flex justify-content-sm-center justify-content-md-start flex-column'>
						<h3>Ajukan Cuti</h3>
						<h4>
							Jumlah Cuti :{' '}
							{parseInt(selectedType) === 3 && selectedDate.length > 0 ? '3 bulan' : selectedDate.length + ' hari'}{' '}
						</h4>
						{parseInt(selectedType) === 3 && <span>Anda Hanya Perlu Memilih Tanggal Awal Cuti</span>}
						<br />
						<div>
							<div className='custom-control custom-radio mb-3'>
								<input
									name='selectedTypes'
									className='custom-control-input'
									id='customRadio1'
									type='radio'
									value={LEAVE_YEARLY}
									onChange={onChangeSelectedType}
								/>
								<label className='custom-control-label' htmlFor='customRadio1'>
									Cuti Tahunan
								</label>
							</div>
							<div className='custom-control custom-radio mb-3'>
								<input
									name='selectedTypes'
									className='custom-control-input'
									id='customRadio2'
									type='radio'
									value={LEAVE_SICK}
									onChange={onChangeSelectedType}
								/>
								<label className='custom-control-label' htmlFor='customRadio2'>
									Cuti Sakit
								</label>
							</div>
							<div className='custom-control custom-radio mb-3'>
								<input
									name='selectedTypes'
									className='custom-control-input'
									id='customRadio3'
									type='radio'
									value={LEAVE_IMPORTANT}
									onChange={onChangeSelectedType}
								/>
								<label className='custom-control-label' htmlFor='customRadio3'>
									Cuti Alasan Penting
								</label>
							</div>
							<div className='custom-control custom-radio mb-3'>
								<input
									name='selectedTypes'
									className='custom-control-input'
									id='customRadio4'
									type='radio'
									value={LEAVE_PREGNANT}
									onChange={onChangeSelectedType}
								/>
								<label className='custom-control-label' htmlFor='customRadio4'>
									Cuti Hamil
								</label>
							</div>
							<div className='custom-control custom-radio mb-3'>
								<input
									name='selectedTypes'
									className='custom-control-input'
									id='customRadio5'
									type='radio'
									value={LEAVE_BIG}
									onChange={onChangeSelectedType}
								/>
								<label className='custom-control-label' htmlFor='customRadio5'>
									Cuti Besar
								</label>
							</div>
						</div>
						<button
							className='btn btn-danger btn-lg btn-block mb-2'
							disabled={submitting || selectedDate.length === 0 || selectedType === null}
							onClick={onCreateLeave}>
							Ambil Cuti
						</button>
						<button className='btn btn-secondary btn-lg btn-block mb-2' onClick={() => history.push('/absent')}>
							Kembali
						</button>

						{showSuccesAlert && (
							<div className='alert alert-success' role='alert'>
								Berhasil Ajukan Cuti
							</div>
						)}
						{showErrorAlert && (
							<div className='alert alert-danger' role='alert'>
								{errorMessage}
							</div>
						)}
					</div>
					{leaveStatus.isLoading ? null : (
						<div style={{ width: '100%' }}>
							<h4>Status Cuti :</h4>

							<table className='table'>
								<thead>
									<tr>
										<th>Code</th>
										<th>Tanggal Cuti</th>
										<th>Jenis Cuti</th>
										<th>Status</th>
										<th>Action</th>
									</tr>
								</thead>
								<tbody>
									{leaveStatus.data.map(status => (
										<tr key={status.ID}>
											<td>{status.code}</td>
											<td>
												<ul>
													{status.employeeLeaveDates.map(employeeLeaveDate => (
														<li key={employeeLeaveDate.ID}>
															{moment(employeeLeaveDate.date, 'YYYY-MM-DD').format('DD MMM YYYY')}
														</li>
													))}
												</ul>
											</td>
											<td>{status.typeLabel}</td>
											<td>
												<span
													className={`badge badge-${
														status.employeeLeaveResponse === null
															? 'warning'
															: status.employeeLeaveResponse.canLeave
															? 'succes'
															: 'danger'
													}`}>
													{status.employeeLeaveResponse === null
														? 'Menunggu Konfirmasi'
														: status.employeeLeaveResponse.canLeave
														? 'Disetujui'
														: 'Ditolak'}
												</span>
											</td>
											<td>
												<a
													href={process.env.REACT_APP_BASE_API_LEGACY_URL + '/leave/employee-leave/export/' + status.ID}
													className='btn btn-sm btn-primary'
													target='_blank'
													rel='noopener noreferrer'>
													Lihat berkas
												</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</div>
			</div>
		</Fragment>
	)
}
