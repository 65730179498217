import React, { useEffect, useContext, useState, Fragment } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const CareerForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const form = { civilianCategoryID: '', date: '', file: null, numberReference: '' }

	const [historyEmployeeCivilianCategory, setHistoryEmployeeCivilianCategory] = useState([])
	const [historyEmployeeCivilianCategoryAdd, setHistoryEmployeeCivilianCategoryAdd] = useState([])
	const [loadingDelete, setLoadingDelete] = useState(false)

	const [submitting, setSubmitting] = useState(false)

	const { employeeProfileEdit, getUserProfile, civilianCategories } = authContext

	useEffect(() => {
		if (employeeProfileEdit.data.historyEmployeeCivilianCategory.length > 0) {
			const tmp = employeeProfileEdit.data.historyEmployeeCivilianCategory.map(civilian => ({
				...civilian,
				file: civilian.realPath,
				numberReference: civilian.number_reference,
			}))
			setHistoryEmployeeCivilianCategory(tmp)
		}
	}, [employeeProfileEdit])

	const disable = () => {
		let status = false
		let merged = historyEmployeeCivilianCategory.concat(historyEmployeeCivilianCategoryAdd)

		if (merged.length === 0) {
			status = true
		}

		if (merged.length > 0) {
			merged.forEach(calc => {
				if (
					`${calc.civilianCategoryID}`.trim().length === 0 ||
					`${calc.date}`.trim().length === 0 ||
					calc.numberReference.trim().length === 0 ||
					calc.file === null
				) {
					status = true
				} else {
					status = false
				}
			})
		}

		return status
	}

	const onDelete = async id => {
		if (!loadingDelete) {
			setLoadingDelete(true)
			try {
				await API.deleteEmployeeCivilianCategory(id)
				getUserProfile()
				setLoadingDelete(false)
				alert.show('Sukses Hapus Golongan', { type: 'success' })
			} catch (error) {
				setLoadingDelete(false)
				alert.show('Sukses Hapus Golongan', { type: 'error' })
			}
		}
	}

	const submit = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			if (historyEmployeeCivilianCategory.length > 0) {
				for (const iterator of historyEmployeeCivilianCategory) {
					try {
						await API.updateEmployeeCivilianCategory(iterator, iterator.ID)
						alert.show('Sukses Ubah Golongan', { type: 'success' })
					} catch (error) {
						alert.show('Gagal Ubah Golongan', { type: 'error' })
					}
				}
			}

			if (historyEmployeeCivilianCategoryAdd.length > 0) {
				for (const iterator of historyEmployeeCivilianCategoryAdd) {
					try {
						await API.addCivilianCategory(iterator)
						alert.show('Sukses Ubah Data', { type: 'success' })
					} catch (error) {
						alert.show('Gagal Tambah Golongan', { type: 'error' })
					}
				}
			}
			getUserProfile()
			setSubmitting(false)
			setHistoryEmployeeCivilianCategoryAdd([])
		}
	}

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Riwayat Golongan</h4>

			{historyEmployeeCivilianCategory.length > 0 &&
				historyEmployeeCivilianCategory.map((civilian, index) => (
					<div key={index}>
						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<div style={{ float: 'right' }}>
									<button
										onClick={e => {
											e.preventDefault()
											onDelete(civilian.ID)
										}}
										type='button'
										className='btn btn-sm btn-warning btn-tooltip'
										data-toggle='tooltip'
										data-placement='top'
										title='Hapus Data'
										data-container='body'
										data-animation='true'>
										<i className='ni ni-fat-remove '></i>
									</button>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='careerCategory'>
										<span style={{ color: 'red' }}>*</span> Kategori
									</label>
									<select
										className='custom-select'
										id='careerCategory'
										value={civilian.civilianCategoryID}
										onChange={e => {
											let tmp = [...historyEmployeeCivilianCategory]
											tmp[index].civilianCategoryID = e.target.value
											setHistoryEmployeeCivilianCategory(tmp)
										}}>
										<option selected>Choose...</option>
										{civilianCategories.data.map((category, index) => (
											<option key={index} value={category.ID}>
												{category.civilianName}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='careerDate'>
									<span style={{ color: 'red' }}>*</span> Tanggal
								</label>
								<div className='form-group'>
									<input
										type='date'
										id='careerDate'
										placeholder='Tanggal'
										className='form-control'
										value={civilian.date}
										onChange={e => {
											let tmp = [...historyEmployeeCivilianCategory]
											tmp[index].date = e.target.value
											setHistoryEmployeeCivilianCategory(tmp)
										}}
									/>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-6'>
								<label htmlFor='carrerNumber'>
									<span style={{ color: 'red' }}>*</span> No. SK
								</label>
								<div className='form-group'>
									<input
										type='text'
										id='carrerNumber'
										placeholder='No. SK'
										className='form-control'
										value={civilian.numberReference}
										onChange={e => {
											let tmp = [...historyEmployeeCivilianCategory]
											tmp[index].numberReference = e.target.value
											setHistoryEmployeeCivilianCategory(tmp)
										}}
									/>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='careerFile'>
										<span style={{ color: 'red' }}>*</span> Berkas
									</label>
									<input
										type='file'
										id='careerFile'
										className='form-control'
										onChange={e => {
											let tmp = [...historyEmployeeCivilianCategory]
											tmp[index].file = e.target.files[0]
											setHistoryEmployeeCivilianCategory(tmp)
										}}
									/>
									{civilian.realPath ? (
										<a href={`${civilian.realPath}`} target='_blank' rel='noopener noreferrer'>
											<div className='btn btn-primary btn-sm mt-2'>
												<small>
													<i className='ni ni-cloud-download-95'></i> &nbsp; Lihat berkas
												</small>
											</div>
										</a>
									) : (
										<div className='btn btn-primary btn-sm mt-2' disabled>
											<small>
												<i className='ni ni-cloud-download-95'></i> &nbsp;Harap unggah berkas
											</small>
										</div>
									)}
								</div>
							</div>
						</div>
						<hr />
					</div>
				))}

			{historyEmployeeCivilianCategoryAdd.length > 0 &&
				historyEmployeeCivilianCategoryAdd.map((civilianAdd, index) => (
					<div key={index}>
						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<div style={{ float: 'right' }}>
									<button
										onClick={() =>
											setHistoryEmployeeCivilianCategoryAdd(
												historyEmployeeCivilianCategoryAdd.filter((_, idx) => idx !== index)
											)
										}
										type='button'
										className='btn btn-sm btn-warning btn-tooltip'
										data-toggle='tooltip'
										data-placement='top'
										title='Hapus Data'
										data-container='body'
										data-animation='true'>
										<i className='ni ni-fat-remove '></i>
									</button>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='careerCategory'>
										<span style={{ color: 'red' }}>*</span> Kategori
									</label>
									<select
										className='custom-select'
										id='careerCategory'
										value={civilianAdd.civilianCategoryID}
										onChange={e => {
											let tmp = [...historyEmployeeCivilianCategoryAdd]
											tmp[index].civilianCategoryID = e.target.value
											setHistoryEmployeeCivilianCategoryAdd(tmp)
										}}>
										<option selected>Choose...</option>
										{civilianCategories.data.map((category, index) => (
											<option value={category.ID}>{category.civilianName}</option>
										))}
									</select>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='careerDate'>
									<span style={{ color: 'red' }}>*</span> Tanggal
								</label>
								<div className='form-group'>
									<input
										type='date'
										id='careerDate'
										placeholder='Tanggal'
										className='form-control'
										value={civilianAdd.date}
										onChange={e => {
											let tmp = [...historyEmployeeCivilianCategoryAdd]
											tmp[index].date = e.target.value
											setHistoryEmployeeCivilianCategoryAdd(tmp)
										}}
									/>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-6'>
								<label htmlFor='carrerNumber'>
									<span style={{ color: 'red' }}>*</span> No. SK
								</label>
								<div className='form-group'>
									<input
										type='text'
										id='carrerNumber'
										placeholder='No. SK'
										className='form-control'
										value={civilianAdd.numberReference}
										onChange={e => {
											let tmp = [...historyEmployeeCivilianCategoryAdd]
											tmp[index].numberReference = e.target.value
											setHistoryEmployeeCivilianCategoryAdd(tmp)
										}}
									/>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='careerFile'>
										<span style={{ color: 'red' }}>*</span> Berkas
									</label>
									<input
										type='file'
										id='careerFile'
										className='form-control'
										onChange={e => {
											let tmp = [...historyEmployeeCivilianCategoryAdd]
											tmp[index].file = e.target.files[0]
											setHistoryEmployeeCivilianCategoryAdd(tmp)
										}}
									/>
								</div>
							</div>
						</div>
						<hr />
					</div>
				))}

			<div className='row mt-2'>
				<div className='col-md-6'>
					<div className='form-group'>
						<button
							className='btn btn-secondary btn-block'
							onClick={e => {
								e.preventDefault()
								setHistoryEmployeeCivilianCategoryAdd([...historyEmployeeCivilianCategoryAdd, { ...form }])
							}}>
							+ Tambah Riwayat Golongan
						</button>
					</div>
				</div>

				<div className='col-md-6'>
					<div className='form-group'>
						<button className='btn btn-primary btn-block' disabled={disable() || submitting} onClick={submit}>
							Kirim Riwayat Golongan
						</button>
					</div>
				</div>
			</div>
		</form>
	)
}

export default CareerForm
