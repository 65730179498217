import React, { Fragment, useState, useContext } from 'react'
import Navbar from '../../Components/Navbar'
import Calendar from 'react-calendar'
import { ReactComponent as BriefCase } from '../../assets/svg/briefcase-solid.svg'
import moment from 'moment'
import DutyAPI from '../../services/DutyAPI'
import { useAlert } from 'react-alert'
import { useHistory } from 'react-router-dom'
import { AbsentContext } from '../../Context/AbsentContext'

const Agency = () => {
	const alert = useAlert()
	const history = useHistory()
	const absentContext = useContext(AbsentContext)

	const [selectedDate, setSelectedDate] = useState([])
	const [form, setForm] = useState({ description: '', number: '' })
	const [submitting, setSubmitting] = useState(false)
	const { getAttendanceStatus } = absentContext

	const onCreateDuty = async () => {
		if (!submitting) {
			setSubmitting(true)
			try {
				const body = { selectedDate, ...form }
				await DutyAPI.createEmployeeDuty(body)
				setSubmitting(false)
				alert.success('Berhasil dinas')
				history.push('/absent')
				getAttendanceStatus()
			} catch (error) {
				setSubmitting(false)
				alert.error('Gagal dinas')
			}
		}
	}

	return (
		<Fragment>
			<Navbar />
			<div className='container py-5 '>
				<div className='row dudi-container justify-content-sm-center'>
					<div className='col-xs-12 col-md-6 d-flex justify-content-sm-center justify-content-md-start pb-5 flex-column'>
						<Calendar
							onChange={(value, event) => {
								let dateString = moment(value).format('YYYY-MM-DD')
								if (selectedDate.includes(dateString)) {
									const tmp = selectedDate.filter(d => d !== dateString)
									setSelectedDate(tmp)
								} else {
									setSelectedDate([...selectedDate, dateString])
								}
							}}
							tileContent={({ activeStartDate, date, view }) =>
								view === 'month' &&
								selectedDate.length > 0 &&
								selectedDate.filter(d => d === moment(date).format('YYYY-MM-DD')).length > 0 ? (
									<span role='img'>✅</span>
								) : null
							}
							tileDisabled={({ activeStartDate, date, view }) => {
								if (view === 'month') {
									return date.getDay() === 0 || date.getDay() === 6
								}
							}}
						/>
					</div>

					<div className='col-xs-12 col-md-6 d-flex justify-content-sm-center justify-content-md-start flex-column'>
						<span>No. Surat Dinas : </span>
						<div>
							<input
								type='text'
								name='description'
								className='form-control'
								id='number'
								style={{ width: '100%' }}
								onChange={e => setForm({ ...form, number: e.target.value })}
							/>
						</div>
						<br />
						<span>Keterangan Dinas : </span>
						<div>
							<textarea
								onChange={e => setForm({ ...form, description: e.target.value })}
								name='description'
								className='form-control'
								id='description'
								style={{ width: '100%' }}
								cols='30'
								rows='10'></textarea>
						</div>
						<br />
						<button
							onClick={onCreateDuty}
							className='btn btn-success btn-sm btn-block clock-in-btn'
							disabled={submitting}>
							<BriefCase style={{ height: '16px' }} />{' '}
							<span className='user-name' style={{ marginLeft: '16px' }}>
								Dinas
							</span>
						</button>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Agency
