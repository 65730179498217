import React from 'react'

import { ReactComponent as UserIcon } from '../../assets/svg/user-solid.svg'
import { ReactComponent as UserIconCheck } from '../../assets/svg/fingerprint-solid.svg'
import { ReactComponent as Calendar } from '../../assets/svg/calendar-alt-solid.svg'
import { ReactComponent as LogOut } from '../../assets/svg/sign-out-alt-solid.svg'
import { ReactComponent as Comment } from '../../assets/svg/comments-solid.svg'
import { NavLink } from 'react-router-dom'
import MemoGuestIcon from '../GuestIcon'

export default () => {
	return (
		<div className='dudi-navbar' style={{ background: '#172B4D' }}>
			<ul className='dudi-navbar-nav'>
				<li className='dudi-navbar-item'>
					<NavLink to='/profile' className='dudi-navbar-link' activeClassName='dudi-navbar-link-active'>
						<UserIcon className='fa-primary' />
						<span className='link-text-dudi'>Profil</span>
					</NavLink>
				</li>
				<li className='dudi-navbar-item'>
					<NavLink to='/absent' className='dudi-navbar-link' activeClassName='dudi-navbar-link-active'>
						<UserIconCheck className='fa-primary' />
						<span className='link-text-dudi'>Absensi</span>
					</NavLink>
				</li>
				<li className='dudi-navbar-item'>
					<NavLink to='/guest' className='dudi-navbar-link' activeClassName='dudi-navbar-link-active'>
						{/* <Comment className='fa-primary' /> */}
						<MemoGuestIcon className='fa-primary' />
						<span className='link-text-dudi'>Tamu</span>
					</NavLink>
				</li>
				{localStorage.getItem('@user_token') && (
					<li className='dudi-navbar-item'>
						<a className='dudi-navbar-link' data-toggle='modal' data-target='#modal-logout'>
							<LogOut className='fa-primary' />
							<span className='link-text-dudi'>Keluar</span>
						</a>
					</li>
				)}
			</ul>
		</div>
	)
}
