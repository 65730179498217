import React, { Fragment, useContext, useRef } from 'react'
import Navbar from '../../Components/Navbar'
import { AuthContext } from '../../Context/AuthContext'
import { AbsentContext } from '../../Context/AbsentContext'
import { ReactComponent as AngleUp } from '../../assets/svg/angle-double-up-solid.svg'
import { ReactComponent as BriefCase } from '../../assets/svg/briefcase-solid.svg'
import { ReactComponent as BirthDayCake } from '../../assets/svg/birthday-cake-solid.svg'
import { ReactComponent as FingerPrint } from '../../assets/svg/fingerprint-solid.svg'
import gravatar from 'gravatar'
import { Link } from 'react-router-dom'
import API from '../../services/API'
import { useAlert } from 'react-alert'

import moment from 'moment'
import 'moment/locale/id'
import CommentIcon from '../../Components/CommentIcon'
moment.locale('id')

const Profile = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)
	const uploadRef = useRef(null)

	const { userProfile, homeInfo, getUserProfile } = authContext
	// const { attendanceStatus } = absentContext

	const getDateDiff = date => {
		const a = moment(date, 'YYYY-MM-DD')
		const b = moment()
		return a.diff(b, 'days') + 1 // =1
	}

	const getDateDiffBirthDate = date => {
		const a = moment(moment(date, 'YYYY-MM-DD'))
		const b = moment()
		return a.diff(b, 'days') + 1 // =1
	}

	const getYearDiff = date => {
		const a = moment(date, 'YYYY-MM-DD')
		const b = moment()
		return b.diff(a, 'years') + 1 // =1
	}

	return (
		<Fragment>
			<Navbar />
			<div className='container py-5'>
				<div className='mx-auto'>
					{userProfile.isLoading ? (
						<div className='text-center'>
							<img
								src={require('../../assets/images/undraw_fast_loading_0lbh.png')}
								style={{ objectFit: 'cover', height: '14rem' }}
								alt='Loading'
							/>

							<h2>Loading ...</h2>
						</div>
					) : (
						<Fragment>
							<div className='text-center' style={{ position: 'relative' }}>
								<Link to='/aspiration'>
									<div className='profile-aspiration-icon'>
										<CommentIcon />
									</div>
								</Link>

								<img
									src={
										userProfile.data.employeeAccess.employee.employeeAvatar
											? userProfile.data.employeeAccess.employee.employeeAvatar.realPath
											: gravatar.url(userProfile.data.employeeAccess.employee.email, { d: 'identicon', s: '200' })
									}
									onClick={() => {
										uploadRef.current.click()
									}}
									alt='jiss'
									className='rounded-circle border'
									style={{ objectFit: 'cover', height: '14rem', width: '14rem', cursor: 'pointer' }}
								/>
								<input
									type='file'
									style={{ display: 'none' }}
									ref={uploadRef}
									onChange={async e => {
										if (userProfile.data.employeeAccess.employee.employeeAvatar) {
											try {
												await API.updateEmployeeAvatar({ image: e.target.files[0] })
												alert.show('Sukses upload avatar', { type: 'success' })
												getUserProfile()
											} catch (error) {
												alert.show('Gagal upload avatar', { type: 'error' })
												console.log(error)
											}
										} else {
											try {
												await API.addEmployeeAvatar({ image: e.target.files[0] })
												alert.show('Sukses upload avatar', { type: 'success' })
												getUserProfile()
											} catch (error) {
												alert.show('Gagal upload avatar', { type: 'error' })
												console.log(error)
											}
										}
									}}
								/>
								<div
									className='mt-2 d-flex flex-column flex-md-row justify-content-center align-items-center align-items-md-start'
									style={{ alignItems: 'flex-start' }}>
									<div className='d-flex flex-column'>
										<h2 className='font-weight-bold mb-0' style={{ lineHeight: '2rem' }}>
											{userProfile.data.employeeAccess.employee.name}
										</h2>
										<small style={{ fontSize: '1.25rem' }}>{userProfile.data.employeeAccess.employee.email}</small>
										<small style={{ fontSize: '1rem' }}>
											Total Point : {userProfile.data.employeeAccess.employee.appreciationPoint}
										</small>
									</div>

									<Link to='/profile/edit' className='mt-2'>
										<div>
											<button className='btn btn-sm ml-2 border rounded-sm' style={{ color: '#172B4D' }}>
												Edit
											</button>
										</div>
									</Link>
								</div>

								<hr />
							</div>
							{homeInfo.isLoading ? null : (
								<div className='row card-notif-wrapper mx-auto'>
									{homeInfo.data
										.filter(home => home.type === 'attendance')
										.map((attendanceStatus, index) => (
											<div key={index} className='col-6 col-md-4 mb-4'>
												<div className='dudi-card-notif d-flex flex-column justify-content-center shadow-sm p-3'>
													<div className='d-flex align-items-center'>
														<div className='p-2 bg-dark logo-card-bg' style={{ borderRadius: '0.5rem' }}>
															<FingerPrint style={{ height: '2.5rem' }} />
														</div>

														<div className='d-flex flex-column'>
															<span className='ml-2 font-weight-bold' style={{ fontSize: '1rem' }}>
																{!attendanceStatus.data.employeeCheckIn
																	? '-'
																	: attendanceStatus.data.employeeCheckOut
																	? 'Check-Out'
																	: 'Check-In'}
															</span>
															<span className='ml-2' style={{ fontSize: '0.8rem', opacity: '0.7' }}>
																{!attendanceStatus.data.employeeCheckIn
																	? '-'
																	: moment(attendanceStatus.data.employeeCheckIn.created, 'YYYY-MM-DD HH:mm:ss').format(
																			'HH:mm'
																	  )}
															</span>
														</div>
													</div>

													<h6 className='font-weight-bold mt-4 mb-0'>Absensi</h6>
													<h6 style={{ opacity: '0.7', fontSize: '0.9rem', padding: '0' }}>
														{!attendanceStatus.data.employeeCheckIn
															? '-'
															: moment(attendanceStatus.data.employeeCheckIn.created, 'YYYY-MM-DD HH:mm:ss').format(
																	'DD MMM YYYY'
															  )}
													</h6>
													<h6 className='font-weight mt-auto mb-0'>
														<strong>
															{' '}
															{!attendanceStatus.data.employeeCheckIn
																? '-'
																: `${moment
																		.utc(
																			moment().diff(
																				moment(attendanceStatus.data.employeeCheckIn.created, 'YYYY-MM-DD HH:mm:ss')
																			)
																		)
																		.format('HH:mm')} `}{' '}
														</strong>
														Jam Kerja
													</h6>
												</div>
											</div>
										))}

									{/* {======} */}
									{homeInfo.data
										.filter(home => home.type === 'promotion')
										.map((promotion, index) => (
											<div key={index} className='col-6 col-md-4 mb-4'>
												<div className='dudi-card-notif d-flex flex-column justify-content-center shadow-sm p-3'>
													<div className='d-flex align-items-center'>
														<div className='p-2 bg-dark logo-card-bg' style={{ borderRadius: '0.5rem' }}>
															<AngleUp style={{ height: '2.5rem' }} />
														</div>

														<div className='d-flex flex-column'>
															<span className='ml-2 font-weight-bold' style={{ fontSize: '1rem' }}>
																{promotion.nextCategory.civilianName}
															</span>
															<span className='ml-2' style={{ fontSize: '0.8rem', opacity: '0.7' }}>
																dari {promotion.currentCategory.civilianCategory.civilianName}
															</span>
														</div>
													</div>

													<h6 className='font-weight-bold mt-4 mb-0'>Naik Pangkat</h6>
													<h6 style={{ opacity: '0.7', fontSize: '0.9rem', padding: '0' }}>
														{moment(promotion.nextCategory.next, 'YYYY-MM-DD').format('DD MMMM YYYY')}
													</h6>
													<h6 className='font-weight mt-auto mb-0'>
														<strong>{getDateDiff(promotion.nextCategory.next)}</strong> Hari lagi{' '}
													</h6>
												</div>
											</div>
										))}
									{/* {======} */}
									{homeInfo.data
										.filter(home => home.type === 'retiring')
										.map((retiring, index) => (
											<div className='col-6 col-md-4 mb-4' key={index}>
												<div className='dudi-card-notif d-flex flex-column justify-content-center shadow-sm p-3'>
													<div className='d-flex'>
														<div className='p-2 bg-dark logo-card-bg' style={{ borderRadius: '0.5rem' }}>
															<BriefCase style={{ height: '2.5rem' }} />
														</div>

														<div className='d-flex flex-column'>
															<span className='ml-2 font-weight-bold' style={{ fontSize: '1rem' }}>
																{getYearDiff(retiring.data.bornDate)} <small style={{ fontSize: '0.7rem' }}>Thn</small>
															</span>
														</div>
													</div>

													<h6 className='font-weight-bold mt-4 mb-0'>Pensiun</h6>
													<h6 style={{ opacity: '0.7', fontSize: '0.9rem', padding: '0' }}>
														{moment(retiring.data.retiringDate, 'YYYY-MM-DD').format('DD MMMM YYYY')}
													</h6>
													<h6 className='font-weight mt-auto mb-0'>
														<strong>{getDateDiff(retiring.data.retiringDate)}</strong> Hari lagi{' '}
													</h6>
												</div>
											</div>
										))}
									{/* {======} */}
									{homeInfo.data
										.filter(home => home.type === 'birthDate')
										.map((birthDate, index) => (
											<div className='col-6 col-md-4 mb-4' key={index}>
												<div className='dudi-card-notif d-flex flex-column justify-content-center shadow-sm p-3'>
													<div className='d-flex align-items-center'>
														<div className='p-2 bg-dark logo-card-bg' style={{ borderRadius: '0.5rem' }}>
															<BirthDayCake style={{ height: '2.5rem' }} />
														</div>

														<div className='d-flex flex-column'>
															<span className='ml-2 font-weight-bold' style={{ fontSize: '1rem' }}>
																{getYearDiff(birthDate.data.birthDate)}{' '}
																<small style={{ fontSize: '0.7rem' }}>Thn</small>
															</span>
															<span className='ml-2' style={{ fontSize: '0.7rem', opacity: '0.7' }}>
																{birthDate.data.name}
															</span>
														</div>
													</div>

													<h6 className='font-weight-bold mt-4 mb-0'>Ulang Tahun</h6>
													<h6 style={{ opacity: '0.7', fontSize: '0.9rem', padding: '0' }}>
														{moment(moment(birthDate.data.birthDate, 'YYYY-MM-DD').format('MM-DD'), 'MM-DD').format(
															'DD MMMM YYYY'
														)}
													</h6>
													<h6 className='font-weight mt-auto mb-0'>
														<strong>{getDateDiffBirthDate(birthDate.data.birthDate)}</strong> Hari lagi{' '}
													</h6>
												</div>
											</div>
										))}
								</div>
							)}
						</Fragment>
					)}
				</div>
				<br />
				<br />
			</div>
		</Fragment>
	)
}

export default Profile
