import React, { Fragment, useContext, useState, useEffect, useRef } from 'react'
import Navbar from '../../Components/Navbar'
import { AuthContext } from '../../Context/AuthContext'
import Quill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import AspirationAPI from '../../services/AspirationAPI'
import { useAlert } from 'react-alert'

const formats = [
	'background',
	'bold',
	'color',
	'font',
	'code',
	'italic',
	'link',
	'size',
	'strike',
	'script',
	'underline',
	'blockquote',
	'header',
	'indent',
	'list',
	'align',
	'direction',
	'code-block',
	'formula',
	// 'image'
	// 'video'
]

const Edit = ({ history, match }) => {
	const alert = useAlert()
	const [submitting, setSubmitting] = useState(false)
	const [errors, setErrors] = useState(null)
	const [aspiration, setAspiration] = useState({ data: null, isLoading: true })

	const [files, setFiles] = useState([
		{
			file: null,
		},
	])

	const [removedFiles, setRemovedFiles] = useState([])

	const onCreate = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await AspirationAPI.updateAspiration(
					aspiration.data,
					files.map(file => file.file),
					removedFiles,
					match.params.id
				)
				alert.success('Berhasil edit aspirasi')
				history.push('/aspiration/' + match.params.id)
				setSubmitting(false)
			} catch (error) {
				// console.log(error.response.data)
				alert.success('Gagal edit aspirasi')

				if (error?.response.data) {
					setErrors(error.response.data.errors)
				}
				setSubmitting(false)
			}
		}
	}

	const fetchAspirationDetail = async () => {
		try {
			const response = await AspirationAPI.getAspirationDetail(match.params.id)
			setAspiration({
				data: response.data,
				isLoading: false,
			})
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (errors) {
			setTimeout(() => {
				setErrors(null)
			}, 5000)
		}
	}, [errors])

	useEffect(() => {
		fetchAspirationDetail()
	}, [])

	return (
		<Fragment>
			<Navbar />
			<div className='container py-5'>
				<div className='mx-auto'>
					{aspiration.isLoading ? (
						<div className='text-center'>
							<img
								src={require('../../assets/images/undraw_fast_loading_0lbh.png')}
								style={{ objectFit: 'cover', height: '14rem' }}
								alt='Loading'
							/>

							<h2>Loading ...</h2>
						</div>
					) : (
						<Fragment>
							<h2 className='font-weight-bold'>Edit aspirasi</h2>

							<hr />

							<form onSubmit={onCreate} className='d-flex justify-content-center'>
								<div className='row' style={{ width: '100%' }}>
									<div className='col-12'>
										<div className='form-group'>
											<label className='font-weight-bold' htmlFor='aspirationCategory'>
												<span style={{ color: 'red' }}>*</span> Kategori
											</label>
											<select
												className='custom-select'
												id='aspirationCategory'
												value={aspiration.data.aspirationCategoryID}
												onChange={e =>
													setAspiration({
														...aspiration,
														data: { ...aspiration.data, aspirationCategoryID: e.target.value },
													})
												}>
												<option selected>Choose...</option>
												<option value={1}>Pelayanan</option>
												<option value={2}>Pekerjaan</option>
												<option value={3}>Fasilitas</option>
												<option value={4}>Kegiatan</option>
											</select>
											{errors && errors.aspirationCategoryID && (
												<small style={{ color: 'red' }}>{errors.aspirationCategoryID[0]}</small>
											)}
										</div>
									</div>
									<div className='col-12'>
										<div className='form-group'>
											<label className='font-weight-bold' htmlFor='aspirationSubject'>
												<span style={{ color: 'red' }}>*</span> Subjek
											</label>
											<input
												type='text'
												className='form-control'
												id='aspirationSubject'
												placeholder='Penambahan modul ...'
												value={aspiration.data.subject}
												onChange={e =>
													setAspiration({ ...aspiration, data: { ...aspiration.data, subject: e.target.value } })
												}
											/>
											{errors && errors.subject && <small style={{ color: 'red' }}>{errors.subject[0]}</small>}
										</div>
									</div>

									<div className='col-12'>
										<label className='font-weight-bold' htmlFor='aspirationDescription'>
											<span style={{ color: 'red' }}>*</span> Deskripsi
										</label>
										<div className='form-group'>
											<Quill
												theme='snow'
												formats={formats}
												value={aspiration.data.description}
												//id='aspirationDescription'
												onChange={value => {
													console.log('change')
													setAspiration({ ...aspiration, data: { ...aspiration.data, description: value } })
												}}
											/>
										</div>
										{errors && errors.description && <small style={{ color: 'red' }}>{errors.description[0]}</small>}
									</div>

									<div className='col-12'>
										<div className='form-group'>
											<label className='font-weight-bold' htmlFor='aspirationSubject'>
												<span style={{ color: 'red' }}>*</span> Berkas
											</label>

											{aspiration.data.aspirationFiles.length > 0 && (
												<div style={{ marginTop: 16 }}>
													<ul>
														{aspiration.data.aspirationFiles.map(file => (
															<li key={file.ID} style={{ fontSize: '0.8rem' }}>
																<a href={file.fileURL} target='_blank' rel='noopener noreferrer'>
																	{file.file.split('/')[file.file.split('/').length - 1]}
																</a>
																{removedFiles.includes(file.ID) ? (
																	<span
																		style={{ cursor: 'pointer', fontSize: 18 }}
																		onClick={() => {
																			let tmp = [...removedFiles]
																			setRemovedFiles(tmp.filter(temp => temp !== file.ID))
																		}}>
																		<i className='ni ni-fat-delete'></i>
																	</span>
																) : (
																	<span
																		style={{ cursor: 'pointer', fontSize: 18 }}
																		onClick={() => {
																			setRemovedFiles([...removedFiles, file.ID])
																		}}>
																		<i className='ni ni-fat-remove'></i>
																	</span>
																)}
															</li>
														))}
													</ul>
												</div>
											)}

											{files.map((file, index) => (
												<div className={`d-flex align-items-center ${index > 0 ? 'mt-3' : ''}`} key={index}>
													<input
														type='file'
														className='form-control mr-3'
														id='aspirationSubject'
														placeholder='Penambahan modul ...'
														onChange={e => {
															let temp = [...files]
															temp[index].file = e.target.files[0]
															setFiles(temp)
														}}
													/>
													<button
														className='btn btn-primary'
														style={{ width: '20%' }}
														onClick={e => {
															e.preventDefault()
															setFiles([...files, { file: null }])
														}}>
														Tambah berkas
													</button>
												</div>
											))}

											{/* {errors && errors.subject && <small style={{ color: 'red' }}>{errors.subject[0]}</small>} */}
										</div>
									</div>

									{errors && errors.errors && (
										<div className='col-12 '>
											<div className='bg-warning text-white rounded p-2 px-4 d-flex justify-content-between align-items-center'>
												<span>{errors.errors[0]}</span>
												<span onClick={() => setErrors(null)} style={{ cursor: 'pointer' }}>
													X
												</span>
											</div>
										</div>
									)}

									<div className='col-6 ml-auto'>
										<div className='form-group'>
											<button disabled={submitting} type='submit' className='btn btn-primary btn-block mt-2'>
												Kirim Aspirasi
											</button>
										</div>
									</div>
								</div>
							</form>
						</Fragment>
					)}
				</div>

				<br />
				<br />
				<br />
				<br />
				<br />
			</div>
		</Fragment>
	)
}

export default Edit
