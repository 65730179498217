import React, { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const TrainingForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)
	// const form = { name: '', type: 0, year: new Date().getFullYear(), number: '' }
	const form = { name: '', trainingType: 0, year: new Date().getFullYear(), number: '', promoter: '' }

	const { employeeProfileEdit, getUserProfile, cities } = authContext

	const [courseForm, setCourSeForm] = useState([])
	const [courseFormAdd, setCourSeFormAdd] = useState([])
	const [submitting, setSubmitting] = useState(false)
	const [loadingDelete, setLoadingDelete] = useState(false)

	useEffect(() => {
		if (employeeProfileEdit.data.employeeTraining.length > 0) {
			setCourSeForm(employeeProfileEdit.data.employeeTraining)
		}
	}, [employeeProfileEdit])

	const onDelete = async (id, index) => {
		if (!loadingDelete) {
			setLoadingDelete(true)
			try {
				await API.deleteEmployeeTraining(id)
				getUserProfile()

				let tmp = courseForm.filter((__, idx) => idx !== index)
				setCourSeForm(tmp)
				setLoadingDelete(false)
				alert.show('Sukses hapus data', { type: 'success' })
			} catch (error) {
				setLoadingDelete(false)
				alert.show('Gagal hapus', { type: 'error' })
			}
		}
	}

	const onSubmit = async () => {
		if (!submitting) {
			setSubmitting(true)

			if (courseForm.length > 0) {
				for (let course of courseForm) {
					try {
						await API.updateEmployeeTraining(course, course.ID)
						alert.show('Sukses ubah data pelatihan', { type: 'success' })
					} catch (error) {
						alert.show('Gagal ubah data pelatihan', { type: 'error' })
					}
				}
			}

			let isError = false

			if (courseFormAdd.length > 0) {
				for (let course of courseFormAdd) {
					try {
						await API.addEmployeeTraining(course)
						alert.show('Sukses tambah data pelatihan', { type: 'success' })
					} catch (error) {
						alert.show('Gagal tambah data pelatihan', { type: 'error' })
						isError = true
					}
				}
			}
			getUserProfile()
			setSubmitting(false)
			if (!isError) {
				setCourSeFormAdd([])
			}
		}
	}
	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Data Pelatihan</h4>

			{courseForm.length > 0 &&
				courseForm.map((course, index) => (
					<div key={index}>
						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<div style={{ float: 'right' }}>
									<button
										onClick={() => onDelete(course.ID, index)}
										type='button'
										className='btn btn-sm btn-warning btn-tooltip'
										data-toggle='tooltip'
										data-placement='top'
										title='Hapus Data'
										data-container='body'
										data-animation='true'>
										<i className='ni ni-fat-remove '></i>
									</button>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-12'>
								<div className='form-group'>
									<label htmlFor='courseName'>
										<span style={{ color: 'red' }}>*</span> Nama Pelatihan
									</label>
									<input
										type='text'
										id='courseName'
										placeholder='Nama Pelatihan'
										className='form-control'
										value={course.name}
										onChange={e => {
											let tmp = [...courseForm]
											tmp[index].name = e.target.value
											setCourSeForm(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='lembaga'>
										<span style={{ color: 'red' }}>*</span> Nama Lembaga
									</label>
									<input
										type='text'
										id='lembaga'
										placeholder='Nama Lembaga'
										className='form-control'
										value={course.promoter}
										onChange={e => {
											let tmp = [...courseForm]
											tmp[index].promoter = e.target.value
											setCourSeForm(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='year'>
										<span style={{ color: 'red' }}>*</span> Tahun Pelatihan
									</label>
									<input
										type='text'
										maxLength={4}
										id='year'
										placeholder='Tahun Pelatihan'
										className='form-control'
										value={course.year}
										onChange={e => {
											let tmp = [...courseForm]
											tmp[index].year = e.target.value
											setCourSeForm(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='courseType'>
									<span style={{ color: 'red' }}>*</span> Jenis Pelatihan
								</label>
								<div className='form-group'>
									<select
										className='custom-select'
										id='courseType'
										value={course.trainingType}
										onChange={e => {
											let tmp = [...courseForm]
											tmp[index].trainingType = e.target.value
											setCourSeForm(tmp)
										}}>
										<option selected>Choose...</option>
										<option value={1}>Struktural</option>
										<option value={2}>Fungsional</option>
									</select>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='Sertifikat'>No. Sertifikat</label>
								<div className='form-group'>
									<input
										type='text'
										id='Sertifikat'
										placeholder='No. Sertifikat'
										className='form-control'
										value={course.number}
										onChange={e => {
											let tmp = [...courseForm]
											tmp[index].number = e.target.value
											setCourSeForm(tmp)
										}}
									/>
								</div>
							</div>
						</div>

						<hr />
					</div>
				))}

			{courseFormAdd.length > 0 &&
				courseFormAdd.map((course, index) => (
					<div key={index}>
						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<div style={{ float: 'right' }}>
									<button
										onClick={() => {
											let tmp = courseFormAdd.filter((__, idx) => idx !== index)
											setCourSeFormAdd(tmp)
										}}
										type='button'
										className='btn btn-sm btn-warning btn-tooltip'
										data-toggle='tooltip'
										data-placement='top'
										title='Hapus Data'
										data-container='body'
										data-animation='true'>
										<i className='ni ni-fat-remove '></i>
									</button>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-12'>
								<div className='form-group'>
									<label htmlFor='courseName'>
										<span style={{ color: 'red' }}>*</span> Nama Pelatihan
									</label>
									<input
										type='text'
										id='courseName'
										placeholder='Nama Pelatihan'
										className='form-control'
										value={course.name}
										onChange={e => {
											let tmp = [...courseFormAdd]
											tmp[index].name = e.target.value
											setCourSeFormAdd(tmp)
										}}
									/>
								</div>
							</div>
							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='courseName'>
										<span style={{ color: 'red' }}>*</span> Nama Lembaga
									</label>
									<input
										type='text'
										id='courseName'
										placeholder='Nama Lembaga'
										className='form-control'
										value={course.promoter}
										onChange={e => {
											let tmp = [...courseFormAdd]
											tmp[index].promoter = e.target.value
											setCourSeFormAdd(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='year'>
										<span style={{ color: 'red' }}>*</span> Tahun Pelatihan
									</label>
									<input
										type='text'
										maxLength={4}
										id='year'
										placeholder='Tahun Pelatihan'
										className='form-control'
										value={course.year}
										onChange={e => {
											let tmp = [...courseFormAdd]
											tmp[index].year = e.target.value
											setCourSeFormAdd(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='courseType'>
									<span style={{ color: 'red' }}>*</span> Jenis Pelatihan
								</label>
								<div className='form-group'>
									<select
										className='custom-select'
										id='courseType'
										value={course.trainingType}
										onChange={e => {
											let tmp = [...courseFormAdd]
											tmp[index].trainingType = e.target.value
											setCourSeFormAdd(tmp)
										}}>
										<option selected>Choose...</option>
										<option value={1}>Struktural</option>
										<option value={2}>Fungsional</option>
									</select>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='Sertifikat'>No. Sertifikat</label>
								<div className='form-group'>
									<input
										type='text'
										id='Sertifikat'
										placeholder='No. Sertifikat'
										className='form-control'
										value={course.number}
										onChange={e => {
											let tmp = [...courseFormAdd]
											tmp[index].number = e.target.value
											setCourSeFormAdd(tmp)
										}}
									/>
								</div>
							</div>
						</div>

						<hr />
					</div>
				))}

			<div className='row mt-2'>
				<div className='col-md-6'>
					<div className='form-group'>
						<button
							className='btn btn-secondary btn-block'
							onClick={e => {
								e.preventDefault()
								setCourSeFormAdd([...courseFormAdd, form])
							}}>
							+ Tambah Data Pelatihan
						</button>
					</div>
				</div>

				<div className='col-md-6'>
					<div className='form-group'>
						<button
							className='btn btn-primary btn-block'
							onClick={e => {
								e.preventDefault()
								onSubmit()
							}}
							disabled={submitting}>
							Kirim Data Pelatihan
						</button>
					</div>
				</div>
			</div>
		</form>
	)
}

export default TrainingForm
