import React, { useState, useRef } from 'react'
import API from '../../services/API'
import { useAlert } from 'react-alert'
import MitrasDudiLogo from '../../assets/images/mitrasdudiLogo.png'

export default () => {
	const _modalRef = useRef(null)
	const _modalRefReset = useRef(null)
	const _showModalReset = useRef(null)
	const [loginForm, setLoginForm] = useState({ username: '', password: '' })
	const [resetPassword, setResetPassword] = useState({ token: '', password: '', confirmPassword: '' })
	const [submitting, setSubmitting] = useState(false)
	const [isError, setIsError] = useState(false)
	const [emailReset, setEmailReset] = useState(null)
	const alert = useAlert()

	const onSubmit = async event => {
		if (!submitting) {
			setSubmitting(true)

			try {
				event.preventDefault()
				const response = await API.loginUser(loginForm)
				if (response.data.token) {
					window.location.href = '/profile'
				}
				localStorage.setItem('@user_token', response.data.token)
				setSubmitting(false)
			} catch (error) {
				setIsError(false)
				setSubmitting(false)
				alert.show('Gagal Masuk', { type: 'error' })
			}
		}
	}

	const onSubmitEmail = async e => {
		e.preventDefault()

		if (!submitting) {
			setSubmitting(true)
			try {
				await API.forgetPassword(emailReset)
				_modalRef.current.click()
				alert.show('Silahkan Periksa Email Anda!', { type: 'success' })
				setSubmitting(false)
				_showModalReset.current.click()
			} catch (error) {
				alert.show('Gagal mengirim OTP', { type: 'error' })
				setSubmitting(false)
			}
		}
	}

	const onResetPassword = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			try {
				await API.resetPassword(resetPassword)
				_modalRefReset.current.click()
				alert.show('Sukses mengubah password anda!', { type: 'success' })
				setSubmitting(false)
			} catch (error) {
				console.log(error)
				alert.show('Gagal mengubah password Anda', { type: 'error' })
				setSubmitting(false)
			}
		}
	}

	return (
		<div className='login-wrapper'>
			<div className='container'>
				<div className='d-flex justify-content-center mt-4'>
					<img src={MitrasDudiLogo} height={250} alt='Mitras Dudi Logo' />
				</div>

				<hr />
				<div className='rounded border p-4 mt-3'>
					<form onSubmit={onSubmit} className='d-flex justify-content-center'>
						<div className='row' style={{ maxWidth: '480px' }}>
							<div className='col-12'>
								<div className='form-group'>
									<input
										type='email'
										className='form-control'
										id='username'
										placeholder='name@example.com'
										value={loginForm.username}
										onChange={event => setLoginForm({ ...loginForm, username: event.target.value })}
									/>
								</div>
							</div>
							<div className='col-12'>
								<div className='form-group'>
									<input
										type='password'
										id='password'
										placeholder='Regular'
										className='form-control'
										value={loginForm.password}
										onChange={event => setLoginForm({ ...loginForm, password: event.target.value })}
									/>
								</div>
							</div>

							<div className='col-12'>
								<div className='form-group'>
									<a href='#!' data-toggle='modal' data-target='#modal-forgot-password'>
										Lupa Password ?
									</a>
								</div>
							</div>

							<div className='col-12'>
								<div className='form-group'>
									<input
										type='submit'
										className='form-control btn btn-block'
										value='Login'
										style={{ background: '#172B4D', color: '#e5e5e5' }}
										disabled={submitting}
									/>
								</div>
							</div>
						</div>
					</form>
					{isError ? (
						<div className='alert alert-danger' role='alert'>
							Gagal Masuk
						</div>
					) : null}
				</div>
				<div className='my-5 text-center'>
					<blockquote>New Normal, Selalu Produktif, Jaga Kesehatan</blockquote>
				</div>

				<button
					style={{ display: 'none' }}
					ref={_showModalReset}
					data-toggle='modal'
					data-target='#modal-reset-password'>
					.
				</button>
			</div>

			<div className='modal' tabIndex={-1} role='dialog' id='modal-forgot-password'>
				<div className='modal-dialog' role='document'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Reset Password</h5>
							<button type='button' className='close' data-dismiss='modal' aria-label='Close' ref={_modalRef}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div className='modal-body'>
							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor='resetEmail'>Masukkan Email Anda</label>

										<input
											type='email'
											className='form-control'
											id='resetEmail'
											placeholder='name@example.com'
											// value={loginForm.username}
											onChange={event => setEmailReset(event.target.value)}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='modal-footer'>
							<button type='button' className='btn btn-primary' disabled={submitting} onClick={e => onSubmitEmail(e)}>
								Kirim OTP
							</button>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>
								Tutup
							</button>
						</div>
					</div>
				</div>
			</div>

			<div className='modal' tabIndex={-1} role='dialog' id='modal-reset-password'>
				<div className='modal-dialog' role='document'>
					<div className='modal-content'>
						<div className='modal-header'>
							<h5 className='modal-title'>Reset Password</h5>
							<button type='button' className='close' data-dismiss='modal' aria-label='Close' ref={_modalRefReset}>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div className='modal-body'>
							<div className='row'>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor='resetEmail'>Masukkan Kode OTP</label>
										<input
											type='text'
											className='form-control'
											id='resetEmail'
											placeholder='OTP'
											value={resetPassword.token}
											onChange={event => setResetPassword({ ...resetPassword, token: event.target.value })}
										/>
									</div>
								</div>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor='resetEmail'>Masukkan Password Anda</label>
										<input
											type='password'
											className='form-control'
											id='resetEmail'
											placeholder='Password'
											value={resetPassword.password}
											onChange={event => setResetPassword({ ...resetPassword, password: event.target.value })}
										/>
									</div>
								</div>
								<div className='col-12'>
									<div className='form-group'>
										<label htmlFor='resetEmail'>Konfirmasi Password Anda</label>
										<input
											type='password'
											className='form-control'
											id='resetEmail'
											placeholder='Konfirmasi Password Anda'
											value={resetPassword.confirmPassword}
											onChange={event => setResetPassword({ ...resetPassword, confirmPassword: event.target.value })}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='modal-footer'>
							<button type='button' className='btn btn-primary' disabled={submitting} onClick={e => onResetPassword(e)}>
								Ubah Sandi
							</button>
							<button type='button' className='btn btn-secondary' data-dismiss='modal'>
								Tutup
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
