import React, { useEffect, useContext, useState, Fragment } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const ChildrenForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const form = { name: '', bornDate: '', bornPlace: '', status: '', gender: '' }
	const [childrenForm, setChildrenForm] = useState([])
	const [childrenFormAdd, setChildrenFormAdd] = useState([])
	const [loadingDelete, setLoadingDelete] = useState(false)

	const [submitting, setSubmitting] = useState(false)

	const { employeeProfileEdit, getUserProfile, cities } = authContext

	useEffect(() => {
		if (employeeProfileEdit.data.employeeChildren.length > 0) {
			setChildrenForm(employeeProfileEdit.data.employeeChildren)
		}
	}, [employeeProfileEdit])

	/**
	 * @returns {boolean}
	 */
	const disable = () => {
		let status = false
		const merge = childrenForm.concat(childrenFormAdd)

		if (merge.length === 0) {
			return true
		}

		if (merge.length > 0) {
			merge.forEach(calc => {
				if (
					`${calc.name}`.trim().length === 0 ||
					`${calc.bornDate}`.trim().length === 0 ||
					`${calc.bornPlace}`.trim().length === 0 ||
					`${calc.gender}`.trim().length === 0 ||
					`${calc.status}`.trim().length === 0
				) {
					status = true
				} else {
					status = false
				}
			})
		}
		return status
	}

	const onDelete = async id => {
		if (!loadingDelete) {
			setLoadingDelete(true)
			try {
				await API.deleteEmployeeChild(id)
				getUserProfile()
				setLoadingDelete(false)
				alert.show('Sukses Menghapus Data Anak', { type: 'success' })
			} catch (error) {
				setLoadingDelete(false)
				alert.show('Gagal Menghapus Data Anak', { type: 'error' })
			}
		}
	}

	const submit = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			if (childrenForm.length > 0) {
				for (let children of childrenForm) {
					try {
						await API.updateEmployeeChildren(children, children.ID)
						alert.show('Sukses Mengubah Data Anak ' + children.name, { type: 'success' })
					} catch (error) {
						alert.show('Gagal Mengubah Data Anak ' + children.name, { type: 'error' })
					}
				}
			}

			if (childrenFormAdd.length > 0) {
				for (let children of childrenFormAdd) {
					try {
						await API.addEmployeeChildren(children)
						alert.show('Sukses Menambahkan Data Anak ' + children.name, { type: 'success' })
					} catch (error) {
						alert.show('Gagal Menambahkan Data Anak ' + children.name, { type: 'error' })
					}
				}
			}
			setChildrenFormAdd([])
			getUserProfile()
			setSubmitting(false)
		}
	}

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Data Anak</h4>

			{childrenForm.length > 0 &&
				childrenForm.map((children, index) => (
					<div>
						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<div style={{ float: 'right' }}>
									<button
										onClick={() => onDelete(children.ID)}
										type='button'
										className='btn btn-sm btn-warning btn-tooltip'
										data-toggle='tooltip'
										data-placement='top'
										title='Hapus Data'
										data-container='body'
										data-animation='true'>
										<i className='ni ni-fat-remove '></i>
									</button>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-12'>
								<div className='form-group'>
									<label htmlFor='childrenName'>
										<span style={{ color: 'red' }}>*</span> Nama Anak
									</label>
									<input
										type='text'
										id='childrenName'
										placeholder='Nama Anak'
										className='form-control'
										value={children.name}
										onChange={e => {
											let tmp = [...childrenForm]
											tmp[index].name = e.target.value
											setChildrenForm(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='dateBornChildren'>
									<span style={{ color: 'red' }}>*</span> Tanggal Lahir
								</label>
								<div className='form-group'>
									<input
										type='date'
										id='dateBornChildren'
										placeholder='Tanggal Lahir'
										className='form-control'
										value={children.bornDate}
										onChange={e => {
											let tmp = [...childrenFormAdd]
											tmp[index].bornDate = e.target.value
											setChildrenFormAdd(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='childrenCity'>
										<span style={{ color: 'red' }}>*</span> Tempat Lahir
									</label>
									<select
										className='custom-select'
										id='childrenCity'
										value={children.bornPlace}
										onChange={e => {
											let tmp = [...childrenForm]
											tmp[index].bornPlace = e.target.value
											setChildrenForm(tmp)
										}}>
										<option selected>Choose...</option>
										{cities.data.map(city => (
											<option value={city.ID} key={city.ID}>
												{city.name}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='dateBornChildren'>
									<span style={{ color: 'red' }}>*</span> Jenis Kelamin
								</label>
								<div className='form-group'>
									<select
										className='custom-select'
										id='childrenCity'
										value={children.gender}
										onChange={e => {
											let tmp = [...childrenForm]
											tmp[index].gender = e.target.value
											setChildrenForm(tmp)
										}}>
										<option selected>Choose...</option>
										<option value='0'>Laki - Laki</option>
										<option value='1'>Perempuan</option>
									</select>
								</div>
							</div>

							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='childrenCity'>
										<span style={{ color: 'red' }}>*</span> Status Anak
									</label>
									<select
										className='custom-select'
										id='childrenCity'
										value={children.status}
										onChange={e => {
											let tmp = [...childrenForm]
											tmp[index].status = e.target.value
											setChildrenForm(tmp)
										}}>
										<option selected>Choose...</option>
										<option value={1}>Anak Kandung</option>
										<option value={2}>Anak Tiri</option>
										<option value={3}>Anak Angkat</option>
									</select>
								</div>
							</div>
						</div>

						<hr />
					</div>
				))}

			{childrenFormAdd.length > 0 &&
				childrenFormAdd.map((childrenAdd, index) => (
					<div>
						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<div style={{ float: 'right' }}>
									<button
										onClick={() => setChildrenFormAdd(childrenFormAdd.filter((_, idx) => idx !== index))}
										type='button'
										className='btn btn-sm btn-warning btn-tooltip'
										data-toggle='tooltip'
										data-placement='top'
										title='Hapus Data'
										data-container='body'
										data-animation='true'>
										<i className='ni ni-fat-remove '></i>
									</button>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-12'>
								<div className='form-group'>
									<label htmlFor='childrenName'>
										<span style={{ color: 'red' }}>*</span> Nama Anak
									</label>
									<input
										type='text'
										id='childrenName'
										placeholder='Nama Anak'
										className='form-control'
										value={childrenAdd.name}
										onChange={e => {
											let tmp = [...childrenFormAdd]
											tmp[index].name = e.target.value
											setChildrenFormAdd(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='dateBornChildren'>
									<span style={{ color: 'red' }}>*</span> Tanggal Lahir
								</label>
								<div className='form-group'>
									<input
										type='date'
										id='dateBornChildren'
										placeholder='Tanggal Lahir'
										className='form-control'
										value={childrenAdd.bornDate}
										onChange={e => {
											let tmp = [...childrenFormAdd]
											tmp[index].bornDate = e.target.value
											setChildrenFormAdd(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='childrenCity'>
										<span style={{ color: 'red' }}>*</span> Tempat Lahir
									</label>
									<select
										className='custom-select'
										id='childrenCity'
										value={childrenAdd.bornPlace}
										onChange={e => {
											let tmp = [...childrenFormAdd]
											tmp[index].bornPlace = e.target.value
											setChildrenFormAdd(tmp)
										}}>
										<option selected>Choose...</option>
										{cities.data.map(city => (
											<option value={city.ID} key={city.ID}>
												{city.name}
											</option>
										))}
									</select>
								</div>
							</div>

							<div className='col-md-6'>
								<label htmlFor='dateBornChildren'>
									<span style={{ color: 'red' }}>*</span> Jenis Kelamin
								</label>
								<div className='form-group'>
									<select
										className='custom-select'
										id='childrenCity'
										value={childrenAdd.gender}
										onChange={e => {
											let tmp = [...childrenFormAdd]
											tmp[index].gender = e.target.value
											setChildrenFormAdd(tmp)
										}}>
										<option selected>Choose...</option>
										<option value='0'>Laki - Laki</option>
										<option value='1'>Perempuan</option>
									</select>
								</div>
							</div>

							<div className='col-md-6'>
								<div className='form-group'>
									<label htmlFor='childrenCity'>
										<span style={{ color: 'red' }}>*</span> Status Anak
									</label>
									<select
										className='custom-select'
										id='childrenCity'
										value={childrenAdd.status}
										onChange={e => {
											let tmp = [...childrenFormAdd]
											tmp[index].status = e.target.value
											setChildrenFormAdd(tmp)
										}}>
										<option selected>Choose...</option>
										<option value={1}>Anak Kandung</option>
										<option value={2}>Anak Tiri</option>
										<option value={3}>Anak Angkat</option>
									</select>
								</div>
							</div>
						</div>

						<hr />
					</div>
				))}

			<div className='row mt-2'>
				<div className='col-md-6'>
					<div className='form-group'>
						<button
							className='btn btn-secondary btn-block'
							onClick={e => {
								e.preventDefault()
								setChildrenFormAdd([...childrenFormAdd, form])
							}}>
							+ Tambah Data Anak
						</button>
					</div>
				</div>

				<div className='col-md-6'>
					<div className='form-group'>
						<button className='btn btn-primary btn-block' onClick={e => submit(e)} disabled={disable() || submitting}>
							Kirim Data Anak
						</button>
					</div>
				</div>
			</div>
		</form>
	)
}

export default ChildrenForm
