import React, { useEffect, useContext, useState } from 'react'
import { AuthContext } from '../../../Context/AuthContext'
import { useAlert } from 'react-alert'
import API from '../../../services/API'

const EducationForm = () => {
	const alert = useAlert()
	const authContext = useContext(AuthContext)

	const employeeEducationForm = {
		educationTierID: '',
		schoolName: '',
		subjectName: '',
		graduationYear: '',
		referenceNumber: '',
		image: null,
	}
	const [employeeEducation, setEmployeeEducation] = useState([])
	const [employeeEducationAdd, setEmployeeEducationAdd] = useState([])

	const [loadingDelete, setLoadingDelete] = useState(false)

	const [submitting, setSubmitting] = useState(false)

	const { employeeProfileEdit, getUserProfile, educationTier } = authContext

	useEffect(() => {
		if (employeeProfileEdit.data.historyEducation.length) {
			setEmployeeEducation(
				employeeProfileEdit.data.historyEducation.map(education => ({
					...education,
					subjectName: education.employeeEducationSubject?.subject,
					image: education.imageURL,
				}))
			)
		}
	}, [employeeProfileEdit])

	/**
	 * @returns {boolean}
	 */
	const disable = () => {
		let status = false
		const merge = employeeEducation.concat(employeeEducationAdd)

		if (merge.length === 0) {
			return true
		}

		if (merge.length > 0) {
			merge.forEach(calc => {
				if (
					`${calc.educationTierID}`.trim().length === 0 ||
					`${calc.schoolName}`.trim().length === 0 ||
					`${calc.graduationYear}`.trim().length === 0 ||
					`${calc.referenceNumber}`.trim().length === 0
				) {
					status = true
				} else {
					status = false
				}
			})
		}
		return status
	}

	const submit = async e => {
		e.preventDefault()
		if (!submitting) {
			setSubmitting(true)
			if (employeeEducation.length > 0) {
				for (let education of employeeEducation) {
					try {
						await API.updateEmployeeEducation(education, education.ID)
						alert.show('Sukses Kirim Riwayat Pendidikan', { type: 'success' })
						setSubmitting(false)
					} catch (error) {
						alert.show('Gagal Ubah Golongan', { type: 'error' })
					}
				}
			}

			let isError = false

			if (employeeEducationAdd.length > 0) {
				for (let education of employeeEducationAdd) {
					try {
						await API.addEmployeeEducation(education)
						alert.show('Sukses Kirim Riwayat Pendidikan', { type: 'success' })
						setSubmitting(false)
					} catch (error) {
						alert.show('Gagal Ubah Golongan', { type: 'error' })
						isError = true
					}
				}
			}
			await getUserProfile()
			setSubmitting(false)

			if (!isError) {
				setEmployeeEducationAdd([])
			}
		}
	}

	const onDelete = async employeeEducationID => {
		if (!loadingDelete) {
			setLoadingDelete(true)
			try {
				await API.deleteEmployeeEducation(employeeEducationID)
				getUserProfile()
				setLoadingDelete(false)
				alert.show('Sukses Hapus Golongan', { type: 'success' })
			} catch (error) {
				setLoadingDelete(false)
				alert.show('Gagal Hapus Golongan', { type: 'error' })
			}
		}
	}

	return (
		<form onSubmit={e => e.preventDefault()}>
			<h4>Riwayat Pendidikan</h4>

			{employeeEducation.length > 0 &&
				employeeEducation.map((education, index) => (
					<div key={index}>
						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<div style={{ float: 'right' }}>
									<button
										onClick={() => onDelete(education.ID)}
										type='button'
										className='btn btn-sm btn-warning btn-tooltip'
										data-toggle='tooltip'
										data-placement='top'
										title='Hapus Data'
										data-container='body'
										data-animation='true'>
										<i className='ni ni-fat-remove '></i>
									</button>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='jenisPendidikan'>
										<span style={{ color: 'red' }}>*</span> Tingkat Pendidikan
									</label>
									<select
										className='custom-select'
										id='jenisPendidikan'
										value={education.educationTierID}
										onChange={e => {
											let tmp = [...employeeEducation]
											tmp[index].educationTierID = e.target.value
											setEmployeeEducation(tmp)
										}}>
										<option selected>Choose...</option>
										{educationTier.data.map(educationTierData => (
											<option value={educationTierData.ID}>{educationTierData.name}</option>
										))}
									</select>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='sekolah'>
										<span style={{ color: 'red' }}>*</span> Nama Sekolah / Universitas
									</label>
									<input
										type='text'
										id='sekolah'
										className='form-control'
										placeholder='Nama Sekolah / Universitas'
										value={education.schoolName}
										onChange={e => {
											let tmp = [...employeeEducation]
											tmp[index].schoolName = e.target.value
											setEmployeeEducation(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='jurusan'>Jurusan</label>
									<input
										type='text'
										id='jurusan'
										className='form-control'
										placeholder='Jurusan'
										value={education.subjectName}
										onChange={e => {
											let tmp = [...employeeEducation]
											tmp[index].subjectName = e.target.value
											setEmployeeEducation(tmp)
										}}
									/>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='referenceNumber'>
										<span style={{ color: 'red' }}>*</span> Nomor Ijazah
									</label>
									<input
										type='text'
										id='referenceNumber'
										className='form-control'
										placeholder='Nomor Ijazah'
										value={education.referenceNumber}
										onChange={e => {
											let tmp = [...employeeEducation]
											tmp[index].referenceNumber = e.target.value
											setEmployeeEducation(tmp)
										}}
									/>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='graduationYear'>
										<span style={{ color: 'red' }}>*</span> Tahun Lulus
									</label>
									<input
										type='text'
										maxLength={4}
										id='graduationYear'
										className='form-control'
										placeholder='Tahun Lulus'
										value={education.graduationYear}
										onChange={e => {
											let tmp = [...employeeEducation]
											tmp[index].graduationYear = e.target.value
											setEmployeeEducation(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='ijazah'>
										<span style={{ color: 'red' }}>*</span> Upload Ijazah
									</label>
									<input
										type='file'
										id='ijazah'
										accept='image/*,pdf'
										className='form-control'
										placeholder='Tahun Lulus'
										onChange={e => {
											let tmp = [...employeeEducation]
											tmp[index].image = e.target.files[0]
											setEmployeeEducation(tmp)
										}}
									/>
									{education.imageURL ? (
										<a href={`${education.imageURL}`} target='_blank' rel='noopener noreferrer'>
											<div className='btn btn-primary btn-sm mt-2'>
												<small>
													<i className='ni ni-cloud-download-95'></i> &nbsp; Lihat ijazah
												</small>
											</div>
										</a>
									) : (
										<div className='btn btn-primary btn-sm mt-2' disabled>
											<small>
												<i className='ni ni-cloud-download-95'></i> &nbsp;Harap unggah ijazah
											</small>
										</div>
									)}
								</div>
							</div>
						</div>

						<hr />
					</div>
				))}

			{employeeEducationAdd.length > 0 &&
				employeeEducationAdd.map((educationAdd, index) => (
					<div key={index}>
						<div className='row'>
							<div className='col-md-6'></div>
							<div className='col-md-6'>
								<div style={{ float: 'right' }}>
									<button
										onClick={() => setEmployeeEducationAdd(employeeEducationAdd.filter((_, idx) => idx !== index))}
										type='button'
										className='btn btn-sm btn-warning btn-tooltip'
										data-toggle='tooltip'
										data-placement='top'
										title='Hapus Data'
										data-container='body'
										data-animation='true'>
										<i className='ni ni-fat-remove '></i>
									</button>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='jenisPendidikan'>
										<span style={{ color: 'red' }}>*</span> Tingkat Pendidikan
									</label>
									<select
										className='custom-select'
										id='jenisPendidikan'
										value={educationAdd.educationTierID}
										onChange={e => {
											let tmp = [...employeeEducationAdd]
											tmp[index].educationTierID = e.target.value
											setEmployeeEducationAdd(tmp)
										}}>
										<option selected>Choose...</option>
										{educationTier.data.map(educationTierData => (
											<option value={educationTierData.ID}>{educationTierData.name}</option>
										))}
									</select>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='sekolah'>
										<span style={{ color: 'red' }}>*</span> Nama Sekolah / Universitas
									</label>
									<input
										type='text'
										id='sekolah'
										className='form-control'
										placeholder='Nama Sekolah / Universitas'
										value={educationAdd.schoolName}
										onChange={e => {
											let tmp = [...employeeEducationAdd]
											tmp[index].schoolName = e.target.value
											setEmployeeEducationAdd(tmp)
										}}
									/>
								</div>
							</div>

							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='jurusan'>Jurusan</label>
									<input
										type='text'
										id='jurusan'
										className='form-control'
										placeholder='Jurusan'
										value={educationAdd.subjectName}
										onChange={e => {
											let tmp = [...employeeEducationAdd]
											tmp[index].subjectName = e.target.value
											setEmployeeEducationAdd(tmp)
										}}
									/>
								</div>
							</div>
						</div>

						<div className='row'>
							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='referenceNumber'>
										<span style={{ color: 'red' }}>*</span> Nomor Ijazah
									</label>
									<input
										type='text'
										id='referenceNumber'
										className='form-control'
										placeholder='Nomor Ijazah'
										value={educationAdd.referenceNumber}
										onChange={e => {
											let tmp = [...employeeEducationAdd]
											tmp[index].referenceNumber = e.target.value
											setEmployeeEducationAdd(tmp)
										}}
									/>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='graduationYear'>
										<span style={{ color: 'red' }}>*</span> Tahun Lulus
									</label>
									<input
										type='text'
										maxLength={4}
										id='graduationYear'
										className='form-control'
										placeholder='Tahun Lulus'
										value={educationAdd.graduationYear}
										onChange={e => {
											let tmp = [...employeeEducationAdd]
											tmp[index].graduationYear = e.target.value
											setEmployeeEducationAdd(tmp)
										}}
									/>
								</div>
							</div>
							<div className='col-md-4'>
								<div className='form-group'>
									<label htmlFor='ijazah'>
										<span style={{ color: 'red' }}>*</span> Upload Ijazah
									</label>
									<input
										type='file'
										id='ijazah'
										className='form-control'
										placeholder='Tahun Lulus'
										onChange={e => {
											let tmp = [...employeeEducationAdd]
											tmp[index].image = e.target.files[0]
											setEmployeeEducationAdd(tmp)
										}}
									/>
								</div>
							</div>
						</div>

						<hr />
					</div>
				))}

			<div className='row mt-2'>
				<div className='col-md-6'>
					<div className='form-group'>
						<button
							className='btn btn-secondary btn-block'
							onClick={e => {
								e.preventDefault()
								setEmployeeEducationAdd([...employeeEducationAdd, { ...employeeEducationForm }])
							}}>
							+ Riwayat Pendidikan
						</button>
					</div>
				</div>

				<div className='col-md-6'>
					<div className='form-group'>
						<button className='btn btn-primary btn-block' disabled={disable() || submitting} onClick={submit}>
							Kirim Riwayat Pendidikan
						</button>
					</div>
				</div>
			</div>
		</form>
	)
}

export default EducationForm
