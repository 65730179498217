import React, { useContext } from 'react'
import { LeaveContext } from '../../../Context/LeaveContext'
import moment from 'moment'

const ModalLeaveDetail = () => {
	const leaveContext = useContext(LeaveContext)
	const { leaveDetail } = leaveContext

	return (
		<div
			className='modal fade'
			id='modalLeaveDetail'
			tabIndex={-1}
			role='dialog'
			aria-labelledby='modalLeaveDetailLabel'
			aria-hidden='true'>
			<div className='modal-dialog modal-lg' role='document'>
				{leaveDetail.isLoading ? null : (
					<div className='modal-content'>
						<div className='modal-header'>
							<div>
								<h5 className='modal-title' id='clockOutModalLabel' style={{ lineHeight: 0.7 }}>
									Pengajuan Cuti
								</h5>
								<span style={{ fontSize: '0.6rem' }}>
									{moment(leaveDetail.data.created, 'YYYY-MM-DD HH:mm:ss').fromNow()}
								</span>
							</div>
							<button type='button' className='close' data-dismiss='modal' aria-label='Close'>
								<span aria-hidden='true'>×</span>
							</button>
						</div>
						<div className='px-4'>
							<br />
							<div className='row'>
								<div className='col-4'>
									<div>
										<p className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
											Nama Karyawan
										</p>
										<p>{leaveDetail.data.employee.name}</p>
									</div>
								</div>
								<div className='col-4'>
									<div>
										<p className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
											Status Pengajuan
										</p>
										{leaveDetail.data.employeeLeaveResponse ? (
											leaveDetail.data.employeeLeaveResponse.canLeave ? (
												<p className='text-success'>Diterima</p>
											) : (
												<p className='text-danger'>Ditolak</p>
											)
										) : (
											<p className='text-warning'>Menunggu</p>
										)}
									</div>
								</div>
								<div className='col-4'>
									<div>
										<p className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
											Berkas
										</p>
										<p>
											<a
												href={
													process.env.REACT_APP_BASE_API_LEGACY_URL + `/leave/employee-leave/export/${leaveDetail.data.ID}`
												}
												target='_blank'
												rel='noopener noreferrer'>
												<i className='far fa-file-pdf'></i> Unduh Berkas
											</a>
										</p>
									</div>
								</div>
							</div>

							<div className='row'>
								<div className='col-4'>
									<div>
										<p className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
											Jenis Cuti
										</p>
										<p>{leaveDetail.data.typeLabel}</p>
									</div>
								</div>
							</div>

							<br />
							<div>
								<table className='table'>
									<thead>
										<tr>
											<td className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
												Tanggal Cuti
											</td>
											<td className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
												Hari
											</td>
										</tr>
									</thead>

									<tbody>
										{leaveDetail.data.employeeLeaveDates.map(employeeLeaveDate => (
											<tr key={employeeLeaveDate.ID}>
												<td>{moment(employeeLeaveDate.date, 'YYYY-MM-DD').format('D MMMM YYYY')}</td>
												<td>{moment(employeeLeaveDate.date, 'YYYY-MM-DD').format('dddd')}</td>
											</tr>
										))}
									</tbody>
								</table>
							</div>

							<br />
							<div>
								{leaveDetail.data.employeeLeaveResponse ? (
									!leaveDetail.data.employeeLeaveResponse.canLeave ? (
										<table className='table'>
											<thead>
												<tr>
													<td className='font-weight-bold' style={{ margin: 0, lineHeight: 0.8 }}>
														Alasan
													</td>
												</tr>
											</thead>

											<tbody>
												{leaveDetail.data.employeeLeaveDates.map(employeeLeaveDate => (
													<tr key={employeeLeaveDate.ID}>
														<td>{leaveDetail.data.employeeLeaveReason.reason}</td>
													</tr>
												))}
											</tbody>
										</table>
									) : null
								) : null}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}

export default ModalLeaveDetail
