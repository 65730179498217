import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Navbar from '../../Components/Navbar'
import { AuthContext } from '../../Context/AuthContext'
import { getVisitor } from '../../services/GuestAPI'
import GuestCard from './Components/GuestCard'
import moment from 'moment'

const GuestPage = () => {
	const authContext = useContext(AuthContext)
	const [guests, setGuests] = useState({ data: null, isLoading: true })
	const [guestPending, setGuestPending] = useState({ data: [], isLoading: true })

	const fetchGuest = async employeeID => {
		try {
			const response = await getVisitor({
				employeeID,
				sort: 'ID',
				order: 'desc',
				status: '1',
			})
			if (response.code === 200) {
				let groupBydate = {}

				for (let i in response.data.list) {
					const current = response.data.list[i]
					const date = moment(current.schedule.dateTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
					if (!groupBydate[date]) {
						groupBydate[date] = []
					}
					groupBydate[date].push(current)
				}

				setGuests({
					data: groupBydate,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	const fetchGuestPending = async employeeID => {
		try {
			const response = await getVisitor({
				employeeID,
				sort: 'ID',
				order: 'desc',
				status: '2',
			})
			if (response.code === 200) {
				setGuestPending({
					data: response.data.list,
					isLoading: false,
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (!authContext.userProfile.isLoading && authContext.userProfile.data.employeeAccess.employeeID) {
			fetchGuest(authContext.userProfile.data.employeeAccess.employeeID)
			fetchGuestPending(authContext.userProfile.data.employeeAccess.employeeID)
		}
	}, [authContext.userProfile.isLoading])

	return (
		<Fragment>
			<Navbar />
			<div className='container py-5'>
				<div className='mx-auto'>
					<div className='aspiration-list-wrapper mx-auto'>
						{guests.isLoading ? (
							<div className='text-center'>
								<img
									src={require('../../assets/images/undraw_fast_loading_0lbh.png')}
									style={{ objectFit: 'cover', height: '14rem' }}
									alt='Loading'
								/>

								<h2>Loading ...</h2>
							</div>
						) : !guests.isLoading && guests.data === null ? (
							<div className='text-center'>
								<img
									src={require('../../assets/images/undraw_empty_xct9.png')}
									style={{ objectFit: 'cover', height: '14rem' }}
									alt='Loading'
								/>

								<h2>Belum ada Tamu</h2>
							</div>
						) : (
							<Fragment>
								<h2 className='font-weight-bold'>Permintaan Bertemu</h2>

								{guestPending.data.map((guest, index) => (
									<GuestCard
										guest={guest}
										key={index}
										onSuccess={() => {
											fetchGuest(authContext.userProfile.data.employeeAccess.employeeID)
											fetchGuestPending(authContext.userProfile.data.employeeAccess.employeeID)
										}}
									/>
								))}

								<br />
								<br />

								{Object.keys(guests.data)
									.reverse()
									.map((key, index) => (
										<div>
											<h2 className='font-weight-bold'>
												Tamu{' '}
												{key === moment().format('YYYY-MM-DD') ? 'Hari ini' : moment(key).format('dddd, DD MMMM YYYY')}
											</h2>
											{guests.data[key].map((guest, index) => (
												<GuestCard
													guest={guest}
													key={index}
													onSuccess={() => {
														fetchGuest(authContext.userProfile.data.employeeAccess.employeeID)
														fetchGuestPending(authContext.userProfile.data.employeeAccess.employeeID)
													}}
												/>
											))}
											<br />
										</div>
									))}
							</Fragment>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default GuestPage
