import React, { Fragment, useEffect } from 'react'
import './App.css'
import Home from './Pages/Home'
import CreateAttendance from './Pages/CreateAttendance'
import Calendar from './Pages/Calendar'
import Login from './Pages/Auth/Login'
import Initial from './Initial'
import Profile from './Pages/Profile'
import EditProfile from './Pages/Profile/Edit'
import Aspiration from './Pages/Aspiration'
import CreateAspiration from './Pages/Aspiration/Create'
import AspirationDetail from './Pages/Aspiration/Detail'
import EditAspiration from './Pages/Aspiration/Edit'

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import MitrasDudiLogo from './assets/images/mitrasdudiLogo.png'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import AbsentContextProvider from './Context/AbsentContext'
import AuthContextProvider from './Context/AuthContext'
import LeaveContextProvider from './Context/LeaveContext'

import Logs from './Pages/Home/Logs'
import Agency from './Pages/Home/Agency'

import moment from 'moment'
import OneSignal, { useOneSignalSetup } from 'react-onesignal'
import GuestPage from './Pages/Guest'

OneSignal.initialize(process.env.REACT_APP_ONESIGNAL_APP_ID, {
	allowLocalhostAsSecureOrigin: true,
	autoRegister: true,
})
moment.locale('id')

// optional configuration
const options = {
	// you can also just use 'bottom center'
	position: positions.BOTTOM_CENTER,
	timeout: 5000,
	offset: '60px',
	// you can also just use 'scale'
	transition: transitions.FADE,
}

function App() {
	useOneSignalSetup(async () => {
		// Check all possible permission states
		const permissions = OneSignal.notificationPermission()

		// Check current permission state
		const currentState = await OneSignal.getNotificationPermission()

		if (currentState === 'default') {
			// Ask the user for notification permissions, if not granted yet
			await OneSignal.registerForPushNotifications()
		}
	})

	return (
		<AlertProvider template={AlertTemplate} {...options}>
			<AuthContextProvider>
				<AbsentContextProvider>
					<LeaveContextProvider>
						<div>
							<div style={{ position: 'absolute', top: '16px', right: '16px', display: 'flex' }}>
								<img src={MitrasDudiLogo} height={64} alt='Mitras Dudi Logo' />
							</div>

							<Router>
								<Switch>
									<Route exact path='/absent' component={Home} />
									<Route exact path='/absent/log/:date' component={CreateAttendance} />
									<Route exact path='/absent/dinas' component={Agency} />
									<Route exact path='/guest' component={GuestPage} />
									<Route exact path='/absent/log' component={Logs} />
									<Route exact path='/calendar' component={Calendar} />
									<Route exact path='/profile' component={Profile} />
									<Route exact path='/profile/edit' component={EditProfile} />
									<Route exact path='/aspiration' component={Aspiration} />
									<Route exact path='/create-aspiration' component={CreateAspiration} />
									<Route exact path='/edit-aspiration/:id' component={EditAspiration} />
									<Route exact path='/aspiration/:id' component={AspirationDetail} />
									<Route exact path='/' component={Initial} />
									<Route exact path='/login' component={Login} />
									<Route exact render={() => <h1>Uh ohh</h1>} />
								</Switch>
							</Router>
						</div>
					</LeaveContextProvider>
				</AbsentContextProvider>
			</AuthContextProvider>
		</AlertProvider>
	)
}

export default App
